import { Modal, Tabs } from '@mantine/core'
import './AcademicUsersView.css'
import AcaTeachersTable from '../AcaTeachersTable/AcaTeachersTable'
import useAcaUsers from '../../../../hooks/Academic/Users/useAcaUsers'
import TanStackTable from '../../../../components/Table/TanStackTable/TanStackTable'
import CellInfoModal from '../../../../components/CellInfoModal/CellInfoModal'
import AcaStudentExamAccordion from '../AcaStudentExamAccordion/AcaStudentExamAccordion'
import AcaStudentCertificatesAccordion from '../AcaStudentCertificatesAccordion/AcaStudentCertificatesAccordion'

export default function AcademicUsersView() {
   const {
      data,
      columns,
      isLoading,
      onEditTeacher,
      // onDeleteUser,
      initialValuesEdit,
      setInitialValuesEdit,
      displayForm,
      setDisplayForm,
      handleSubmit,
      formValidation,
      deleteModalOpen,
      setDeleteModalOpen,
      setSelectedUserId,
      studentModal,
      setStudentModal,
      cellModal,
      setCellModal,
   } = useAcaUsers()

   return (
      <section className='AcademicView-layout--container'>
         <Tabs
            defaultValue="Profesores"
            onChange={() => {
               setDisplayForm(false);
               setInitialValuesEdit(null)
            }}
         >
            <Tabs.List>
               <Tabs.Tab value="Profesores">
                  Profesores
               </Tabs.Tab>
               <Tabs.Tab value="Estudiantes">
                  Estudiantes
               </Tabs.Tab>
            </Tabs.List>

            {!isLoading && data &&
               <Tabs.Panel value="Profesores" pt={20}>
                  < AcaTeachersTable
                     data={data?.teachers}
                     columns={columns?.teachers}
                     onEdit={onEditTeacher}
                     displayForm={displayForm}
                     setDisplayForm={setDisplayForm}
                     initialValuesEdit={initialValuesEdit}
                     setInitialValuesEdit={setInitialValuesEdit}
                     handleSubmit={handleSubmit}
                     formValidation={formValidation}
                     /* onDelete={onDeleteUser} */
                     setSelectedUserId={setSelectedUserId}
                     deleteModalOpen={deleteModalOpen}
                     setDeleteModalOpen={setDeleteModalOpen}
                     isLoading={isLoading}
                  />
               </Tabs.Panel>}

            {!isLoading && data &&
               <Tabs.Panel value="Estudiantes" pt={20}>
                  < TanStackTable
                     data={data?.students}
                     columns={columns.students}
                  />

                  <Modal
                     classNames={{ title: 'AcademicView-studentModal--title' }}
                     opened={studentModal}
                     onClose={() => setStudentModal(null)}
                     title={studentModal?.title}
                     closeOnEscape={() => setStudentModal(null)}
                     closeOnClickOutside={() => setStudentModal(null)}
                     size="auto"
                  >
                     {studentModal?.data?.length === 0 &&
                        <p className='AcademicView-studentModal--notContent'>No hay nada para mostrar</p>
                     }

                     {studentModal?.data?.length > 0 &&
                        <>
                           {studentModal.isExams
                              ? < AcaStudentExamAccordion
                                 data={studentModal.data}
                              />
                              : < AcaStudentCertificatesAccordion
                                 userId={studentModal.userId}
                                 data={studentModal.data}
                              />
                           }
                        </>
                     }
                  </Modal>
               </Tabs.Panel>
            }
         </Tabs>
         <CellInfoModal opened={cellModal} onCloseFunct={setCellModal} text={cellModal?.text} title={cellModal?.title} />
      </section>
   )
}
