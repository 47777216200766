import React from 'react'
import { Grid, Group, Stack } from '@mantine/core'
import { DonutChart } from '@mantine/charts'
import { IconSchool } from '@tabler/icons-react'


export default function ModDashboardChart({ coursesInfo }) {
   return (
      <Grid.Col span={12}>
         <Stack
            align='center'
            p={10}
            miw={{ base: '100%', sm: '300px' }}
            style={{
               boxShadow: 'var(--box-shadow)',
               borderRadius: 'var(--border-radius-md)',
               backgroundColor: 'var(--color-base-light)',
            }}
         >
            <Group>
               < IconSchool />
               <h3 className='AdministrativeDashboard-card-title'>Clases según su tipo</h3>
            </Group>
            <DonutChart
               withTooltip
               data={[
                  {
                     name: 'Curso',
                     value: coursesInfo?.byType?.Curso,
                     color: 'var(--color-tertiary)'
                  },
                  {
                     name: 'Diplomado',
                     value: coursesInfo?.byType?.Diplomado,
                     color: 'var(--color-primary)'
                  },
                  {
                     name: 'Posgrado',
                     value: coursesInfo?.byType?.Posgrado,
                     color: 'var(--color-secondary)'
                  },
                  {
                     name: 'Webinar',
                     value: coursesInfo?.byType?.Webinar,
                     color: 'red.6'
                  },
                  {
                     name: 'Masterclass',
                     value: coursesInfo?.byType?.Masterclass,
                     color: 'green.6'
                  },
                  {
                     name: 'Taller',
                     value: coursesInfo?.byType?.Taller,
                     color: 'orange.6'
                  },
                  {
                     name: 'Bootcamp',
                     value: coursesInfo?.byType?.Bootcamp,
                     color: 'pink.1'
                  },
               ]} />
            <span style={{
               textAlign: 'center',
               fontSize: 'var(--fs-small)',
               color: 'var(--color-text-primary)'
            }}>Total <strong>{coursesInfo?.totals}</strong></span>
            <span style={{
               textAlign: 'center',
               fontSize: 'var(--fs-small)',
               color: 'var(--color-text-primary)'
            }}>{`Por tipo de capacitación`}</span>
         </Stack>
      </Grid.Col>
   )
}
