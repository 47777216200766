import { Flex, Grid, Skeleton, Stack } from '@mantine/core'
import React from 'react'

export default function ModDashbardSkeleton() {
   return (
      <>
         {/* chart */}
         <Grid.Col span={12}>
            <Stack
               align='center'
               p={10}
               miw={{ base: '100%', sm: '300px' }}
               style={{
                  boxShadow: 'var(--box-shadow)',
                  borderRadius: 'var(--border-radius-md)',
                  backgroundColor: 'var(--color-base-light)',
               }}
            >
               {/* title */}
               <Skeleton w='80%' maw={'200px'} h='32' circle />

               {/* chart component */}
               <Skeleton w='160px' h='160px' circle />

               {/* chart total */}
               <Skeleton w='54px' h='18px' circle />

               {/* chart total */}
               <Skeleton w='172px' h='22px' circle />
            </Stack>
         </Grid.Col>

         {/* teachers table */}
         <Grid.Col span={{ base: 12, md: 6 }}>

            <Stack gap={'16px'} bg={'var(--color-base-light)'}>
               {/* title table */}
               <Flex gap='md' align={'center'} p={8}>
                  <Skeleton circle w={'48px'} height={'24px'} />
                  <Skeleton circle h={'24px'} width={'80%'} maw={'222px'} />
               </Flex>
               {/* content */}
               <Stack gap='md' p={8}>
                  <Skeleton h={20} w={'70%'} circle />

                  {/* a card */}
                  <Flex
                     bg='var(--color-base-dark)'
                     style={{ borderRadius: 'var(--border-radius-lg)' }}
                     mt={10}
                     direction={{ base: 'column', xs: 'row' }}
                  >
                     <Flex w={{ base: '100%', xs: '60%' }} gap={'8px'} align={'center'} justify={'flex-start'} >
                        {/* number */}
                        <Skeleton circle h={'32px'} w={'32px'} style={{ borderRadius: 'var(--border-radius-lg)' }} />
                        {/* name */}
                        <Skeleton h={'18px'} w={'140px'} circle />
                     </Flex>

                     <Flex style={{ flexGrow: 1 }} p={{ base: '12px', xs: '0' }} pr={8} align={'center'} gap={'10px'} justify={{ base: 'flex-start', xs: 'flex-end' }} >

                        <Skeleton h={20} w={55} circle />
                        <Skeleton h={20} w={55} circle />
                     </Flex>
                  </Flex>

                  {/* a card */}
                  <Flex
                     bg='var(--color-base-dark)'
                     style={{ borderRadius: 'var(--border-radius-lg)' }}
                     mt={10}
                     direction={{ base: 'column', xs: 'row' }}
                  >
                     <Flex w={{ base: '100%', xs: '60%' }} gap={'8px'} align={'center'} justify={'flex-start'} >
                        {/* avatar */}
                        <Skeleton circle h={'32px'} w={'32px'} style={{ borderRadius: 'var(--border-radius-lg)' }} />
                        {/* name */}
                        <Skeleton h={'18px'} w={'140px'} circle />
                     </Flex>

                     <Flex style={{ flexGrow: 1 }} p={{ base: '12px', xs: '0' }} pr={8} align={'center'} gap={'10px'} justify={{ base: 'flex-start', xs: 'flex-end' }} >

                        <Skeleton h={20} w={55} circle />
                        <Skeleton h={20} w={55} circle />
                     </Flex>
                  </Flex>

                  {/* a card */}
                  <Flex
                     bg='var(--color-base-dark)'
                     style={{ borderRadius: 'var(--border-radius-lg)' }}
                     mt={10}
                     direction={{ base: 'column', xs: 'row' }}
                  >
                     <Flex w={{ base: '100%', xs: '60%' }} gap={'8px'} align={'center'} justify={'flex-start'} >
                        {/* number */}
                        <Skeleton circle h={'32px'} w={'32px'} style={{ borderRadius: 'var(--border-radius-lg)' }} />
                        {/* name */}
                        <Skeleton h={'18px'} w={'140px'} circle />
                     </Flex>

                     <Flex style={{ flexGrow: 1 }} p={{ base: '12px', xs: '0' }} pr={8} align={'center'} gap={'10px'} justify={{ base: 'flex-start', xs: 'flex-end' }} >

                        <Skeleton h={20} w={55} circle />
                        <Skeleton h={20} w={55} circle />
                     </Flex>
                  </Flex>
               </Stack>
            </Stack>
         </Grid.Col>

         {/* moderators table */}
         <Grid.Col span={{ base: 12, md: 6 }}>

            <Stack gap={'16px'} bg={'var(--color-base-light)'}>
               {/* title table */}
               <Flex gap='md' align={'center'} p={8}>
                  <Skeleton circle w={'48px'} height={'24px'} />
                  <Skeleton circle h={'24px'} width={'80%'} maw={'222px'} />
               </Flex>
               {/* content */}
               <Stack gap='md' p={8}>
                  <Skeleton h={20} w={'50%'} circle animate />

                  {/* a card */}
                  <Flex
                     bg='var(--color-base-dark)'
                     style={{ borderRadius: 'var(--border-radius-lg)' }}
                     mt={10}
                     direction={{ base: 'column', xs: 'row' }}
                  >
                     <Flex w={{ base: '100%', xs: '60%' }} gap={'8px'} align={'center'} justify={'flex-start'} >
                        {/* number */}
                        <Skeleton circle h={'36px'} w={'36px'} style={{ borderRadius: 'var(--border-radius-lg)' }} />
                        {/* name */}
                        <Skeleton h={'18px'} w={'140px'} circle />
                     </Flex>

                     <Flex style={{ flexGrow: 1 }} p={{ base: '12px', xs: '0' }} pr={8} align={'center'} gap={'10px'} justify={{ base: 'flex-start', xs: 'flex-end' }} >

                        <Skeleton h={20} w={55} circle />
                        <Skeleton h={20} w={55} circle />
                     </Flex>
                  </Flex>

                  {/* a card */}
                  <Flex
                     bg='var(--color-base-dark)'
                     style={{ borderRadius: 'var(--border-radius-lg)' }}
                     mt={10}
                     direction={{ base: 'column', xs: 'row' }}
                  >
                     <Flex w={{ base: '100%', xs: '60%' }} gap={'8px'} align={'center'} justify={'flex-start'} >
                        {/* number */}
                        <Skeleton circle h={'36px'} w={'36px'} style={{ borderRadius: 'var(--border-radius-lg)' }} />
                        {/* name */}
                        <Skeleton h={'18px'} w={'140px'} circle />
                     </Flex>

                     <Flex style={{ flexGrow: 1 }} p={{ base: '12px', xs: '0' }} pr={8} align={'center'} gap={'10px'} justify={{ base: 'flex-start', xs: 'flex-end' }} >

                        <Skeleton h={20} w={55} circle />
                        <Skeleton h={20} w={55} circle />
                     </Flex>
                  </Flex>

                  {/* a card */}
                  <Flex
                     bg='var(--color-base-dark)'
                     style={{ borderRadius: 'var(--border-radius-lg)' }}
                     mt={10}
                     direction={{ base: 'column', xs: 'row' }}
                  >
                     <Flex w={{ base: '100%', xs: '60%' }} gap={'8px'} align={'center'} justify={'flex-start'} >
                        {/* number */}
                        <Skeleton circle h={'32px'} w={'32px'} style={{ borderRadius: 'var(--border-radius-lg)' }} />
                        {/* name */}
                        <Skeleton h={'18px'} w={'140px'} circle />
                     </Flex>

                     <Flex style={{ flexGrow: 1 }} p={{ base: '12px', xs: '0' }} pr={8} align={'center'} gap={'10px'} justify={{ base: 'flex-start', xs: 'flex-end' }} >

                        <Skeleton h={20} w={55} circle />
                        <Skeleton h={20} w={55} circle />
                     </Flex>
                  </Flex>
               </Stack>
            </Stack>
         </Grid.Col>
      </>
   )
}
