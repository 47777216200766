import React, {useContext, useEffect, useState} from 'react';
import {useForm} from "@mantine/form";
import {Loader} from "@mantine/core";
import LessonForm from "../../Moderation/ModerationClasses/LessonForm/LessonForm";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext/AuthContext";
import showNotification from "../../../utils/showNotification";
import AcademicContext from "../../../contexts/AcademicContext/AcademicContext";

const editLesson = async (values, config) => {
    // Method coupled with editLesson on ModerationClasses.js
    const jsonData = {
        item: {_id: values._id},
        fields: {
            ...values,
            name: values.name.toUpperCase(),
        },
    };
    return axios.put(
        `${process.env.REACT_APP_BACKEND_SERVER}admin/moderation/updateLesson`,
        jsonData,
        config
    );
};

function EditLessonFormWrapper(props) {
    const {lessonId, setOpen} = props;
    const {authToken} = useContext(AuthContext)
    const {handleModified} = useContext(AcademicContext)
    const [loading, setLoading] = useState(false)
    const form = useForm({
        validateInputOnChange: true,
        validateInputOnBlur: true,
        initialValues: null,
        validate: {}
    })
    const config = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + authToken,
        }
    }

    const getInitialValues = async () => {
        // Get the lesson data from the API
        const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/lessons?_id=${lessonId}`
        try {
            const response = await axios.get(url, config)
            const rowData = response.data[0]

            form.setValues({
                _id: rowData._id,
                moderator: rowData.moderator,
                name: rowData.name,
                initDate: new Date(rowData.initDate),
                teachers: rowData.teachers,
                state: rowData.state,
                roomUrl: rowData.roomUrl,
                materials: rowData.materials,
            })
        } catch (error) {
            showNotification({
                color: "red",
                status: "error",
                title: "Error al obtener la información de la clase",
                message: `Detalle: ${error.message}`,
                autoClose: 12000
            });
        }
    }

    const handleSubmit = async (values) => {
        try {
            setLoading(true)
            await editLesson(values, config)
            setOpen(null)
            handleModified()
        } catch (error) {
            showNotification({
                color: "red",
                status: "error",
                title: "Operación fallida.",
                message: `No se pudo actualizar la clase. Detalle: ${error.message}`,
                autoClose: 12000
            });
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (Object.keys(form.values).length === 0) getInitialValues()
    }, []);

    if (Object.keys(form.values).length === 0) return <div style={{display: "grid", placeItems: "center"}}><Loader size={20}/></div>
    return (
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
            <LessonForm form={form} loading={loading}/>
        </form>
    );
}

export default EditLessonFormWrapper;