import { Button, Checkbox, FileInput, Grid, TextInput, Textarea } from '@mantine/core'
import { IconPhoto } from '@tabler/icons-react';
import React from 'react'
import {renderFile} from "../../../utils/renderFile";

export default function MkgtOpportunitiesForm({ form, isLoading }) {
   
   return (
      <Grid style={{ width: "100%" }} justify="center" align="center">

         <Grid.Col span={12}>
            <TextInput
               label="Título"
               disabled={isLoading}
               {...form.getInputProps("title")}
               w={"100%"}
            />
         </Grid.Col>

         <Grid.Col span={12}>
            <Textarea
               label="Descripción"
               disabled={isLoading}
               {...form.getInputProps("description")}
               w={"100%"}
            />
         </Grid.Col>

         <Grid.Col span={12}>
            <TextInput
               label="Enlace"
               disabled={isLoading}
               {...form.getInputProps("redirectUrl")}
               w={"100%"}
            />
         </Grid.Col>

         <Grid.Col span={{ base: 12, md: 6 }}>
            <FileInput
               {...form.getInputProps("logoUrl")}
               label="Imagen/Logo"
               withAsterisk
               w={"100%"}
               placeholder="Cargar logo"
               leftSection={<IconPhoto size={30} />}
               disabled={isLoading}
               onChange={(file) => {
                   renderFile(file).then((_fileBase64) => {
                     form.setFieldValue("logoUrl", _fileBase64);
                  });
               }}
               accept={['image/*', 'application/pdf']}
            />
         </Grid.Col>


         <Grid.Col span={{ base: 12, md: 6 }}>
            <FileInput
               {...form.getInputProps("bannerImageUrl")}
               label="Imagen de fondo o banner"
               withAsterisk
               w={"100%"}
               placeholder="Cargar banner"
               leftSection={<IconPhoto size={30} />}
               disabled={isLoading}
               onChange={(file) => {
                   renderFile(file).then((_fileBase64) => {
                     form.setFieldValue("bannerImageUrl", _fileBase64);
                  });
               }}
               accept={['image/*', 'application/pdf']}
            />
         </Grid.Col>

         <Grid.Col span={12}>
            <Checkbox
               style={{cursor: 'pointer '}}
               my={20}
               checked={form.values.BannerHighlight || false}
               onChange={({ target }) => form.setFieldValue('BannerHighlight', target.checked)}
               label="Destacar oportunidad"
               disabled={isLoading}
               {...form.getInputProps("BannerHighlight")}
               w={"100%"}
            />
         </Grid.Col>

         <Button
            type="submit"
            className="eventCalendarForm__form__button"
            disabled={isLoading}
            w={'max-content'}
         >
            Guardar oportunidad
         </Button>

      </Grid>
   )
}
