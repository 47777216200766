import './Login.css'
import logoDark from '../../assets/logo/logoForvet_dark.svg'
import { Button } from '@mantine/core'
import { randomValueFromArray } from '../../utils/randomValueFromArray'
import { LOGIN_BG_IMAGES } from '../../utils/login-bg-images'
import catImg from '../../assets/login/cat-observing.gif'
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react'
import { useIsMount } from '../../hooks/useIsMount'

export default function Login() {
   const { loginWithRedirect } = useAuth0();
   const [randomStyles, setRandomStyles] = useState({})
   const isMount = useIsMount()

   useEffect(() => {
      if (!isMount) return null
      const randomImage = randomValueFromArray(LOGIN_BG_IMAGES)

      const styles = {
         backgroundImage: `url(${randomImage.img})`,
         justifyContent: randomImage.justifyContent
      }
      
      return setRandomStyles({
         styles,
         randomImage
      })

   }, [isMount])



   return (
      <section className='LoginPage-container' style={randomStyles?.styles}>
         <article className='LoginPage-card-container'>

            <header className='LoginPage-card-header'>
               < img className='LoginPage--logo-img' src={logoDark} alt='logo de Forvet' />
               <h1 className='LoginPage--title'>Panel de administración</h1>
            </header>

            <figure className='LoginPage-card-gif-container'>
               < img className='LoginPage-card--gif' alt='gato leyendo' src={catImg} />
            </figure>

            <div>
               <Button color={randomStyles?.randomImage?.color} onClick={() => loginWithRedirect()}>
                  Ingresar
               </Button>
            </div>
         </article>
      </section>
   )
}
