import { Avatar, Flex, Grid, Modal, Stack } from '@mantine/core'
import './ModDashboardTeacherStatsModal.css'
import { renderInitialLetterToAvatar } from '../../../../utils/renderInitialLetterToAvatar'
import { IconPercentage, IconStarFilled } from '@tabler/icons-react'


const badgeBgColor = value => {
   if (!value) return null

   if (value > 75) return '#9ec098'
   if (value <= 75 && value >= 50) return '#cc8443'
   return '#963d3d'

}

export default function ModDashboardTeacherStatsModal({ opened: user, onClose }) {
   if (!user) return <></>
   return (
      <Modal
         opened={user}
         onClose={() => onClose(null)}
         closeOnEscape={() => onClose(null)}
         closeOnClickOutside={() => onClose(null)}
         withCloseButton={false}
      >
         <Stack>
            <Flex direction={{ base: 'column', xs: 'row' }}>
               <Flex align='center' gap={16}>
                  <Avatar src={user?.profilePicture} alt={user.teacherName} key={user.teacherName}>
                     {!user?.profilePicture && renderInitialLetterToAvatar(user.teacherName)}
                  </Avatar>
                  <Stack gap='0' p={0} >
                     <h4
                        className='ModDashboardModal-text-tiny-secondary'
                     >{user?.teacherName}</h4>
                     {user?.email && <p className='ModDashboardModal-text-tiny-secondary' style={{
                        opacity: '.9'
                     }}>{user?.email}</p>}
                  </Stack>
               </Flex>
               <Stack align={'end'} justify='center' gap='4px' p={0} style={{ flexGrow: 1 }}>
                  <Flex align='center' gap='10px'>
                     <span className='ModDashboardModal-text-tiny'>Puntaje general:</span>
                     <span className='ModDashboardModal-badge ModDashModal-badge--tertiary'>
                        <IconPercentage size={'12px'} />
                        {user.overallTeacherScore}
                     </span>
                  </Flex>
                  <Flex align='center' gap='10px'>
                     <span className='ModDashboardModal-text-tiny'>Votos totales:</span>
                     <span className='ModDashboardModal-badge ModDashModal-badge--secondary'>
                        <IconStarFilled size={'12px'} />
                        {user.totalReviews}</span>
                  </Flex>
               </Stack>
            </Flex>
            {user?.units && user.units?.length > 0 &&
               <>
                  <div className='ModDashboardTeacherStatsModal--line'></div>
                  <Grid>
                     <Grid.Col span={8}>
                        <span className='ModDashboardModal-text-tiny-secondary'>
                           Unidad Academica
                        </span>
                     </Grid.Col>
                     <Grid.Col span={2}>
                        <Flex align='center' justify='center'>
                           <span className='ModDashboardModal-text-tiny-secondary'>
                              Votos
                           </span>
                        </Flex>
                     </Grid.Col>
                     <Grid.Col span={2}>
                        <Flex align='center' justify='center'>
                           <span className='ModDashboardModal-text-tiny-secondary'>
                              Puntaje
                           </span>
                        </Flex>
                     </Grid.Col>
                     {user.units?.map(unit => (
                        <>
                           <Grid.Col key={unit.acaUnit} span={8} style={{ overflow: 'hidden' }} >
                              <span className='ModDashboardModal-text-tiny ModDashboardModal--name-title'>
                                 {unit.acaUnitName}
                              </span>
                           </Grid.Col>
                           <Grid.Col span={2}>
                              <Flex align='center' justify='center'>
                                 <span className='ModDashboardModal-text-tiny '
                                    style={{ fontWeight: 700 }}
                                 >
                                    {unit.countReviews}
                                 </span>
                              </Flex>

                           </Grid.Col>
                           <Grid.Col span={2}>
                              <Flex align='center' justify='center'>
                                 <span className='ModDashboardModal-badge' style={{ backgroundColor: badgeBgColor(unit.teacherScore) }}>
                                    <IconPercentage size={'12px'} />
                                    {unit.teacherScore}
                                 </span>
                              </Flex>

                           </Grid.Col>
                        </>
                     ))}
                  </Grid>
               </>
            }
         </Stack>
      </Modal>
   )
}
