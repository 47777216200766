import './UserCard.css'
import {  Avatar, Badge, Card, Flex, Group, Stack, } from '@mantine/core'
import { IconPhone, IconMail } from '@tabler/icons-react'
import { mapUserType } from '../../utils/user-types'

export default function UserCard({profilePicture, name, lastname, type, phone, email,}) {
   return (
      <Card maw={{ base: 'unset', md: '400px' }} w={{ base: '100%' }} shadow="sm" padding="lg" radius="md" withBorder>
         <Group justify='center' className='AdministrativeUserDetails-card-headerContainer'>
            <Avatar
               src={profilePicture}
               h={150}
               w={150}
               alt={`foto de perfil de ${name} ${lastname}`}
            />
            <Badge className='AdministrativeUserDetails-card---userType' >{mapUserType[type] || 'usuario'}</Badge>
         </Group>

         <Flex gap='sm' direction={{ base: 'column', sm: 'row' }} justify={{ base: 'unset', sm: 'space-between' }} align='center' mb={10}>
            <h3 className='AdministrativeUserDetails--username'>{`${name} ${lastname}`}</h3>
            {/* <Badge color={render.badge.color}>{render.badge.text}</Badge> */}
         </Flex>

         <Stack>
            {phone &&
               <Flex
                  gap="sm"
                  direction={{ base: 'column', sm: 'row' }}
                  align={'center'}
               >
                  < IconPhone color='#5D5A6F' />
                  <p className='AdministrativeUserDetails--itemText'>{phone}</p>
               </Flex>
            }
            {email &&
               <Flex
                  gap="sm"
                  direction={{ base: 'column', sm: 'row' }}
                  align={'center'}
               >
                  < IconMail color='#5D5A6F' />
                  <p className='AdministrativeUserDetails--itemText'>{email}</p>
               </Flex>
            }

         </Stack>

         {/* <Button
            color={render.button.color}
            fullWidth
            mt="md"
            radius="md"
            type='submit'
            disabled={isLoading}
         >
            {isLoading ? < Loader size={20} color='#fff' /> : render.button.text}
         </Button> */}
      </Card>
   )
}
