import { useContext } from 'react'
import showNotification from '../utils/showNotification';
import { isBase64 } from '../utils/isBase64';
import axios from 'axios';
import AuthContext from '../contexts/AuthContext/AuthContext';
import { ERROR_HANDLER } from '../utils/error-handler';

export default function useS3() {
   const { authToken } = useContext(AuthContext)

   const uploadToS3 = async ({ image: file, fileName, fileDirectory, isRequired = false }) => {
      if(!isRequired && !file) return ''
      if (!file) return showNotification({
         color: "red",
         status: "error",
         title: "Error al cargar el archivo.",
         message: `Detalle: No se ha encontrado ningún archivo o URL para guardar.`
      })

      let imageUrl

      if (isBase64(file)) {
         // Convertir el archivo base64 en un Blob
         const base64Data = file.split(',')[1]; // Remove data URI prefix
         const byteString = atob(base64Data);
         const mimeType = file.split(';')[0].split(':')[1]; // Extract mimeType
         const byteArray = new Uint8Array(byteString.length);
         for (let i = 0; i < byteString.length; i++) {
            byteArray[i] = byteString.charCodeAt(i);
         }
         const blob = new Blob([byteArray], { type: mimeType });

         // Crear FormData y agregar el Blob
         const formData = new FormData()
         formData.append('directory', fileDirectory)
         formData.append('name', `${fileName}.${mimeType.split('/')[1]}`)
         formData.append('file', blob)

         try {
            const awsResponse = await axios.post(
               `${process.env.REACT_APP_BACKEND_SERVER}admin/uploadToS3`,
               formData, {
                  headers: {
                     'Content-Type': 'multipart/form-data',
                     "Authorization": `Bearer ${authToken}`
                  },
               }
            );
            imageUrl = awsResponse.data.url
         } catch (error) {
            showNotification({
               color: "red",
               status: "error",
               title: "Error al cargar el archivo.",
               message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`
            })
         }
      } 
      
      return imageUrl
   }

   return {
      uploadToS3
   }
}
