import React from 'react';
import {Button, Grid, NumberInput, Select} from "@mantine/core";

function AdministrativeFeeForm(props) {
    const {form, isLoading} = props;

    return (
        <Grid style={{width: "100%"}}>
            <Grid.Col span={6}>
                <NumberInput
                    label="Tarifa de la clase"
                    {...form.getInputProps('lessonFee')}
                    min={0}
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <Select
                    label="Moneda"
                    {...form.getInputProps('feeCurrency')}
                    data={[
                        {value: "USD", label: "USD"},
                        {value: "ARS", label: "ARS"}
                    ]}
                />
            </Grid.Col>
            <Grid.Col span={3}>
                <Button
                    fullWidth
                    mt="md"
                    radius="md"
                    type='submit'
                    disabled={isLoading}
                >
                    Guardar cambios
                </Button>
            </Grid.Col>
        </Grid>
    );
}

export default AdministrativeFeeForm;