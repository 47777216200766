import { Avatar, Group, Modal, Stack } from '@mantine/core'
import ContentCreator from '../../../../components/ContentCreator/ContentCreator'
import DeleteConfirmationModal from '../../../../components/DeleteConfirmationModal/DeleteConfirmationModal'
import TanStackTable from '../../../../components/Table/TanStackTable/TanStackTable'
import AcaUsersForm from '../AcaUsersForm/AcaUsersForm'
import './AcaTeachersTable.css'

export default function AcaTeachersTable({
   data,
   columns,
   displayForm,
   setDisplayForm,
   initialValuesEdit,
   setInitialValuesEdit,
   handleSubmit,
   onEdit,
   onDelete,
   // setSelectedUserId,
   // deleteModalOpen,
   // setDeleteModalOpen,
   isLoading,
   formValidation,

}) {

   return (
      <section className='AcademicView-layout--container AcaTeachersTable-container'>
         {displayForm && (
            <ContentCreator
               title={initialValuesEdit ? "Editar profesor" : "Crear profesor"}
               onCancel={() => {
                  setDisplayForm(false)
                  setInitialValuesEdit(null)
               }}
               initialValues={initialValuesEdit ?? {
                  type: "teacher",
                  name: "",
                  lastname: "",
                  profilePicture: "",
                  email: "",
                  phone: "",
                  signature: "",
                  sendMail: "true",
               }}
               validation={formValidation}
               renderPreview={(_form) => (
                  <Group align='end' justify='center'>
                     <Stack align='center' >
                        < Avatar w={150} h={150} src={_form.form.values.profilePicture} />
                        <span className='AcademicTeacherTable-preview--label'>Foto de perfil</span>
                     </Stack>
                     <Stack align='center' >
                        < Avatar w={75} h={75} src={_form.form.values.signature} />
                        <span className='AcademicTeacherTable-preview--label'>Firma</span>
                     </Stack>
                  </Group>
               )}
               renderForm={(_form) => (
                  <AcaUsersForm form={_form.form} loading={isLoading} isCreate={!initialValuesEdit} handleSubmit={handleSubmit} />
               )}
            />
         )}

         {!displayForm && data && (
            < TanStackTable
               data={data}
               columns={columns}
               editFunc={onEdit}
               onDelete={onDelete}
               addButtonFunc={() => {
                  setDisplayForm(!displayForm)
                  if (displayForm) setInitialValuesEdit(null)
               }}
            />
         )}

         {/* <DeleteConfirmationModal
            opened={deleteModalOpen}
            loading={isLoading}
            onClose={() => {
               setDeleteModalOpen(false)
               setSelectedUserId(null)
            }}
            onCancel={() => {
               setDeleteModalOpen(false)
               setSelectedUserId(null)
            }}
            onConfirm={onDelete}
         /> */}
      </section>
   )
}
