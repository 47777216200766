import "./PreloadedLessonsTable.css";
import {NumberInput, Select, Table, Textarea} from "@mantine/core";
import moment from "moment";
import React, {useContext} from "react";
import AppContext from "../../../contexts/AppContext";
import {IconEdit, IconSquareCheck} from "@tabler/icons-react";
import {DateInput} from "@mantine/dates";
import MantineSelect from "../../../components/MantineSelect/MantineSelect";

const EditingRow = ({form, index, setEditingRow, teachersUsers}) => {
    return (
        <Table.Tr key={index} className="preloadedLessonsTable__editingRow">
            <Table.Td style={{width: "40px"}}>
                <NumberInput
                    label=""
                    {...form.getInputProps(`csvData.${index}.module_number`)}
                    min={1}
                />
            </Table.Td>
            <Table.Td>
                <Textarea
                    minRows={6}
                    {...form.getInputProps(`csvData.${index}.module_name`)}
                />
            </Table.Td>
            <Table.Td>
                <DateInput
                    dateParser={(dateString) => moment(dateString, 'DD/MM/YYYY').toDate()}
                    allowFreeInput
                    locale="es"
                    placeholder="dd/mm/yyyy"
                    inputFormat="DD/MM/YYYY"
                    valueFormat="DD/MM/YYYY"
                    value={moment(form.values.csvData[index].date, 'DD/MM/YYYY').toDate()}
                    onChange={(e) =>
                        form.setFieldValue(`csvData.${index}.date`, moment(e, 'DD/MM/YYYY').toDate())
                    }
                    autoComplete='off'
                />
            </Table.Td>
            <Table.Td>
                <Select
                    label=""
                    name={`csvData.${index}.teacher`}
                    value={form.values.csvData[index].teacher}
                    onChange={(_val) => {
                        form.setFieldValue(`csvData.${index}.teacher`, _val);
                    }}
                    data={teachersUsers}
                    {...form.getInputProps(`csvData.${index}.teacher`)}
                />
            </Table.Td>
            <Table.Td>
                <Textarea
                    minRows={3}
                    {...form.getInputProps(`csvData.${index}.lesson_name`)}
                />
            </Table.Td>
            <Table.Td>
                <IconSquareCheck
                    className="preloadedLessonsTable__editBtn"
                    size={30}
                    m={'0 auto'}
                    onClick={() => setEditingRow(null)}
                />
            </Table.Td>
        </Table.Tr>
    )
}

const PreloadedLessonsTable = ({form, editingRow, setEditingRow}) => {
    const {csvData} = form.values;
    const {teachersUsers} = useContext(AppContext);

    const getTeacherById = (id) => {
        return teachersUsers.find(teacher => teacher.value === id);
    }

    const getTeacherByName = (name) => {
        if (!name) return null;
        const nameParts = name.toLowerCase().split(' ');

        // First, look for exact matches with the last name
        const lastNameMatches = teachersUsers.filter(teacher =>
            teacher.label.toLowerCase().includes(nameParts[nameParts.length - 1])
        );

        if (lastNameMatches.length === 1) {
            return lastNameMatches[0]; // return if there's only one match
        } else if (lastNameMatches.length > 1) {
            // If there are multiple matches, look for exact matches with the full name
            return lastNameMatches.find(teacher =>
                nameParts.every(part => teacher.label.toLowerCase().includes(part))
            );
        }

        // If there are no exact matches with the last name, look for exact matches with the full name
        return teachersUsers.find(teacher =>
            nameParts.every(part => teacher.label.toLowerCase().includes(part))
        );
    };

    return (<Table.ScrollContainer minWidth={800}>
        <Table verticalSpacing="xs">
            <Table.Thead>
                <Table.Tr>
                    <Table.Th>N° MÓDULO</Table.Th>
                    <Table.Th>MÓDULO</Table.Th>
                    <Table.Th>FECHA</Table.Th>
                    <Table.Th>DOCENTE</Table.Th>
                    <Table.Th>CLASE</Table.Th>
                    <Table.Th>ACCIONES</Table.Th>
                </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
                {csvData.map((row, index) => {
                    let displayTeacher = getTeacherById(row.teacher);

                    if (displayTeacher) {
                        displayTeacher = displayTeacher.label;
                    } else {
                        const foundTeacher = getTeacherByName(row.teacher);
                        if (foundTeacher) {
                            form.setFieldValue(`csvData.${index}.teacher`, foundTeacher.value);
                            displayTeacher = foundTeacher.label;
                        } else {
                            displayTeacher = <MantineSelect
                                form={form}
                                name={`csvData.${index}.teacher`}
                                label=""
                                options={teachersUsers}
                            />
                        }
                    }

                    if (editingRow === index) {
                        return <EditingRow
                            form={form}
                            index={index}
                            setEditingRow={setEditingRow}
                            teachersUsers={teachersUsers}
                        />
                    }
                    return (
                        <Table.Tr key={index}>
                            <Table.Td>{row.module_number}</Table.Td>
                            <Table.Td>{row.module_name.toUpperCase()}</Table.Td>
                            <Table.Td>{moment(row.date, "DD/M/YYYY").format("DD/MM/YYYY")}</Table.Td>
                            <Table.Td>{displayTeacher}</Table.Td>
                            <Table.Td>{row.lesson_name.toUpperCase()}</Table.Td>
                            <Table.Td>
                                <IconEdit
                                    className='preloadedLessonsTable__editBtn'
                                    size={30}
                                    onClick={() => setEditingRow(index)}
                                    m={'0 auto'}/>
                            </Table.Td>
                        </Table.Tr>
                    )
                })}
            </Table.Tbody>
        </Table>
    </Table.ScrollContainer>)
}

export default PreloadedLessonsTable;