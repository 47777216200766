import * as yup from 'yup';
import moment from "moment";

const ExamModel = () => {
    const initialValues = {
        hasExam: false,
        exam: {
            // Coupled with initial values on convertInitialValues function
            initDate: moment().add(10, 'minutes').toDate(),
            endDate: moment().add(2, 'days').toDate(),
            duration: 30,
            approvalPercentage: 0,
            academicUnit: '',
            questionary: '',
            recoveryExam: false,
            tryQuantity: 1
        }
    }
    const validationSchema = yup.object().shape({
        hasExam: yup.boolean(),
        exam: yup.object().shape({
            initDate: yup.date()
                .when('hasExam', {
                    is: true,
                    then: yup.date()
                        .min(new Date(), 'La fecha indicada ya pasó')
                        .max(yup.ref('exam.endDate'), 'La fecha de inicio no puede ser superior o igual a la fecha de finalización.'),
                    otherwise: yup.date().nullable()
                }),
            endDate: yup.date()
                .when('hasExam', {
                    is: true,
                    then: yup.date()
                        .min(yup.ref('exam.initDate'), 'La fecha de finalización no puede ser anterior o igual a la fecha de inicio.'),
                    otherwise: yup.date().nullable()
                }),
            duration: yup.number()
                .when('hasExam', {
                    is: true,
                    then: yup.number()
                        .min(30, 'La duración del examen debe ser de al menos 30 minutos.')
                        .max(360, 'La duración del examen es de hasta 360 minutos.'),
                    otherwise: yup.number().nullable()
                }),
            approvalPercentage: yup.number()
                .when('hasExam', {
                    is: true,
                    then: yup.number()
                        .min(0, 'El porcentaje de aprobación del examen debe ser de al menos 0%.')
                        .max(100, 'El porcentaje de aprobación del examen es de hasta 100%.'),
                    otherwise: yup.number().nullable()
                }),
            questionary: yup.string()
                .when('hasExam', {
                    is: true,
                    then: yup.string().required('Por favor, elige un cuestionario para crear el examen.'),
                    otherwise: yup.string().nullable()
                }),
            recoveryExam: yup.boolean()
                .when('hasExam', {
                    is: true,
                    then: yup.boolean().required('El valor del campo de recuperatorio es requerido.')
                }),
            tryQuantity: yup.number()
                .when('hasExam', {
                    is: true,
                    then: yup.number()
                        .min(1, 'La cantidad mínima debe ser de 1 intento.')
                        .max(3, 'La cantidad máxima es de 3 intentos.'),
                    otherwise: yup.number().nullable()
                })
        })
    });

    return {initialValues, validationSchema}
}

export {ExamModel};
