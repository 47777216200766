import {useContext, useEffect, useState} from "react";
import SalesDashboardStats from "../../../components/SalesDashboardStats/SalesDashboardStats";
import './SalesDashboardCarousel.css';
import axios from "axios";
import "@splidejs/react-splide/css";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import AuthContext from "../../../contexts/AuthContext";
import {SalesDashboardProfitCommissions} from "../../../components/SalesDashboardProfitCommissions/SalesDashboardProfitCommissions";

const SalesDashboardCarousel = () => {
    const [dashboardData, setDashboardData] = useState();
    const {authToken} = useContext(AuthContext);

    const init = async () => {
        const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/ventas/dashboardInfo`;
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        };
        const dashboardResponse = await axios.get(url, config);
        setDashboardData(dashboardResponse.data);
    };
    useEffect(() => {
        init();
    }, [authToken]);

    return (
        <div className="salesDashboardCarousel--father-div">
            <h3 className="salesDashboardCarousel-header">Estadísiticas de Ventas:</h3>
            <div className="salesDashboardCarousel-splide-div">
                <h4 className="salesDashboardCarousel-splide-header">Alumnos por curso:</h4>
                <Splide
                    options={{
                        perPage: 3,
                        perMove: 1,
                        gap: "1rem",
                        breakpoints: {
                            1200: {
                                perPage: 2,
                            },
                            801: {
                                perPage: 1,
                            }
                        }
                    }}
                >
                    {dashboardData?.courses.map((item, index) => (
                        <SplideSlide key={index}>
                            <SalesDashboardStats item={item}/>
                        </SplideSlide>
                    ))}
                </Splide>
            </div>
            <div className="salesDashboardCarousel-profits-section">
                <h4 className="salesDashboardCarousel-splide-header">Comisiones por mes:</h4>
                {dashboardData?.commissionsPerMonth &&
                <SalesDashboardProfitCommissions item={dashboardData?.commissionsPerMonth}/>
                }
            </div>
        </div>
    );
};
export default SalesDashboardCarousel;
