import React from "react";
import { Button, Accordion, Avatar, Badge } from "@mantine/core";
import "./AcademicValorationsComments.css";

const AcademicValorationsComments = ({ onBack, comments, unitName }) => {
  return (
    <div className="academicValorationsComments-mainDiv">
      <div className="academicValorationsComments-headerDiv">
        <div className="academicValorationsComments-titlesDiv">
          <h3 className="academicValorationsComments-header">
            Comentarios de la unidad académica
          </h3>
          <p>
            <Badge
              classNames={{ root: "academicValorationsComments-subtitleBadge" }}
            >
              {unitName}
            </Badge>
          </p>
        </div>
        <Button
          className="academicValorationsComments-buttonRoot"
          onClick={onBack}
          variant="outline"
          color="var(--color-tertiary)"
        >
          Volver atrás
        </Button>
      </div>
      {comments.length === 0 ? (
        <div>
          <h3 className="academicValorationsContainer-noContent-header">Aún no hay valoraciones para este curso.</h3>
        </div>
      ) : (
        <Accordion
          transitionDuration={1000}
          classNames={{
            control: "academicValorationsComments-accordion-control",
            panel: "academicValorationsComments-accordion-panel",
          }}
        >
          {comments.map((comment, index) => (
            <Accordion.Item key={index} value={comment.user._id}>
              <Accordion.Control>
                <div className="academicValorationsComments-accordionControl-div">
                  <Avatar src={comment.user.profilePicture} />
                  {`${comment.user.name} ${comment.user.lastname}`}
                </div>
              </Accordion.Control>
              <Accordion.Panel>{comment.extraCommit}</Accordion.Panel>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </div>
  );
};

export default AcademicValorationsComments;
