import React, { useContext, useEffect, useState } from "react";
import "./MarketingFaqsContainer.css";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";
import DeleteConfirmationModal from "../../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import TanStackTable from "../../../../components/Table/TanStackTable/TanStackTable";
import { createColumnHelper } from "@tanstack/react-table";
import ContentCreator from "../../../../components/ContentCreator/ContentCreator";
import showNotification from "../../../../utils/showNotification";
import { ERROR_HANDLER } from "../../../../utils/error-handler";
import AccordionItem from "../../../../components/AccordionItem/AccordionItem";
import MktgFaqsForm from "../MarketingFaqsForm/MarketingFaqsForm";

const createEvent = async (values, config) => {
  const jsonData = {
    data: {
      ...values,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_BACKEND_SERVER}admin/marketing/faqs`,
    jsonData,
    config
  );
};
const editEvent = async (values, config) => {
  const jsonData = {
    item: {_id: values.id},
    field: {
      ...values,
    },
  };
  return axios.put(
    `${process.env.REACT_APP_BACKEND_SERVER}admin/marketing/faqs`,
    jsonData,
    config
  );
};

function MarketingFaqsContainer() {
  const { authToken } = useContext(AuthContext);
  const [faqsEventsData, setFaqsEventsData] = useState(null);
  const [displayForm, setDisplayForm] = useState(false);
  const [initialValuesEdit, setInitialValuesEdit] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedFaqsEventId, setSelectedFaqsEventId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState(false);

  const DISPLAY_TYPE = [
    { value: "landing", label: "Web ventas" },
    { value: "campus", label: "Campus" },
  ];

  // COLUMN DEFINITIONS
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("question", {
      cell: (info) => info.getValue(),
      header: "Pregunta",
    }),
    columnHelper.accessor("answer", {
      cell: (info) => info.getValue(),
      header: "Respuesta",
    }),
    columnHelper.accessor("category", {
      cell: (info) => info.getValue(),
      header: "Categoría",
    }),
    columnHelper.accessor("display", {
      cell: (info) =>{
        const displayValue = info.getValue();
        const displayLabel = DISPLAY_TYPE.find(type => type.value === displayValue)?.label || displayValue;
        return displayLabel;
      },
      header: "Espacio",
    }),
  ];

  // TABLE FUNCTIONS
  const editFaqsEvent = (rowData) => {
    if (rowData) {
      setInitialValuesEdit({
        id: rowData._id,
        question: rowData.question,
        answer: rowData.answer,
        display: rowData.display,
        category: rowData.category,
      });
      setDisplayForm(true);
    }
  };

  const deleteFaqsEvent = async (idFaqs = null) => {
    if (deleteModalOpen) {
      try {
        setLoading(true);
        await axios.delete(
          `${process.env.REACT_APP_BACKEND_SERVER}admin/marketing/faqs`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + authToken,
            },
            data: { _id: selectedFaqsEventId._id },
          }
        );
        setUpdateData(!updateData);
        showNotification({
          color: "green",
          status: "success",
          title: "¡Operación exitosa!",
          message: "El elemento seleccionado ha sido correctamente eliminado.",
          autoClose: 12000,
        });
      } catch (error) {
        console.error(error);
        showNotification({
          color: "red",
          status: "error",
          title: "Operación fallida.",
          message: `No se pudo eliminar la notificación. Detalle: ${
            ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError
          }`,
          autoClose: 12000,
        });
      } finally {
        setDeleteModalOpen(false);
        setLoading(false);
      }
    } else {
      setSelectedFaqsEventId(idFaqs);
      setDeleteModalOpen(true);
    }
  };

  const handleSubmit = async (values) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
    };

    try {
      setLoading(true);
      // Check if it's an edit or a new event
      if (initialValuesEdit) {
        await editEvent(values, config);
      } else {
        await createEvent(values, config);
      }

      showNotification({
        color: "green",
        status: "success",
        title: "¡Excelente! Operación exitosa.",
        message: `El evento ${values.name} ha sido creado / editado con éxito.`,
      });

      setUpdateData(!updateData);
    } catch (error) {
      showNotification({
        color: "red",
        status: "error",
        title: "Operación fallida.",
        message: `No se pudo crear / editar el evento. Detalle: ${
          ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError
        }`,
        autoClose: 12000,
      });
    } finally {
      setLoading(false);
      setDisplayForm(false);
      setInitialValuesEdit(null);
    }
  };

  // DATA INITIALIZATION
  const init = async () => {
    const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/marketing/faqs`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    // Fetch schedule filtered data
    const scheduleResponse = await axios.get(url, config);
    setFaqsEventsData(scheduleResponse.data);
  };

  useEffect(() => {
    init();
  }, [updateData]);
  
  return (
    <div className="mktgFaqs__container">
      {displayForm && (
        <ContentCreator
          title={initialValuesEdit ? "Editar evento" : "Crear evento"}
          submitFunc={(values) => handleSubmit(values)}
          onCancel={() => {
            setDisplayForm(false);
            setInitialValuesEdit(null);
          }}
          initialValues={initialValuesEdit ?? null}
          renderPreview={(_form) => (
            <AccordionItem
              id= {_form.form.values._id}
              question={_form.form.values.question}
              answer={_form.form.values.answer}
            />
          )}
          renderForm={(_form) => (
            <MktgFaqsForm form={_form.form} loading={loading} />
          )}
        />
      )}
      {faqsEventsData && (
        <TanStackTable
          loading={loading}
          data={faqsEventsData}
          columns={columns}
          editFunc={editFaqsEvent}
          onDelete={deleteFaqsEvent}
          displayForm={displayForm}
          addButtonFunc={() => {
            setDisplayForm(!displayForm);
            if (displayForm) setInitialValuesEdit(null);
          }}
        />
      )}

      <DeleteConfirmationModal
        opened={deleteModalOpen}
        loading={loading}
        onClose={() => {
          setDeleteModalOpen(false);
          setSelectedFaqsEventId(null);
        }}
        onCancel={() => {
          setDeleteModalOpen(false);
          setSelectedFaqsEventId(null);
        }}
        onConfirm={deleteFaqsEvent}
      />
    </div>
  );
}

export default MarketingFaqsContainer;
