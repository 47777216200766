import React from 'react'
import ViewCard from '../../../components/ViewCard/ViewCard'
import './AdministrativeHome.css'
import {IconSwitch, IconBuildingBank, IconPigMoney, IconMoodSadSquint, IconCreditCard} from '@tabler/icons-react'
import AdministrativeDashboard from '../AdministrativeDashboard/AdDashboardView/AdministrativeDashboard'

const ADMINISTRATIVE_ITEMS = [
   {
      title: 'Usuarios',
      text: 'Revisar y ajustar información de los usuarios',
      href: 'usuarios',
      icon: < IconSwitch size={50} className='ViewCard--icon' color='var(--color-white)' />,
   },
   {
      title: 'Ingresos',
      text: 'Visualizar la información relacionada a ingresos por inscripciones.',
      href: 'ingresos',
      icon: < IconPigMoney size={50} className='ViewCard--icon' color='var(--color-white)' />,
   },
   {
      title: 'Egresos',
      text: 'Visualizar la información pagos a realizar.',
      href: 'egresos',
      icon: < IconMoodSadSquint size={50} className='ViewCard--icon' color='var(--color-white)' />,
   },
   {
      title: 'Cuentas bancarias',
      text: 'Habilitar o deshabilitar cuentas. Crear nuevas cuentas bancarias disponibles.',
      href: 'cuentas-bancarias',
      icon: < IconBuildingBank size={50} className='ViewCard--icon' color='var(--color-white)' />,
   },
   {
      title: 'Sponsors',
      text: 'Habilitar o deshabilitar sponsors. Crear nuevos sponsors.',
      href: 'sponsors',
      icon: < IconBuildingBank size={50} className='ViewCard--icon' color='var(--color-white)' />,
   },
   {
      title: 'Cobros',
      text: 'Establecer cuotas como pagadas o pendientes.',
      href: 'cobros',
      icon: < IconCreditCard size={50} className='ViewCard--icon' color='var(--color-white)'/>,
   },
]

export default function AdministrativeHome() {
   return (
      <>
      <div className='AdministrativeHome-grid-container'>
         {ADMINISTRATIVE_ITEMS.map(item => (
            < ViewCard key={item.name} item={item} />
         ))}
      </div>
      <div>
         < AdministrativeDashboard />
      </div>
      </>
   )
}
