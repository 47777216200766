import React, {useEffect, useState, useMemo} from "react";
import AuthContext from "./AuthContext.js";
import {useAuth0} from "@auth0/auth0-react";

const AuthContextProvider = ({children}) => {
    const {getIdTokenClaims, isAuthenticated, isLoading} = useAuth0();

    const [authToken, setAuthToken] = useState(null);
    const [isAuth, setIsAuth] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [idAuth, setIdAuth] = useState(null);

    async function getToken() {
        let claims = await getIdTokenClaims();
        setIdAuth(claims["sub"]);
        return claims.__raw;
    }

    useEffect(() => {
        const init = async () => {
            if (!isLoading) {

                getToken()
                    .then((id_token) => {
                        // console.log(id_token)
                        setIsAuth(true);
                        setAuthToken(id_token);
                        setIsReady(true);
                    })
                    .catch((error) => {
                        console.log(error)
                        setIsAuth(false);
                        setIsReady(true);
                    });
            }
        };

        init();
    }, [authToken, isAuthenticated, isLoading]);

    const context = useMemo(
        () => ({
            idAuth,
            authToken,
            isAuth,
            isReady,
        }),
        [idAuth, authToken, isAuth, isReady]
    );
    return (
        <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
    );
};
export default AuthContextProvider;
