import * as yup from "yup";

const UsersModel = () => {
    const initialValues = {
        director: [],
        codirector: [],
        coordinator: "",
        teachers: [],
    }

    const validationSchema = yup.object().shape({
        coordinator: yup.string().required("El coordinador es obligatorio"),
        teachers: yup.array().test('is-empty', 'Debe seleccionar al menos un profesor', (value) => {
            return value.length > 0;
        })
    });

    return {initialValues, validationSchema}
}

export {UsersModel};
