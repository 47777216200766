import { Button, Checkbox, FileInput, Grid, Group, TextInput } from '@mantine/core'
import { IconAt, IconPhone, IconPhoto, IconSignature } from '@tabler/icons-react'
import React, { useState } from 'react'
import PhoneInputField from '../../../../components/PhoneInputField/PhoneInputField'
import {renderFile} from "../../../../utils/renderFile";


export default function AcaUsersForm({ form, loading, isCreate, handleSubmit }) {

   const mapCheckbox = {
      true: 'true',
      false: 'false'
   }

   const [checked, setChecked] = useState(mapCheckbox[form.getInputProps("sendMail").value])

   return (
      <Grid style={{ width: "100%" }} justify="center" align="center">
         <Grid.Col span={{base: 12, sm: 6}}>
            <TextInput
               disabled={loading}
               label="Nombre:"
               {...form.getInputProps("name")}
               w={'100%'}
               placeholder="Juan Antonio"

            />
         </Grid.Col>
         <Grid.Col span={{base: 12, sm: 6}}>
            <TextInput
               disabled={loading}
               label="Apellido:"
               {...form.getInputProps("lastname")}
               w={'100%'}
               placeholder="Pérez"

            />
         </Grid.Col>

         <Grid.Col span={{base: 12, md: 6}}>
            <TextInput
               disabled={loading}
               leftSectionPointerEvents="none"
               leftSection={<IconAt />}
               {...form.getInputProps("email")}
               label="Email:"
               placeholder="ejemplo@gmail.com"
            />
         </Grid.Col>
         <Grid.Col span={{base: 12, md: 6}}>
            < PhoneInputField
               disabled={loading}
               leftSection={<IconPhone />}
               {...form.getInputProps("phone")}
               placeholder="3446544456"
            />
         </Grid.Col>

         <Grid.Col span={isCreate ? {base: 12, md: 6} : {base: 12}}>
            <Group>
               <FileInput
                  disabled={loading}
                  {...form.getInputProps("profilePicture")}
                  label="Cargar imagen de perfil"
                  placeholder='Cargar imagen'
                  w={{base: '100%', sm: 'max-content'}}
                  leftSection={< IconPhoto size={30} />}
                  onChange={(file) => {
                      renderFile(file).then((_fileBase64) => {
                           form.setFieldValue("profilePicture", _fileBase64)
                        })
                  }}
                  accept={['image/*']}
               />

               <FileInput
                  disabled={loading}
                  {...form.getInputProps("signature")}
                  label="Cargar firma"
                  placeholder='Cargar imagen'
                  w={{base: '100%', sm: 'max-content'}}
                  leftSection={< IconSignature size={30} />}
                  onChange={(file) => {
                      renderFile(file).then((_fileBase64) => {
                           form.setFieldValue("signature", _fileBase64)
                        })
                  }}
                  accept={['image/*']}
               />
            </Group>
         </Grid.Col>
         {isCreate &&
            <Grid.Col span={{base: 12, md: 6}}>
               <Checkbox
                  disabled={loading}
                  label='Enviar email de confirmación'
                  checked={checked}
                  onChange={(event) => {
                     setChecked(event.currentTarget.checked)
                     form.setFieldValue('sendMail', mapCheckbox[event.currentTarget.checked])
                  }}
               />
            </Grid.Col>
         }
         
         <Grid.Col span={12}>
            <Button
               type="submit"
               className="eventCalendarForm__form__button"
               disabled={loading}
               onClick={(e) => {
                  e.preventDefault()
                  handleSubmit(form.values)
               }}
            >
               Guardar profesor/a
            </Button>
         </Grid.Col>
      </Grid>
   )
}
