import React, {useContext} from 'react';
import "./Users.css";
import {Button, Grid, MultiSelect, Select} from "@mantine/core";
import AppContext from "../../../../../contexts/AppContext";

function UsersForm(props) {
    const {form, setCreateUserModal} = props;
    const {staffUsers, teachersUsers} = useContext(AppContext)
    const usersOptions = [...staffUsers, ...teachersUsers]

    return (
        <Grid style={{width: "100%"}}>
            <Grid.Col span={12} justify="center" align="center">
                <Button
                    variant="outline"
                    color="secondaryColor"
                    onClick={() => {
                        setCreateUserModal(true)
                    }}
                >
                    Crear profesor
                </Button>
            </Grid.Col>
            <Grid.Col span={6}>
                <Select
                    label="Coordinador"
                    {...form.getInputProps('coordinator')}
                    data={staffUsers}
                    searchable
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <MultiSelect
                    label="Directores"
                    {...form.getInputProps('director')}
                    data={usersOptions}
                    searchable
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <MultiSelect
                    label="Co-directores"
                    {...form.getInputProps('codirector')}
                    data={usersOptions}
                    searchable
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <MultiSelect
                    label="Profesores"
                    {...form.getInputProps('teachers')}
                    data={usersOptions}
                    searchable
                />
            </Grid.Col>
        </Grid>
    )
}

export default UsersForm;