import { Button, Checkbox, Flex, Grid, Group, Loader, Modal, Select, Stack, TextInput } from "@mantine/core";
import { useContext, useState } from "react";
import showNotification from "../../utils/showNotification";
import axios from "axios";
import AuthContext from "../../contexts/AuthContext";
import { useIsMount } from "../../hooks/useIsMount";
import { IconExclamationCircle, IconFile, IconLink, IconNote } from "@tabler/icons-react";
import AppContext from "../../contexts/AppContext/AppContext";

// is prepared to recieve by default lessonId, if isGeneral prop is true, the id passed will be taken like a academicUnit id. 

export default function MaterialsModal({
   opened,
   onClose,
   idToAdd,
   isGeneral = false,
   onCreate,
}) {
   const isMount = useIsMount()
   const { authToken } = useContext(AuthContext)
   const { handleModified } = useContext(AppContext)
   const [isLoading, setIsLoading] = useState(false)
   const [values, setValues] = useState({
      name: '',
      type: '',
      link: '',
      sendNotification: false
   })

   const handleSubmit = async () => {

      const axiosConfig = {
         headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`,
         },
      }

      try {
         if (!isValid) {
            throw Error('Hay errores en los campos completados')
         }

         setIsLoading(true)
         const putURL = `${process.env.REACT_APP_BACKEND_SERVER}admin/moderation/insertMaterials`

         const idToUse = Array.isArray(idToAdd) ? idToAdd : [idToAdd]

         let data = {
            academicUnits: [],
            lessons: idToUse,
            name: values.name,
            type: values.type,
            link: values.link,
            sendNotification: values.sendNotification
         }

         if (isGeneral) {
            data.lessons = []
            data.academicUnits = idToUse
         }

         const { data: newMaterialId} = await axios.put(
            putURL,
            { data },
            axiosConfig
         )

         showNotification({
            color: "green",
            status: "success",
            title: "¡Excelente! Operación exitosa.",
            message: `El material ha sido creado con éxito.`,
         });
         
         if (onCreate) {
            onCreate({
               isNew: true,
               _id: newMaterialId,
               name: data.name,
               type: data.type,
               link: data.link
            })
         }
         handleModified()
         onClose(false)
         
      } catch (error) {
         console.error(error)
         showNotification({
            color: "red",
            status: "error",
            title: "Operación fallida.",
            message: `No se pudo crear el material. Detalle: ${error}`,
            autoClose: 12000
         });
      } finally {
         setIsLoading(false)
      }
   }

   const errors = {
      name:
         ((values.name === '' && 'El nombre del material no puede estar vacío.') ||
            (values.name.length <= 5 && 'El nombre del material debe ser mayor a 5 caracteres.') ||
            (values.name.length >= 255 && 'El nombre del material debe ser menor a 255 caracteres.')),
      type:
         ((values.type === '' || !values.type || !['PDF', 'ZIP', 'DOC'].includes(values.type)) && 'Se debe seleccionar un tipo de archivo válido.'),
      link:
         (values.link === '' && 'El link no puede estar vacío.') ||
         (!values.link.startsWith('https') && 'El link debe comenzar con "https"') ||
         (values.link.length <= 5 && 'El link del material debe ser mayor a 5 caracteres.') ||
         (values.link.length >= 255 && 'El link del material debe ser menor a 255 caracteres.')
   }

   const isValid = Boolean(!errors.name && !errors.type && !errors.link)

   const warningModalText = isGeneral ? 'unidad académica' : 'clase'

   return (
      <Modal
         onClose={() => onClose(false)}
         opened={opened}
      >
         <Grid
            style={{ width: "100%" }}
            justify="center"
            align="center"
         >
            <Grid.Col>
               <h3 style={{
                  fontSize: 'var(--fs-h4)',
                  color: '#2C4B58',
                  textAlign: 'center'
               }}
               >
                  Crear material
               </h3>
            </Grid.Col>
            <Grid.Col span={12}>
               < TextInput
                  placeholder="CLASE 1 - ÍNDICES - M.V JUAN RAYA"
                  leftSection={< IconNote />}
                  label='Nombre del material'
                  error={!isMount && errors?.name}
                  onChange={({ target }) => setValues({ ...values, name: target.value })}
               />
            </Grid.Col>

            <Grid.Col span={12}>
               < Select
                  leftSection={< IconFile />}
                  placeholder="Selecciona uno"
                  label='Tipo del material'
                  data={['PDF', "DOC", "ZIP"]}
                  onChange={(_value, option) => setValues({ ...values, type: option.value })}
                  allowDeselect={false}
                  error={!isMount && errors?.type}
               />
            </Grid.Col>

            <Grid.Col span={12}>
               < TextInput
                  leftSection={< IconLink />}
                  label='Link del material'
                  placeholder="https://www.dropbox.com/MANEJO-REPRODUCTIVO-DE-UN-CENTRO-DE-REPRODUCCION-CANINA-EN-ARGENTINA-Ruben-Somoza.pdf"
                  name="link"
                  onChange={({ target }) => setValues({ ...values, link: target.value })}
                  error={!isMount && errors?.link}
               />
            </Grid.Col>

            <Grid.Col span={12}>
               < Checkbox label='Notificar sobre este cambio'
                  defaultValue={false}
                  value={values.sendNotification}
                  onChange={({ target }) => setValues({ ...values, sendNotification: target.checked })}
               />
            </Grid.Col>

            <Grid.Col span={12}>
               <Stack align='center' gap={10}>

                  < IconExclamationCircle size={40} color="#2C4B58" />
                  <p
                     style={{
                        fontSize: 'var(--fs-small)',
                        color: '#2C4B58',
                        textAlign: 'center',
                        fontWeight: 'bold'
                     }}
                  >{`Al crear este material, se asignará a la ${warningModalText} actual.`}</p>
               </Stack>
            </Grid.Col>

            <Group align="center" py={20}>
               <Button
                  variant="light"
                  onClick={() => onClose(false)}>Cancelar</Button>
               <Button
                  miw={150}
                  disabled={!isValid}
                  onClick={() => handleSubmit()}
               >
                  {isLoading
                     ? < Loader size={20} color="#fff" />
                     : 'Crear material'}
               </Button>
            </Group>
         </Grid>
      </Modal>
   )
}
