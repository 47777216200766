import { Accordion, Badge, Flex, Group, Stack } from '@mantine/core';
import moment from 'moment';
import { mapInstallmentsEnums } from '../../../../utils/payments-enums';

export default function AdministrativeInstallmentsAccordion({ data, currency }) {
   return (
      <Stack>
         <Accordion>
            {data?.map(item => (
               <Accordion.Item key={item._id} value={item._id}>
                  <Accordion.Control>
                     <Group w={'100%'} justify='space-between'>
                        <p className="cell-modal--installmentsAccordionTitle">{`Cuota ${item.cycle} de ${item.total_cycles}`}</p>
                        <Badge color={item.state === 'PAID' ? 'green' : 'yellow'} >{`${mapInstallmentsEnums[item.state]}`}</Badge>
                     </Group>
                  </Accordion.Control>
                  <Accordion.Panel>
                     <Stack>
                        <Flex gap='sm'>
                           <p className="cell-modal--installmentsAccordionTitle">Numero de cuota:</p>
                           <span className="cell-modal--installmentsAccordionTitle" style={{ fontWeight: 'bold' }}>{item.cycle}</span>
                        </Flex>
                        <Flex gap='sm'>
                           <p className="cell-modal--installmentsAccordionTitle">Total de cuotas:</p>
                           <span className="cell-modal--installmentsAccordionTitle" style={{ fontWeight: 'bold' }}>{item.total_cycles}</span>
                        </Flex>
                        <Flex gap='sm'>
                           <p className="cell-modal--installmentsAccordionTitle">Monto:</p>
                           <span className="cell-modal--installmentsAccordionTitle" style={{ fontWeight: 'bold' }}>${item.amount}</span>
                        </Flex>
                        <Flex gap='sm'>
                           <p className="cell-modal--installmentsAccordionTitle">Monto acordado:</p>
                           <span className="cell-modal--installmentsAccordionTitle" style={{ fontWeight: 'bold' }}>${item.agreedAmount}</span>
                        </Flex>
                        <Flex gap='sm'>
                           <p className="cell-modal--installmentsAccordionTitle">Moneda:</p>
                           <span className="cell-modal--installmentsAccordionTitle" style={{ fontWeight: 'bold' }}>{currency}</span>
                        </Flex>
                        <Flex gap='sm'>
                           <p className="cell-modal--installmentsAccordionTitle">Fecha:</p>
                           <span className="cell-modal--installmentsAccordionTitle" style={{ fontWeight: 'bold' }}>{moment(item.date).format('DD-MM-YYYY')}</span>
                        </Flex>
                     </Stack>
                  </Accordion.Panel>
               </Accordion.Item>
            ))}
         </Accordion>
      </Stack>
   )
}
