import { Flex, Skeleton } from '@mantine/core'
import React from 'react'

export default function AdDashboardCoursesSkeleton() {
   return (
      <section className='AdministrativeDashboard-section-container'>
         < Skeleton w='175px' h='30px' />
         <Flex w='100%' direction='column' gap='30px'>
            <div className='AdministrativeDashboard-gridContainer'>
               < Skeleton w='100%' h='300px' />
               < Skeleton w='100%' h='300px' />
               < Skeleton w='100%' h='300px' />
            </div>

            <Flex direction={{ base: 'column', sm: 'row' }} w='100%' gap='lg' justify='space-around'>

               < Skeleton h='175px' w='175px' circle />
               < Skeleton h='175px' w='175px' circle />

            </Flex>
         </Flex>
      </section>
   )
}
