import './ViewCard.css'
import { Group, Stack } from '@mantine/core'
import { Link } from 'react-router-dom'

export default function ViewCard({ item }) {
   const { title, text, icon, href } = item
   return (
      <Link to={href} className='ViewCard-container' >

         <Stack >
            <Group align='center' justify='start'>
               {icon && icon}
               {title &&
                  <h3 className='ViewCard--title' >
                     {title}
                  </h3>
               }
            </Group>

            {text &&
               <p className='ViewCard--text'>
                  {text}
               </p>
            }
         </Stack>
      </Link>
   )
}
