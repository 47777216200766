import React from "react";
import App from "./App.js";
import "./index.css";
import {MantineProvider} from "@mantine/core";
import {theme} from "./themes/mantine.js";
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import '@mantine/charts/styles.css';
import {Notifications} from '@mantine/notifications';
import '@mantine/notifications/styles.css';
import {createRoot} from 'react-dom/client';


const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <MantineProvider theme={theme} defaultColorScheme="light">
        <Notifications/>
        <App/>
    </MantineProvider>
);



