import React, {useState} from 'react';
import "./MantineSwitchButton.css";
import { Switch } from '@mantine/core';

function MantineSwitchButton(props) {
    const {checked, onChange, disabled} = props;
    const [stateChecked, setStateChecked] = useState(checked);

    if (checked === null) return null;
    return (
        <Switch
            checked={stateChecked}
            onChange={(event) => {
                setStateChecked(event.currentTarget.checked);
                onChange(event.currentTarget.checked)
            }}
            disabled={disabled}
            {...props}
        />
    );
}

export default MantineSwitchButton;