import React, { useContext, useState } from 'react'
import ContentCreator from '../../../../components/ContentCreator/ContentCreator';
import { Button, Loader, Modal, TextInput } from '@mantine/core';
import { IconCopy } from '@tabler/icons-react';
import { handleCopy } from '../../../../utils/handleCopy';
import DiscountsForm from '../../DiscountsForm/DiscountsForm';
import showNotification from '../../../../utils/showNotification';
import { ERROR_HANDLER } from '../../../../utils/error-handler';
import AuthContext from '../../../../contexts/AuthContext';
import axios from 'axios';
import './SalesCraeteDiscountModal.css'

const validation = {
   institutional_name: (value) => {
      if (!value) return 'El campo de la institucion no puede estar vacio.'
   },
   percentage: (value) => {
      if (!value) return 'Ingresa un numero que indique el descuento.'
      if (value > 100) return 'El descuento no puede ser mayor al 100%.'
   },
   code: (value) => {
      if (!value) return 'El nombre del código no puede estar vacío.'
      if (value.length > 100) return 'El código no puede superar los 100 caracteres'
   },
}

export default function SalesCreateDiscountModal({ opened, onClose }) {
   const { authToken } = useContext(AuthContext)

   const [isLoading, setIsLoading] = useState(false)


   const axiosConfig = {
      headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${authToken}`
      }
   };

   const handleSubmit = async (form) => {
      try {
         setIsLoading(true);
         const jsonData = {
            data: { ...form.values }
         };

         const validateForm = form.validate()
         if (validateForm.hasErrors) {
            const firstKey = Object.keys(validateForm.errors)[0];
            const firstValue = validateForm.errors[firstKey];
            return showNotification({
               color: "red",
               status: "error",
               title: "Ha ocurrido un problema y no se ha podido crear el descuento.",
               message: `Detalle: ${firstValue}`,
               autoClose: 12000
            })
         }

         await axios.post(
            `${process.env.REACT_APP_BACKEND_SERVER}admin/ventas/createDiscount`,
            jsonData,
            axiosConfig
         );

         showNotification({
            color: "green",
            status: "success",
            title: "¡Excelente! Te recomendamos que copies el código.",
            message: `El descuento ${form.values.code} ha sido creado / editado con éxito.`,
         });

      } catch (err) {
         showNotification({
            color: "red",
            status: "error",
            title: "Operación fallida.",
            message: `No se pudo crear / editar el descuento. Detalle: ${err.message || ERROR_HANDLER[err.request.status] || ERROR_HANDLER.defaultError
               }`,
            autoClose: 12000,
         });
      } finally {
         setIsLoading(false);
      }
   };

   return (
      <Modal className='SalesCraeteDiscountModal-main-container' size='lg' opened={opened} onClose={() => onClose(false)}>
         <ContentCreator
            title="Crear descuento"
            submitFunc={() => null}
            validation={validation}
            onCancel={() => {
               onClose(false);
            }}
            initialValues={null}
            renderPreview={(_form) => (
               <div className="salesDiscounts__preview">
                  <TextInput
                     {..._form.form.getInputProps('code')}
                     style={{ width: "50%" }}
                     label="Código de descuento"
                     disabled
                  />
                  <IconCopy className="salesDiscounts__preview__icon"
                     style={
                        _form.form.values.code
                           ? {}
                           : { pointerEvents: "none" }
                     }
                     onClick={() => handleCopy(_form.form.values.code)} />
               </div>
            )}
            renderForm={(_form) => (
               <>
                  <DiscountsForm
                     initialValuesEdit={{
                        max_uses: 1,
                        advancedSwitchAllow: true,
                     }}
                     form={_form.form}
                     disabledInputs={{
                        submitButton: true,
                        advancedSwitch: true,
                        usesQuantityInput: true,
                        activeSwitch: true,
                     }}
                     loading={isLoading}
                  />
                  <Button mt={20} onClick={(e) => {
                     e.preventDefault()
                     handleSubmit(_form.form)
                  }}>
                     {isLoading ? <Loader size={20} color="#fff" /> : "Crear descuento"}
                  </Button>
               </>
            )}
         />

      </Modal>
   )
}
