import React from 'react';

const AuthContext = React.createContext({
    idAuth: null,
    authToken: null,
    isAuth: false,
    isReady: false

});

export default AuthContext;