import './OpportunitiesSlide.css'

export default function OpportunitiesSlide({ title, description, redirectUrl, bannerImageUrl, logoUrl, handleClickCard, isActive }) {

   const styles = {
      backgroundImage: `url("${bannerImageUrl}")`,
   }
   
   return (
      <article
         onClick={() => handleClickCard({title, description, redirectUrl, bannerImageUrl, logoUrl})}
         style={styles}
         className={`OpportunitiesSlide--container ${isActive ? "OpportunitiesSlide-container--active" : ""}`}
         >
            <div className='OpportunitiesSlide--overlay--container'>
            <div className='OpportunitiesSlide--overlay'>
               {title && <h4 className='OpportunitiesSlide--title'>{title}</h4>}

               {description && <p className='OpportunitiesSlide--description'>{description}</p>}
            </div>
            </div>
      </article>
   )
}
