import './ModerationDashboard.css'
import { Grid } from '@mantine/core'
import useModDashboard from '../../../hooks/Moderation/useModDashboard/useModDashboard'
import ModDashbardSkeleton from './ModDashbardSkeleton'
import ModDashboardChart from './ModDashboardChart/ModDashboardChart'
import ModDashboardTeachersTop from './ModDashboardTeachersTop/ModDashboardTeachersTop'
import ModDashboardModerators from './ModDashboardModerators/ModDashboardModerators'

export default function ModerationDashboard() {
   const { coursesInfo, mostRatedModerators, isLoading, tenMostRatedTeachers } = useModDashboard()

   return (
      <section className='ModerationDashboard-main-container'>
         <Grid>
            {isLoading
               ? <ModDashbardSkeleton />
               : <>
                  {coursesInfo && <ModDashboardChart coursesInfo={coursesInfo} />}

                  {tenMostRatedTeachers && <ModDashboardTeachersTop tenMostRatedTeachers={tenMostRatedTeachers} />}

                  {mostRatedModerators && <ModDashboardModerators mostRatedModerators={mostRatedModerators} />}
               </>
            }
         </Grid>

      </section>
   )
}
