import './Marketing.css'
import {
    withMarketingContextProvider,
    withMarketingContextReady
} from '../../contexts/MarketingContext/withMarketingContext'
import {Route, Routes} from 'react-router-dom'
import ViewLayout from '../../layouts/ViewLayout/ViewLayout'
import MktgHome from '../../features/Marketing/MktgHome/MktgHome'
import MktgNewsView from '../../features/Marketing/MktgNews/MktgNewsView/MktgNewsView'
import MktgTestimonies from '../../features/Marketing/MktgTestimonies/MktgTestimonies'
import MktgAdvicesView from '../../features/Marketing/MktgAdvices/MktgAdvicesView/MktgAdvicesView'
import MktgOpportunities from '../../features/Marketing/MktgOpportunities/MktgOpportunities'
import MainLayout from '../../layouts/MainLayout/MainLayout'
import MktgCalendarContainer from "../../features/Marketing/MktgCalendar/MktgCalendarContainer/MktgCalendarContainer";
import MktgNotificationsContainer from '../../features/Marketing/MarketingNotifications/MktgNotificationsContainer/MktgNotificationsContainer'
import MarketingFaqsContainer from '../../features/Marketing/MarketingFaqs/MarketingFaqsContainer/MarketingFaqsContainer'

const Marketing = () => {

    return (
        <MainLayout>
            <Routes>
                < Route
                    path='/'
                    element={
                        <ViewLayout
                            title='Marketing'
                            description='Aqui podras generar contenido específico para la sección relacionadas al Marketing del sitio web y del campus.'
                            backBtnText='Ir a Marketing'
                        >
                            < MktgHome/>
                        </ViewLayout>
                    }
                />

                < Route
                    path='/novedades'
                    element={
                        <ViewLayout
                            withBackButton
                            title='Novedades'
                            description='Generar nuevas entradas para el campus y web ventas.'
                            backBtnText='Ir a Marketing'
                        >
                            < MktgNewsView/>
                        </ViewLayout>
                    }
                />

                < Route
                    path='/testimonios'
                    element={
                        <ViewLayout
                            title='Testimonios'
                            description='Recepción y lectura de testimonios.'
                            backBtnText='Ir a Marketing'
                            withBackButton
                        >
                            < MktgTestimonies/>
                        </ViewLayout>
                    }
                />

                < Route
                    path='/calendario'
                    element={
                        <ViewLayout
                            title='Calendario'
                            description='Generar eventos en calendario.'
                            backBtnText='Ir a Marketing'
                            withBackButton
                        >
                            < MktgCalendarContainer/>
                        </ViewLayout>
                    }
                />

                < Route
                    path='/consejos'
                    element={
                        <ViewLayout
                            title='Consejos'
                            description='Crear nuevos mensajes y recomendados.'
                            backBtnText='Ir a Marketing'
                            withBackButton
                        >
                            < MktgAdvicesView/>
                        </ViewLayout>
                    }
                />
                < Route
                    path='/consejos'
                    element={
                        <ViewLayout
                            title='Consejos'
                            description='Crear nuevos mensajes y recomendados.'
                            backBtnText='Ir a Marketing'
                            withBackButton
                        >
                            < MktgAdvicesView/>
                        </ViewLayout>
                    }
                />

                < Route
                    path='/oportunidades'
                    element={
                        <ViewLayout
                            title='Oportunidades'
                            description='Incorporar o editar oportunidades disponibles.'
                            backBtnText='Ir a Marketing'
                            withBackButton
                        >
                            < MktgOpportunities/>
                        </ViewLayout>
                    }
                />

                < Route
                    path='/notificaciones'
                    element={
                        <ViewLayout
                            title='Notificaciones'
                            description='Incorporar o editar notificaciones del campus.'
                            backBtnText='Ir a Marketing'
                            withBackButton
                        >
                            < MktgNotificationsContainer/>
                        </ViewLayout>
                    }
                />

                < Route
                    path='/preguntas-frecuentes'
                    element={
                        <ViewLayout
                            title='Preguntas Frecuentes'
                            description='Incorporar o editar preguntas disponibles.'
                            backBtnText='Ir a Marketing'
                            withBackButton
                        >
                            < MarketingFaqsContainer />
                        </ViewLayout>
                    }
                />

            </Routes>
        </MainLayout>
    )
}

export default withMarketingContextProvider(withMarketingContextReady(Marketing));