import {Table, Tooltip} from "@mantine/core";
import {currencyFormat} from "../../../utils/currencyFormat";
import {IconInfoCircleFilled} from "@tabler/icons-react";

export function SalesInstallmentsPreview({rowData}) {
    const {
        payer_name,
        payer_lastname,
        installment_cycle,
        installments_total_cycles,
        agreedAmount,
        currency
    } = rowData;

    return (
        <Table.ScrollContainer minWidth={800}>
            <Table verticalSpacing="xs">
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>Cliente</Table.Th>
                        <Table.Th>Cuota actual</Table.Th>
                        <Table.Th>Cuotas</Table.Th>
                        <Table.Th>
                            Importe
                            <Tooltip label="Este importe corresponde al monto acordado para abonar por el cliente al momento de confirmar la compra en la plataforma, luego del descuento otorgado por ventas"
                                     multiline
                                     w={304}
                                     color="rgba(54,54,54,1)">
                                <IconInfoCircleFilled size={16} className='TableContent-thead--icon'/>
                            </Tooltip>
                        </Table.Th>
                        <Table.Th>Moneda</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    <Table.Tr>
                        <Table.Td>{`${payer_name} ${payer_lastname}`}</Table.Td>
                        <Table.Td>{`${installment_cycle}°`}</Table.Td>
                        <Table.Td>{`${installments_total_cycles} cuotas`}</Table.Td>
                        <Table.Td>{`$${currencyFormat(agreedAmount)}`}</Table.Td>
                        <Table.Td>{currency}</Table.Td>
                    </Table.Tr>
                </Table.Tbody>
            </Table>
        </Table.ScrollContainer>
    );
}
