import React, {useContext, useState} from 'react';
import "./NewQuestionnaireModal.css";
import moment from "moment";
import QuestionnaireForm from "../../../AcademicExams/QuestionnaireForm/QuestionnaireForm";
import showNotification from "../../../../../utils/showNotification";
import {ERROR_HANDLER} from "../../../../../utils/error-handler";
import axios from "axios";
import AuthContext from "../../../../../contexts/AuthContext";
import AcademicContext from "../../../../../contexts/AcademicContext";

function NewQuestionnaireModal(props) {
    const {setOpen, formHandler} = props;
    const { authToken } = useContext(AuthContext);
    const { handleModified } = useContext(AcademicContext);
    const [loading, setLoading] = useState(false);
    const academicUnitName = formHandler.values.name;

    const postQuestionnaire = async (values) => {
        const postURL = `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/createQuestionary`
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`
            }
        }
        const jsonData = {
            name: `CUESTIONARIO - ${academicUnitName} - ${moment().format('MMMM - YYYY')}`.toUpperCase(),
            questions: values
        }
        return axios.post(postURL, jsonData, config)
    }

    const handleSubmitQuestionnaire = async (values) => {
        try {
            setLoading(true)

            // if (initialValuesQuestionnaireEdit)
            //     await putQuestionnaire(values, axiosConfig)
            // else
            await postQuestionnaire(values)

            showNotification({
                color: "green",
                status: "success",
                title: "Cuestionario creado con éxito.",
                message: `Detalle: el cuestionario se ha guardado correctamente.`,
            });
            handleModified()
            setOpen(false)
        } catch (err) {
            showNotification({
                color: "red",
                status: "error",
                title: "Error al cargar los crear el cuestionario.",
                message: `Detalle: ${ERROR_HANDLER[err.request.status] || ERROR_HANDLER.defaultError}`,
                autoClose: 12000
            });
        } finally {
            setLoading(false)
        }
    }


    return (
        <QuestionnaireForm
            onBackClick={() => {
                setOpen(false)
            }}
            academicUnitName={academicUnitName}
            handleSubmit={handleSubmitQuestionnaire}
            isLoading={loading}
            initialValues={{
                questions: [
                    {
                        title: `PREGUNTA 1 - ${academicUnitName.toUpperCase()} - ${moment().format('MMMM - YYYY')}`,
                        questionText: '',
                        responseOptions: [{label: '', value: 1}],
                        trueOption: null
                    }
                ]
            }}
        />
    );
}

export default NewQuestionnaireModal;