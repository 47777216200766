import { useState } from 'react';
import { UnstyledButton, Text, Paper, Group, rem } from '@mantine/core';
import {
  IconSwimming,
  IconBike,
  IconPaywall,
  IconChevronDown,
  IconChevronUp,
  IconCash,
} from '@tabler/icons-react';
import './SalesDashboardProfitCommissions.css';

const data = [
  { icon: IconPaywall, label: 'Tus Comisiones' },
];

export function SalesDashboardProfitCommissions({ item }) {
  const [monthIndex, setMonthIndex] = useState(0);

  const handleMonthChange = (increment) => {
    setMonthIndex((prevIndex) => {
      let newIndex = prevIndex + increment;
      if (newIndex < 0) {
        newIndex = 11; 
      } else if (newIndex > 11) {
        newIndex = 0;
      }
      return newIndex;
    });
  };

  const monthNames = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];

  const currentMonth = monthNames[monthIndex];
  const currentMonthValue = item[monthIndex] || 0;

  return (
    <div className="salesDashboardProfitCommissions-root">
      <div className="salesDashboardProfitCommissions-controls">
        <UnstyledButton
          className="salesDashboardProfitCommissions-control"
          onClick={() => handleMonthChange(-1)}
        >
          <IconChevronUp
            style={{ width: rem(16), height: rem(16) }}
            className="salesDashboardProfitCommissions-control-icon"
            stroke={1.5}
          />
        </UnstyledButton>

        <div className="salesDashboardProfitCommissions-date">
          <p className="salesDashboardProfitCommissions-month">{currentMonth}</p>
        </div>

        <UnstyledButton
          className="salesDashboardProfitCommissions-control"
          onClick={() => handleMonthChange(1)}
        >
          <IconChevronDown
            style={{ width: rem(16), height: rem(16) }}
            className="salesDashboardProfitCommissions-control-icon"
            stroke={1.5}
          />
        </UnstyledButton>
      </div>
      <Group style={{ flex: 1 }}>
        <Paper bg="var(--color-base-dark)" withBorder p="md" radius="md">
          <Group justify="space-between">
            <p className= "salesDashboardProfitCommissions-iconStyle" size="xs" c="dimmed">
              {data[0].label}
            </p>
            <IconPaywall size="1.4rem" stroke={1.5} />
          </Group>
          <Group align="flex-end" gap="xs" mt={25}>
            <p className='salesDashboardProfitCommissions-value-style'> <IconCash className='salesDashboardProfitCommissions-value-iconCashStyle'/> ${currentMonthValue}</p>
          </Group>
        </Paper>
      </Group>
    </div>
  );
}
