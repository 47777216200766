import React, { useContext, useEffect, useState } from "react";
import "./SalesPaymentsForm.css";
import {
   Button,
   FileInput,
   Grid,
   Pill,
   Select,
   TextInput,
   Textarea,
   NumberInput,
   Switch,
   Flex,
   Modal,
} from "@mantine/core";
import AppContext from "../../../../contexts/AppContext";
import { IconCopy, IconExternalLink, IconPhoto, IconPlus } from "@tabler/icons-react";
import { openUrl } from "../../../../utils/openUrl";
import MantineDateInput from "../../../../components/MantineDateInput/MantineDateInput";
import moment from "moment";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";
import {
   BANK_ACCOUNTS_OPTIONS,
   mapInscriptionOriginEnums,
} from "../../../../utils/payments-enums";
import NotificationBanner from "../../../../components/NotificationBanner/NotificationBanner";
import { renderFile } from "../../../../utils/renderFile";
import showNotification from "../../../../utils/showNotification";
import { isBase64 } from "../../../../utils/isBase64";
import { openBase64InNewTab } from "../../../../utils/openBase64InNewTab";
import SalesCreateDiscountModal from "./SalesCreateDiscountModal";

const DISPLAY_TYPE = [
   { value: "SINGLE_PAYMENT", label: "Pago único" },
   { value: "SUBSCRIPTION", label: "Pago en cuotas" },
];

const CUSTOMER_TYPE = [
   { value: "true", label: "Nuevo" },
   { value: "false", label: "Preexistente" },
];

const INSCRIPTION_ORIGIN_DATA = [
   { value: "GENERAL", label: mapInscriptionOriginEnums.GENERAL },
   {
      value: "SCHOOL_SCHOLARSHIP",
      label: mapInscriptionOriginEnums.SCHOOL_SCHOLARSHIP,
   },
   {
      value: "UNIVERSITY_SCHOLARSHIP",
      label: mapInscriptionOriginEnums.UNIVERSITY_SCHOLARSHIP,
   },
   {
      value: "FORVET_SCHOLARSHIP",
      label: mapInscriptionOriginEnums.FORVET_SCHOLARSHIP,
   },
];

/**
 * Check if the two arrays have changed, used for the installment array
 * @param arr1
 * @param arr2
 * @returns {boolean}
 */
function hasInstallmentsDataChanged(arr1, arr2) {
   if (arr1.length !== arr2.length) return true;

   function compareDates(date1, date2) {
      return new Date(date1).getTime() !== new Date(date2).getTime();
   }

   for (let i = 0; i < arr1.length; i++) {
      const obj1 = arr1[i];
      const obj2 = arr2[i];

      if (obj1.amount !== obj2.amount) return true;

      if (compareDates(obj1.due_date, obj2.due_date)) return true;
   }
   return false;
}

const generateInstallmentItem = (form, index) => {
   return (
      <Grid.Col span={12}>
         <div className="SalesPaymentForm-installments-grid-container">
            <Grid
               className="SalesPaymentForm-installments-grid"
               justify="space-between"
               align="center"
            >
               <Grid.Col span={6}>
                  <NumberInput
                     withAsterisk
                     label={`Monto de cuota ${index + 1}`}
                     min={0}
                     step={0.1}
                     {...form.getInputProps(`installments.data.${index}.amount`)}
                  />
               </Grid.Col>
               <Grid.Col span={6}>
                  <MantineDateInput
                     withAsterisk
                     label={`Fecha de cobro de cuota ${index + 1}`}
                     form={form}
                     name={`installments.data.${index}.due_date`}
                  // type="date"
                  // {...form.getInputProps(`installments.data.${index}.due_date`)}
                  />
               </Grid.Col>
            </Grid>
         </div>
      </Grid.Col>
   );
};

function SalesPaymentForm(props) {
   const { form, loading } = props;
   const { staffUsers } = useContext(AppContext);
   const { authToken } = useContext(AuthContext);
   const axiosConfig = {
      headers: {
         "Content-Type": "application/json",
         Authorization: `Bearer ${authToken}`,
      },
   };
   const [
      displayAdvancedInstallmentsConfig,
      setDisplayAdvancedInstallmentsConfig,
   ] = useState(form.values.hasAdvancedConfigEnabled);
   const [installmentsDataState, setInstallmentsDataState] = useState(
      form.values.installments?.data
   );

   const getDiscountCode = async () => {
      const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/ventas/getDiscountCode/${form.values.discount}`;
      try {
         const discountCode = await axios.get(url, axiosConfig);
         form.setFieldValue("discount", discountCode.data.code);
      } catch (error) {
         console.error("Error al obtener el código de descuento", error);
      }
   };

   const checkDiscountCode = async (value) => {
      const response = await axios.post(
         `${process.env.REACT_APP_BACKEND_SERVER}info/checkDiscountCode`,
         { code: value }
      );
      const discountInfoData = response.data.data;
      const calculateNewAmount =
         (form.values.agreedAmount - (form.values.agreedAmount * (discountInfoData.percentage / 100)));
      form.setFieldValue("agreedAmount", calculateNewAmount);
   };

   const handleGetReceipt = async () => {
      const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/ventas/comprobantes/${form.values.trackingCode}`;
      try {
         const receiptResponse = await axios.get(url, axiosConfig);
         form.setFieldValue("receipt", receiptResponse.data.receipt);
      } catch (error) {
         console.error("Error al obtener el comprobante", error);
      }
   };

   useEffect(() => {
      //If discount exist, fetch discount code
      if (form.values.discount) getDiscountCode();
      //If trackingCode exist, fetch receipt
      if (form.values.trackingCode) handleGetReceipt();
   }, []);

   useEffect(() => {
      // Set default fixedAmount of installments
      let fixedAmountPerCycle =
         form.values.agreedAmount / form.values.installments.total_cycles;

      // If currency is not USD, make it
      if (form.values.currency !== "USD" && !form.values.keepInstallmentsOnARS)
         fixedAmountPerCycle = fixedAmountPerCycle / form.values.dollar_day;
      // Here I will use the dollar value of the day the payment was generated to convert the amount to USD

      form.setFieldValue(
         "installments.fixedAmount",
         parseFloat(fixedAmountPerCycle.toFixed(2))
      );
   }, [
      form.values.installments.total_cycles,
      form.values.keepInstallmentsOnARS,
   ]);

   useEffect(() => {
      // Check if the installments data has changed, used to indicate the backend to update the installments
      if (installmentsDataState && form.values.installments.data) {
         if (
            hasInstallmentsDataChanged(
               installmentsDataState,
               form.values.installments.data
            )
         ) {
            setInstallmentsDataState(form.values.installments.data);
            form.setFieldValue("installments.hasChanged", true);
         }
      }
   }, [form.values.installments.data]);

   const setInstallmentsData = (
      _keepInstallmentsOnARSRaw = form.values.keepInstallmentsOnARS
   ) => {
      // If advanced installments config is displayed, generate installments data based on cycles and first due date
      const installmentsData = Array.from(
         { length: form.values.installments.total_cycles },
         (_, index) => {
            let cycleAmount =
               form.values.agreedAmount / form.values.installments.total_cycles;
            if (form.values.currency !== "USD" && !_keepInstallmentsOnARSRaw)
               cycleAmount = cycleAmount / form.values.dollar_day;

            return {
               amount: parseFloat(cycleAmount.toFixed(2)),
               due_date: moment(form.values.installments.first_due_date)
                  .add(index, "months")
                  .toDate(),
            };
         }
      );
      form.setFieldValue("installments.data", installmentsData);
   };

   const handleAdvancedInstallmentsConfig = () => {
      setDisplayAdvancedInstallmentsConfig(!displayAdvancedInstallmentsConfig);
      if (!displayAdvancedInstallmentsConfig) {
         setInstallmentsData();
      }
   };

   const [isCreateDiscountModal, setIsCreateDiscountModal] = useState(false)

   return (
      <Grid style={{ width: "100%" }} justify="center" align="center">
         <Grid.Col span={6}>
            <Select
               {...form.getInputProps("sellerId")}
               withAsterisk
               label="Vendedor a cargo"
               searchable
               data={staffUsers}
            />
         </Grid.Col>
         { }
         <Grid.Col span={6}>
            <Select
               {...form.getInputProps("isNewCustomer")}
               withAsterisk
               label="Tipo de usuario"
               data={CUSTOMER_TYPE}
            />
         </Grid.Col>
         <Grid.Col span={6}>
            <Select
               label="Cuenta de recepción"
               withAsterisk
               data={BANK_ACCOUNTS_OPTIONS}
               {...form.getInputProps("bankTransferAccount")}
               searchable
            />
         </Grid.Col>
         <Grid.Col span={6}>
            <Select
               label="Origen de registro"
               data={INSCRIPTION_ORIGIN_DATA}
               {...form.getInputProps("inscriptionOrigin")}
            />
         </Grid.Col>
         <Grid.Col span={6}>
            <TextInput
               label="Código de descuento asignado"
               withAsterisk
               placeholder="COVETSALT-AEEC-D7SN"
               {...form.getInputProps("discount")}
               w={"100%"}
            />
         </Grid.Col>
         <Grid.Col span={6}>
            <Flex align='center' justify='flex-start' gap='sm'>
               <Button
                  fw="normal"
                  onClick={() => {
                     checkDiscountCode(form.values.discount);
                  }}
                  style={{ marginTop: "24px" }}
               >
                  Validar descuento
               </Button>

               <Button
                  variant="outline"
                  fw="normal"
                  onClick={() => {
                     setIsCreateDiscountModal(true)
                     // checkDiscountCode(form.values.discount);
                  }}
                  style={{ marginTop: "24px" }}
               >
                  <IconPlus />
                  Crear descuento
               </Button>
               {isCreateDiscountModal &&
                 <SalesCreateDiscountModal onClose={setIsCreateDiscountModal} opened={isCreateDiscountModal} academicUnit={form.values.details} />
               }
            </Flex>
         </Grid.Col>
         <Grid.Col span={12}>
            <div className="salesPaymentForm-typeSelect">
               <Select
                  {...form.getInputProps("type")}
                  withAsterisk
                  label="Tipo de operación"
                  data={DISPLAY_TYPE}
               />
            </div>
         </Grid.Col>
         {form.values.type === "SUBSCRIPTION" && (
            <>
               <hr />
               {!form.values.keepInstallmentsOnARS && (
                  <NotificationBanner
                     type="information"
                     message={`El importe de las cuotas se define de acuerdo al valor del dólar del día de la operación. Referencia valor del dólar: $${form.values.dollar_day}`}
                  />
               )}
               <Grid className="SalesPaymentForm-installments-grid-wrapper">
                  <Grid.Col span={6}>
                     <NumberInput
                        {...form.getInputProps("installments.total_cycles")}
                        withAsterisk
                        label="Cantidad de cuotas"
                        min={1}
                        max={24}
                     />
                  </Grid.Col>
                  <Grid.Col span={6} />
                  <Grid.Col span={6}>
                     <NumberInput
                        label={`Importe fijo de cuotas`}
                        min={0}
                        {...form.getInputProps(`installments.fixedAmount`)}
                        disabled
                        description="El importe de cada cuota se calcula según el monto acordado y la cantidad de cuotas."
                     />
                  </Grid.Col>
                  <Grid.Col span={6}>
                     <MantineDateInput
                        label={`Fecha de cobro de primera cuota`}
                        form={form}
                        name={`installments.first_due_date`}
                        description="La fecha de cobro de las siguientes cuotas se calculará automáticamente, una vez por mes."
                        disabled={displayAdvancedInstallmentsConfig}
                     />
                  </Grid.Col>
                  {!displayAdvancedInstallmentsConfig && (
                     <Grid.Col span={12}>
                        <div className="salesPaymentForm-button-container">
                           <Button
                              variant={"outline"}
                              color="secondaryColor"
                              style={{ alignSelf: "flex-end" }}
                              onClick={() => handleAdvancedInstallmentsConfig()}
                           >
                              Configuración avanzada de cuotas
                           </Button>
                        </div>
                     </Grid.Col>
                  )}

                  {displayAdvancedInstallmentsConfig && (
                     <>
                        <div className="salesPaymentForm-switch-grid">
                           <Switch
                              checked={form.values.keepInstallmentsOnARS}
                              label="Mantener cuotas en pesos argentinos"
                              w={"100%"}
                              {...form.getInputProps("keepInstallmentsOnARS")}
                              onChange={(event) => {
                                 form.setFieldValue(
                                    "keepInstallmentsOnARS",
                                    event.currentTarget.checked
                                 );
                                 // Send the value directly form the event to avoid the state update delay
                                 setInstallmentsData(event.currentTarget.checked);
                              }}
                           />
                        </div>
                        {Array.from({
                           length: form.values.installments.total_cycles,
                        }).map((_, index) => generateInstallmentItem(form, index))}
                     </>
                  )}
                  {displayAdvancedInstallmentsConfig && (
                     <Grid.Col span={12}>
                        <div className="salesPaymentForm-button-container">
                           <span
                              style={{
                                 cursor: "pointer",
                                 textDecoration: "underline",
                                 fontSize: "var(--fs-tiny)",
                              }}
                              onClick={() => handleAdvancedInstallmentsConfig()}
                           >
                              Cancelar configuración avanzada
                           </span>
                        </div>
                     </Grid.Col>
                  )}
               </Grid>
               <hr />
            </>
         )}
         <Grid.Col span={12}>
            <Textarea
               label="Comentarios"
               {...form.getInputProps("commit")}
               w={"100%"}
               minRows={3}
            />
         </Grid.Col>
         <Grid.Col span={6}>
            <FileInput
               {...form.getInputProps("receipt")}
               label="Cargar nuevo comprobante de pago"
               description="El nuevo comprobante de pago reemplazará al anterior."
               withAsterisk
               w={"100%"}
               placeholder="Cargar imagen"
               leftSection={<IconPhoto size={30} />}
               disabled={loading}
               onChange={(file) => {
                  renderFile(file).then((_fileBase64) => {
                     form.setFieldValue("receipt", _fileBase64);
                  });
               }}
               accept={["image/*", "application/pdf"]}
            />
         </Grid.Col>
         {!form.values.receipt && <Grid.Col span={6} />}
         {form.values.receipt && (
            <Grid.Col span={6}>
               <div className="SalesPaymentForm-iamgeLink-div">
                  <h6 className="SalesPaymentForm-image-header">
                     Link del comprobante
                  </h6>
                  <div className="SalesPaymentForm-pill-container">
                     <Pill>{form.values.receipt}</Pill>
                     <IconExternalLink
                        className="materialsTable__icon"
                        onClick={() => {
                           if (
                              !form.values.receipt ||
                              form.values.receipt === "Comprobante pendiente"
                           ) {
                              showNotification({
                                 color: "yellow",
                                 status: "warning",
                                 title: "El comprobante no existe",
                                 message: `Detalle: No se ha cargado un comprobante de pago.`,
                                 autoClose: 12000,
                              });
                           } else if (isBase64(form.values.receipt)) {
                              openBase64InNewTab(form.values.receipt);
                           } else {
                              openUrl(form.values.receipt);
                           }
                        }}
                     />
                  </div>
               </div>
            </Grid.Col>
         )}
         <Grid.Col span={12}>
            <Button
               type="submit"
               className="eventCalendarForm__form__button"
               loading={loading}
            >
               Guardar pago
            </Button>
         </Grid.Col>
      </Grid>
   );
}

export default SalesPaymentForm;
