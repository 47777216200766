import React, {useContext} from "react";
import {Route, Routes} from "react-router-dom";
import Moderation from "../pages/Moderation/Moderation";
import Marketing from "../pages/Marketing/Marketing";
import Academic from "../pages/Academic/Academic";
import Sales from "../pages/Sales/Sales";
import Support from "../pages/Support/Support";
import Administrative from "../pages/Administrative/Administrative";
import {
    withAppContextProvider,
    withAppContextReady,
} from "../contexts/AppContext";
import AppContext from "../contexts/AppContext/AppContext";

function AdminRoutes() {
    const {currentUser} = useContext(AppContext);
    const roles = currentUser?.user.forVetRole || [];

    return (
        <Routes>
            {roles.includes("moderacion") && (
                <Route exact path="/moderacion/*" element={<Moderation/>}/>
            )}
            {roles.includes("marketing") && (
                <Route exact path="/marketing/*" element={<Marketing/>}/>
            )}
            {roles.includes("academico") && (
                <Route exact path="/academico/*" element={<Academic/>}/>
            )}
            {roles.includes("ventas") && (
                <Route exact path="/ventas/*" element={<Sales/>}/>
            )}
            {roles.includes("soporte") && (
                <Route exact path="/soporte" element={<Support/>}/>
            )}
            {roles.includes("administracion") && (
                <Route exact path="/administracion/*" element={<Administrative/>}/>
            )}
        </Routes>
    );
}

export default withAppContextProvider(withAppContextReady(AdminRoutes));