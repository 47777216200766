import React, { useState } from "react";
import { Burger } from "@mantine/core";
import { IconLogout } from "@tabler/icons-react";
import SidebarLinksGroup from "../../components/Sidebar/SidebarLinksGroup/SidebarLinksGroup";
import "./Sidebar.css";
import SidebarLogo from "../../components/Sidebar/SidebarLogo/SidebarLogo";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import SidebarFooter from "../../components/Sidebar/SidebarFooter/SidebarFooter";
import { useAuth0 } from "@auth0/auth0-react";
import sidebarURLs from "../../utils/sidebarURLs";

function Sidebar() {
  const mockdata = sidebarURLs();
  const { width } = useWindowDimensions();
  const isTablet = width <= 1000;
  const [menuOpen, setMenuOpen] = useState(false);
  const { logout } = useAuth0();
  const logoutUrl = "https://admin.forvet.org/";
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <aside className="sidebar-aside">
      <nav
        style={isTablet ? { gap: menuOpen ? "32px" : "0" } : {}}
        className="sidebar-nav"
      >
        <div className="sidebar-header">
          <div className="sidebar-logo">
            <SidebarLogo />
          </div>
          {isTablet && (
            <div className="sidebar-burger">
              <Burger opened={menuOpen} onClick={toggleMenu} />
            </div>
          )}
        </div>

        <div
          style={isTablet ? { display: !menuOpen && "none" } : {}}
          className="sidebar-innerLinks"
        >
          <SidebarLinksGroup data={mockdata} key={mockdata.label} />
        </div>

        <footer className="sidebar-footer">
          <SidebarFooter
            style={isTablet && { display: !menuOpen && "none" }}
            icon={<IconLogout className="sidebar-navLink--icon" />}
            isButton
            text="Cerrar sesión"
            onClick={() => logout({ returnTo: logoutUrl })}
          />
        </footer>
      </nav>
    </aside>
  );
}

export default Sidebar;
