import React, {useContext, useState} from 'react';
import "./NewUserModal.css"
import showNotification from "../../../../../utils/showNotification";
import {useForm} from "@mantine/form";
import axios from "axios";
import AuthContext from "../../../../../contexts/AuthContext";
import AppContext from "../../../../../contexts/AppContext";
import AcaUsersForm from "../../../AcademicUsers/AcaUsersForm/AcaUsersForm";
import useS3 from "../../../../../hooks/useS3";

function NewUserModal(props) {
    const {setOpen} = props;
    const [loading, setLoading] = useState(false);
    const {handleModified} = useContext(AppContext);
    const {authToken} = useContext(AuthContext);
    const { uploadToS3 } = useS3();
    const form = useForm({
        initialValues: {
            type: "teacher",
            name: "",
            lastname: "",
            profilePicture: "",
            email: "",
            phone: "",
            signature: "",
            sendMail: "false",
        },
        validate: {},
    });

    const handleSubmit = async (values) => {
        setLoading(true)

        const { profilePicture, name, lastname, signature } = values
        const profileImageURL = await uploadToS3({
            image: profilePicture,
            fileDirectory: `public/images/users`,
            fileName: `${name}-${lastname}`,
            isRequired: false
        })
        const signatureImageURL = await uploadToS3({
            image: signature,
            fileDirectory: `public/images/signatures`,
            fileName: `${name}-${lastname}`,
            isRequired: false
        })

        const jsonData = {
            data: {
                ...values,
                profilePicture: profileImageURL,
                signature: signatureImageURL,
            }
        }

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            }
        }
        axios.post(`${process.env.REACT_APP_BACKEND_SERVER}admin/academico/createUser`, jsonData, config)
            .then(() => {
                handleModified()
                showNotification({
                    color: "green",
                    status: "success",
                    title: "¡Operación exitosa!",
                    message: "El usuario ha sido creado.",
                    autoClose: 5000,
                });
                setOpen(false)
            })
            .catch((err) => {
                const error = err.message || err.response.data.message || err || "Error al crear el módulo";
                showNotification({
                    color: "red",
                    status: "error",
                    title: "Error",
                    message: `Error al crear el usuario: ${error}`,
                });
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <AcaUsersForm form={form} loading={loading} isCreate={false} handleSubmit={handleSubmit} />
    );
}

export default NewUserModal;