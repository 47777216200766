import './AcaStudentExamAccordion.css'
import { Accordion, Grid, Group, Stack } from '@mantine/core';
import { IconCalendarTime, IconPercentage } from '@tabler/icons-react';
import moment from 'moment';
import { useContext } from 'react';
import AppContext from '../../../../contexts/AppContext/AppContext';

export default function AcaStudentExamAccordion({ data }) {
   const { academicUnits } = useContext(AppContext)

   const mapItems = {
      FAILED: {
         text: 'desaprobado',
         className: 'AcaStudentExamAccordion-result--failed'
      },
      APPROVED: {
         text: 'aprobado',
         className: 'AcaStudentExamAccordion-result--success'
      },
      PENDING: {
         text: 'pendiente',
         className: 'AcaStudentExamAccordion-result--pending'
      },
   }
   
   const items = data?.map((item) => (
      <Accordion.Item key={item?.academicUnitId} value={item?.academicUnitId}>
         <Accordion.Control >
            <Stack justify='space-between'>
               <p className='AcaStudentExamAccordion--name'>{academicUnits.find(au => au.value === item.academicUnitId)?.label || 'Exámen'}</p>
               <span className={`AcaStudentExamAccordion-result ${mapItems[item.note]?.className}`}>{mapItems[item.note]?.text}</span>
            </Stack>
         </Accordion.Control>

         <Accordion.Panel>
            <Grid>
               {item.date &&
                  <Grid.Col span={6}>
                     <Stack>
                        <span className='AcaStudentExamAccordion--date'>Finalizado:</span>
                        <Group align='center'>
                           < IconCalendarTime color='#2C4B58' />
                           <span className='AcaStudentExamAccordion--date'>{moment(item?.date).format('DD MMMM YYYY')}</span>
                        </Group>
                     </Stack>
                  </Grid.Col>
               }
               {item.resultPercentage &&
                  <Grid.Col span={6}>
                     <Stack>
                        <span className='AcaStudentExamAccordion--date'>Porcentaje:</span>
                        <Group align='center'>
                           < IconPercentage color='#2C4B58' />
                           <span className='AcaStudentExamAccordion--date'>{item?.resultPercentage.toString().split('.')[0]}</span>
                        </Group>
                     </Stack>
                  </Grid.Col>
               }
            </Grid>
         </Accordion.Panel>
      </Accordion.Item>
   ));

   return (
      <Accordion>
         {items}
      </Accordion>
   );
}