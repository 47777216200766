import React, { useContext } from "react";
import { NavLink } from "@mantine/core";
import "./SidebarLinksGroup.css";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../contexts/AppContext";
import {
  IconAdjustments,
  IconCalendarStats,
  IconFileAnalytics,
  IconGauge,
  IconNotes,
  IconPresentationAnalytics,
} from "@tabler/icons-react";

function SidebarLinksGroup({ data }) {
  const navigate = useNavigate();
  const { currentUser } = useContext(AppContext);
  const userRoles = currentUser?.user.forVetRole || [];

  const handleRedirection = (url) => {
    navigate(url);
  };

  const getIconForLabel = (label) => {
    switch (label) {
      case "Administracion":
        return <IconGauge />;
      case "Academico":
        return <IconNotes />;
      case "Ventas":
        return <IconCalendarStats />;
      case "Marketing":
        return <IconPresentationAnalytics />;
      case "Moderacion":
        return <IconFileAnalytics />;
      case "Soporte":
        return <IconAdjustments />;
      default:
        return null;
    }
  };

  const items = data.map((_item) => {
    if (!userRoles.includes(_item.requiredPermision)) return null;
    const icon = getIconForLabel(_item.label);
    return _item.link ? (
      <NavLink
        classNames={{ root: "sidebarLinksGroup-link" }}
        onClick={() => handleRedirection(_item.link)}
        label={_item.label}
        leftSection={icon}
      />
    ) : (
      <NavLink
        className="sidebarLinksGroup-link"
        label={_item.label}
        leftSection={icon}
      >
        {_item.links.map((innerLinks) => (
          <NavLink
            className="sidebarLinksGroup-link"
            onClick={() => handleRedirection(innerLinks.link)}
            label={innerLinks.label}
            leftSection={icon}
          />
        ))}
      </NavLink>
    );
  });
  const filteredItems = items.filter((item) => item !== null);
  return <>{filteredItems}</>;
}

export default SidebarLinksGroup;
