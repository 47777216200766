function calculateAmountValues(data) {
    // Data destructuring
    const {amount, agreedAmount, amount_breakdown, discount, commission, gateway} = data;

    // Fixing values
    const commissionAmountFixed = commission?.amount || 0.0;

    // Calculating values
    const amountAfterDiscountAndFee = agreedAmount - (gateway?.amount || amount_breakdown.fee_amount);
    const percentageVariationDiscountAndFee = ((amountAfterDiscountAndFee - agreedAmount) / agreedAmount * 100).toFixed(1);
    const finalAmount = amountAfterDiscountAndFee - commissionAmountFixed;
    const percentageVariationFinal = ((finalAmount - amount) / amount * 100).toFixed(1);

    return {
        // Initial amount
        initialAmount: amount,
        // Discount
        discountAmount: discount.amount,
        amountAfterDiscount: agreedAmount,
        percentageVariationDiscount: -discount?.percent?.toFixed(1) || 0.0,
        // Fee
        feeAmount: (gateway?.amount || amount_breakdown.fee_amount),
        amountAfterDiscountAndFee: amountAfterDiscountAndFee,
        percentageVariationDiscountAndFee,
        // Commission
        amountCommission: commissionAmountFixed,
        percentageVariationCommission: -commission?.percentage?.toFixed(1) || 0.0,
        // Final amount
        finalAmount,
        percentageVariationFinal
    };
}

export { calculateAmountValues };