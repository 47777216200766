import React, { useContext, useState } from 'react';
import "./LessonForm.css";
import { Grid, MultiSelect, Select, Switch, TextInput, Button } from "@mantine/core";
import { DateTimePicker } from "@mantine/dates";
import AppContext from "../../../../contexts/AppContext";
import MaterialsModal from '../../../../components/MaterialsModal/MaterialsModal';

function LessonForm(props) {
   const { form, loading } = props;
   const {currentMaterial = null, materials} = form.values;
   const { teachersUsers, staffUsers, materialOptions, materialsData } = useContext(AppContext);
   const [materialModal, setMaterialModal] = useState(false)
   
   const addMaterialRow = () => {
      // Check if the material is already in the list
      if (!currentMaterial) return;
      if (!materials || materials.some(material => material._id === currentMaterial)) return;

      // Add the new material to the list
      form.setFieldValue("materials", [...materials, setMaterialData(currentMaterial)]);
   }

   const setMaterialData = (_currentMaterial) => {
      return materialsData.find(item => item._id === _currentMaterial)
  }

   return (
      <Grid style={{ width: "100%" }} justify="center" align="center">
         <Grid.Col span={10}>
            <TextInput
               label="Nombre o tema:"
               {...form.getInputProps("name")}
               w={'100%'}
            />
         </Grid.Col>
         <Grid.Col span={2}>
            <Switch
               {...form.getInputProps("state")}
               style={{ marginTop: "20px" }}
               label="Estado"
               disabled
            />
         </Grid.Col>
         <Grid.Col span={6}>
            <Select
               {...form.getInputProps('moderator')}
               withAsterisk
               label="Moderador"
               data={staffUsers}
               searchable
            />
         </Grid.Col>
         <Grid.Col span={6}>
            <MultiSelect
               {...form.getInputProps('teachers')}
               withAsterisk
               label="Proferores"
               data={[...teachersUsers, ...staffUsers]}
               searchable
            />
         </Grid.Col>
         <Grid.Col span={6}>
            <TextInput
               {...form.getInputProps('roomUrl')}
               withAsterisk
               label={form.values.state ? "Link de la clase" : "Link de la grabación"}
            />
         </Grid.Col>
         <Grid.Col span={6}>
            <DateTimePicker
               label="Fecha y hora de la clase"
               {...form.getInputProps('initDate')}
               withAsterisk
            />
         </Grid.Col>
         <Grid.Col span={10}>
            <Select
               data={materialOptions}
               label="Materiales"
               searchable
               value={currentMaterial}
               onChange={(value) => form.setFieldValue("currentMaterial", value)}
            />
         </Grid.Col>
         <Grid.Col span={2}>
            <Button
               style={{ width: "100%", marginTop: "20px" }}
               onClick={addMaterialRow}
            >
               Agregar material
            </Button>
         </Grid.Col>
         <Grid.Col span={12}>
            <Button
               w='max-content'
               variant='outline' color='secondaryColor' onClick={() => setMaterialModal(true)}>Crear material nuevo</Button>
         </Grid.Col>

         {materialModal && 
            < MaterialsModal opened={materialModal} onCreate={
               (newMaterial) => {
                  form.setFieldValue("materials", [...materials, newMaterial])
               }
            } onClose={setMaterialModal} idToAdd={form.values._id} />
         }
         
         <Grid.Col span={12}>
            <Button
               type="submit"
               className="lessonForm__form__button"
               loading={loading}
            >
               Guardar clase
            </Button>
         </Grid.Col>
      </Grid>
   );
}

export default LessonForm;