import React, { useState } from "react";
import { Button, Grid, Select, Switch, TextInput } from "@mantine/core";
import "moment/locale/es";

function AdminBankAccountForm({ form, loading, initialValues }) {
  const [checked, setChecked] = useState(
    form.getInputProps("is_visible").value
  );

  const handleSwitch = (value) => {
    setChecked(value);
    form.setFieldValue("is_visible", value);
  };
  const handleButtons = () => {
    if (!initialValues) {
      const handleChange = (value) => {
        form.setValues({ ...form.values, formToShow: value });
        renderForm();
      };

      return (
        <div>
          <Select
            label="Selecciona la cuenta a crear"
            value={form.values.formToShow}
            onChange={handleChange}
            data={["Cuenta en pesos", "Cuenta en dolares"]}
          ></Select>
        </div>
      );
    }
  };

  const renderForm = () => {
    if (
      initialValues?.accountAlias ||
      form.values.formToShow === "Cuenta en pesos"
    ) {
      return renderFormPesos();
    } else if (
      initialValues?.accountNumber ||
      form.values.formToShow === "Cuenta en dolares"
    ) {
      return renderFormDolares();
    } else {
      return null;
    }
  };

  const renderFormPesos = () => (
    <Grid style={{ width: "100%" }} justify="center" align="center">
      <Grid.Col span={12}>
        <TextInput
          label="Alias"
          {...form.getInputProps("accountAlias")}
          w={"100%"}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <TextInput
          label="Banco de la cuenta"
          {...form.getInputProps("accountBank")}
          w={"100%"}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="CBU"
          {...form.getInputProps("accountCBU")}
          w={"100%"}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="CUIT"
          {...form.getInputProps("accountCUIT")}
          w={"100%"}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="Titular de la cuenta"
          {...form.getInputProps("accountHolder")}
          w={"100%"}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="Tipo de cuenta"
          {...form.getInputProps("accountType")}
          w={"100%"}
        />
      </Grid.Col>
    </Grid>
  );

  const renderFormDolares = () => (
    <Grid style={{ width: "100%" }} justify="center" align="center">
      <Grid.Col span={12}>
        <TextInput
          label="Banco de la cuenta"
          {...form.getInputProps("accountBank")}
          w={"100%"}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="CUIT"
          {...form.getInputProps("accountCUIT")}
          w={"100%"}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="Titular de la cuenta"
          {...form.getInputProps("accountHolder")}
          w={"100%"}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="Numero de cuenta"
          {...form.getInputProps("accountNumber")}
          w={"100%"}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="Tipo de cuenta"
          {...form.getInputProps("accountType")}
          w={"100%"}
        />
      </Grid.Col>
    </Grid>
  );

  return (
    <>
      {handleButtons()}
      {renderForm()}

      <Grid style={{ width: "100%" }} justify="center" align="center">
        <Switch
          checked={checked}
          label="Activar/desactivar cuenta"
          {...form.getInputProps("is_visible")}
          w={"100%"}
          onChange={(event) => handleSwitch(event.currentTarget.checked)}
        />
      </Grid>

      <Grid style={{ width: "100%" }} justify="center" align="center">
        <Button
          type="submit"
          className="adminBankAccountForm__form__button"
          loading={loading}
        >
          Guardar evento
        </Button>
      </Grid>
    </>
  );
}

export default AdminBankAccountForm;
