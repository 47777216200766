import {createColumnHelper} from "@tanstack/react-table";
import {useContext, useEffect, useState} from "react";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import {ERROR_HANDLER} from "../../../utils/error-handler";
import showNotification from "../../../utils/showNotification";
import moment from "moment";
import filterFunctions from "../../../utils/filterFunctions";


const convertDate = dateString => {
    if (!dateString) return
    const [day, _, month, year] = dateString?.toLowerCase()?.split(' ')

    const date = moment(`${day} ${month} ${year}`, "DD MMMM YYYY").format("DD-MM-YYYY");
    return date
}

const COURSES_TYPES = {
    curso: {text: 'Curso', className: 'au-type-moderation--tag au-type-tag--course'},
    diplomado: {text: 'Diplomado', className: 'au-type-moderation--tag au-type-tag--diplomat'},
    posgrado: {text: 'Posgrado', className: 'au-type-moderation--tag au-type-tag--posgrade'},
    webinar: {text: 'Webinar', className: 'au-type-moderation--tag au-type-tag--webinar'},
    masterclass: {text: 'Masterclass', className: 'au-type-moderation--tag au-type-tag--masterclass'},
    taller: {text: 'Taller', className: 'au-type-moderation--tag au-type-tag--workshop'},
    bootcamp: {text: 'Bootcamp', className: 'au-type-moderation--tag au-type-tag--bootcamp'},
}

export default function useUnitsModeration() {
    const {authToken} = useContext(AuthContext);
    const [academicUnitsData, setAcademicUnitsData] = useState(null);
    const [reFetch, setReFetch] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [initialValuesEdit, setInitialValuesEdit] = useState(null);
    const [displayForm, setDisplayForm] = useState(false);

    const axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`
        }
    }

    useEffect(() => {
        const getAllUnits = async () => {
            try {
                setIsLoading(true)
                const getURL = `${process.env.REACT_APP_BACKEND_SERVER}admin/moderation/dashboardInfo`

                const {data} = await axios.get(getURL, axiosConfig)

                const formattedData = data?.map(item => {
                    let initialDate = convertDate(item.comercialInformation.initialDate)
                    return {
                        ...item,
                        comercialInformation: {
                            ...item.comercialInformation,
                            initialDate
                        }
                    }
                })
                setAcademicUnitsData(formattedData)
            } catch (err) {
                console.error(err)
                showNotification({
                    color: "red",
                    status: "error",
                    title: "Error al cargar las unidades académicas.",
                    message: `Detalle: ${ERROR_HANDLER[err.request.status] || ERROR_HANDLER.defaultError}`,
                    autoClose: 12000
                });
            } finally {
                setIsLoading(false)
            }
        }
        getAllUnits()
    }, [reFetch])


    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor('comercialInformation.initialDate', {

            cell: (info) => {
                return info.getValue()
            },
            header: "Fecha",
            size: 248,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "date",
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;

                const [startDate, endDate] = filterValue;
                const rowValue = row.original.comercialInformation.initialDate;

                if (rowValue === 'Fecha inválida') return false;

                const date = moment(rowValue, 'DD MM YYYY');
                return date.isBetween(moment(startDate), moment(endDate), null, '[]');
            }
        }),
        columnHelper.accessor('type', {
            cell: info => {
                return <span
                    className={COURSES_TYPES[info.getValue().toLowerCase()].className}
                >
               {COURSES_TYPES[info.getValue().toLowerCase()].text}
            </span>
            },
            header: 'Tipo',
            enableColumnFilter: true,
            enableSorting: false,
            size: 122,
            filter: "select",
            filterOptions: [
                {value: 'curso', label: 'Curso'},
                {value: 'diplomado', label: 'Diplomado'},
                {value: 'posgrado', label: 'Posgrado'},
                {value: 'webinar', label: 'Webinar'},
                {value: 'masterclass', label: 'Masterclass'},
                {value: 'taller', label: 'Taller'},
                {value: 'bootcamp', label: 'Bootcamp'},
            ],
            filterFn: filterFunctions.selectFilter,
        }),
        columnHelper.accessor('comercialInformation.name', {
            cell: info => info.getValue(),
            header: 'Capacitación',
            enableColumnFilter: true,
            enableSorting: false,
            filter: 'text',
            filterFn: 'includesString',
            size: 400
        }),
    ];

    const handleSubmit = async ({
                                    roomUrl,
                                    zoomCode,
                                    moderator,
                                    selectZoom,
                                    selectZoomCode,
                                    selectModerator,
                                    classesToEdit,
                                    sendNotification
                                }) => {

        if (selectModerator === 'actual' && selectZoom === 'actual'&& selectZoomCode === 'actual') return showNotification({
            color: "red",
            status: "error",
            title: "Operación fallida.",
            message: `No se han identificados campos para modificar.`,
            autoClose: 12000
        });

        if (selectModerator !== 'actual' && !moderator) return showNotification({
            color: "red",
            status: "error",
            title: "Operación fallida.",
            message: `El campo de moderador esta vacío.`,
            autoClose: 12000
        });

        if (selectZoom !== 'actual' && !roomUrl) return showNotification({
            color: "red",
            status: "error",
            title: "Operación fallida.",
            message: `El link de zoom esta vacío.`,
            autoClose: 12000
        });

        if (selectZoomCode !== 'actual' && !zoomCode) return showNotification({
            color: "red",
            status: "error",
            title: "Operación fallida.",
            message: `El código de zoom esta vacío.`,
            autoClose: 12000
        });

        if (!initialValuesEdit) return showNotification({
            color: "red",
            status: "error",
            title: "Operación fallida.",
            message: `No se han encotrado datos para enviar y editar.`,
            autoClose: 12000
        });

        try {
            setIsLoading(true);

            let fieldsToEdit
            if (roomUrl) fieldsToEdit = {roomUrl}
            if (moderator) fieldsToEdit = {...fieldsToEdit, moderator}
            if (zoomCode) fieldsToEdit = {...fieldsToEdit, zoomCode}

            const jsonData = {
                lessons: classesToEdit,
                fields: fieldsToEdit,
                sendNotification
            }

            const putURL = `${process.env.REACT_APP_BACKEND_SERVER}admin/moderation/bulkUpdate`
            await axios.put(putURL, jsonData, axiosConfig)

            showNotification({
                color: 'green',
                status: 'success',
                title: '¡Excelente! Operación exitosa.',
                message: `La unidad académica ha sido editada con éxito.`,
            });
            setReFetch(!reFetch)

        } catch (err) {
            showNotification({
                color: "red",
                status: "error",
                title: "Operación fallida.",
                message: `No se pudo crear / editar el profesor. Detalle: ${ERROR_HANDLER[err.request.status] || ERROR_HANDLER.defaultError}`,
                autoClose: 12000
            });
        } finally {
            setIsLoading(false);
            setDisplayForm(false);
        }
    }

    const onEditUnit = (rowData) => {
        if (rowData) {
            setInitialValuesEdit({
                id: rowData._id,
                comercialInformation: rowData.comercialInformation,
                modules: rowData.modules,
            })
            setDisplayForm(true)
        }
    }


    return {
        data: academicUnitsData,
        columns,
        isLoading,
        initialValuesEdit, setInitialValuesEdit,
        displayForm, setDisplayForm,
        onEditUnit, handleSubmit,
        formValidation: {}
    }
}
