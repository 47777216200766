import './Administrative.css'
import MainLayout from "../../layouts/MainLayout/MainLayout";
import { Route, Routes } from "react-router";
import ViewLayout from "../../layouts/ViewLayout/ViewLayout";
import AdministrativeHome from "../../features/Administrative/AdministrativeHome/AdministrativeHome";
import AdministrativeUsersView from "../../features/Administrative/AdministrativeUsers/AdministrativeUsersView/AdministrativeUsersView";
import AdminBankAccountContainer from '../../features/Administrative/AdministrativeBankAccount/AdminBankAccountContainer/AdminBankAccountContainer';
import AdministrativePaymentsView from '../../features/Administrative/AdministrativePayments/AdministrativePaymentsView/AdministrativePaymentsView';
import AdminSponsorsContainer from "../../features/Administrative/AdministrativeSponsors/AdminSponsorsContainer/AdminSponsorsContainer";
import AdministrativeExpensesView
    from "../../features/Administrative/AdministrativeExpensesView/AdministrativeExpensesView";
import SalesInstallmentsView from "../../features/Sales/SalesInstallments/SalesInstallmentsView/SalesInstallmentsView";

const Administrative = () => {
   return (
      <MainLayout>
         <Routes>
            < Route
               path='/'
               element={
                  <ViewLayout
                     title='Administración'
                     description='Aquí podrás generar contenido específico para la sección relacionadas a lo administrativo.'
                  >
                     < AdministrativeHome />
                  </ViewLayout>
               }
            />
            < Route
               path='/usuarios'
               element={
                  <ViewLayout
                     title='Usuarios'
                     description='Aquí podrás revisar y ajustar información de los usuarios: inhabilitarlos, hequear informacion de pagos, configurar tarifas etc.'
                     backBtnText='Ir a Administración'
                     withBackButton
                  >
                     < AdministrativeUsersView />
                  </ViewLayout>
               }
            />
            < Route
               path='/cuentas-bancarias'
               element={
                  <ViewLayout
                     title='Cuentas Bancarias'
                     description='Habilitar o deshabilitar cuentas. Crear nuevas cuentas bancarias disponibles.'
                     backBtnText='Ir a Administración'
                     withBackButton
                  >
                     < AdminBankAccountContainer />
                  </ViewLayout>
               }
            />
             < Route
                 path='/ingresos'
                 element={
                     <ViewLayout
                         title='Ingresos'
                         description='Aquí podrás visualizar la información relacionada a ingresos por inscripciones.'
                         backBtnText='Ir a Administración'
                         withBackButton
                     >
                         < AdministrativePaymentsView />
                     </ViewLayout>
                 }
             />
             < Route
                 path='/egresos'
                 element={
                     <ViewLayout
                         title='Egresos'
                         description='Aquí podrás visualizar la información pagos a realizar en determinado período de tiempo.'
                         backBtnText='Ir a Administración'
                         withBackButton
                     >
                         < AdministrativeExpensesView />
                     </ViewLayout>
                 }
             />
             <Route
                 path='/cobros'
                 element={
                     <ViewLayout
                         title='Cobros de cuotas'
                         description='Aquí podras encontrar toda la información relacionada a cuotas a pagar el presente mes.'
                         backBtnText='Ir a Administración'
                         withBackButton
                     >
                         <SalesInstallmentsView/>
                     </ViewLayout>
                 }
             />
             < Route
                 path='/sponsors'
                 element={
                     <ViewLayout
                         title='Todos los Sponsors'
                         description='Habilitar o deshabilitar sponsor. Crear nuevos sponsors.'
                         backBtnText='Ir a Administración'
                         withBackButton
                     >
                         < AdminSponsorsContainer />
                     </ViewLayout>
                 }
             />
         </Routes>
      </MainLayout>
   );
};
export default Administrative;
