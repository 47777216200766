function flattenObject(obj, parent = '', res = {}) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            const propName = parent ? `${parent}.${key}` : key;
            if (typeof obj[key] === 'object' && obj[key] !== null) {
                if (Array.isArray(obj[key])) {
                    obj[key].forEach((item, index) => {
                        if (typeof item === 'object' && item !== null) {
                            flattenObject(item, `${propName}[${index}]`, res);
                        } else {
                            res[`${propName}[${index}]`] = item;
                        }
                    });
                } else {
                    flattenObject(obj[key], propName, res);
                }
            } else {
                res[propName] = obj[key];
            }
        }
    }
    return res;
}

export { flattenObject }