import './CalendarItem.css'
import {SCHEDULE_EVENTS_TYPES} from "../../utils/schedule-events-type";

function CalendarRow({ day, name, type, auName }) {

    const renderType = () => {
        switch (type) {
            case SCHEDULE_EVENTS_TYPES.lesson:
                return {
                    className: 'CalendarRow-lesson',
                    event: 'Clase'
                }
            case SCHEDULE_EVENTS_TYPES.exam:
                return {
                    className: 'CalendarRow-exam',
                    event: 'Exámen'
                }
            case SCHEDULE_EVENTS_TYPES.reExam:
                return {
                    className: 'CalendarRow-reExam',
                    event: 'Recuperatorio'
                }
            case SCHEDULE_EVENTS_TYPES.general:
                return {
                    className: 'CalendarRow-general',
                    event: 'General'
                }
            case SCHEDULE_EVENTS_TYPES.break:
                return {
                    className: 'CalendarRow-break',
                    event: 'Receso'
                }
            default: return ''
        }
    }

    return (
        <div className='CalendarRow-container'>
            <div className='CalendarRow--numberArea'>
                <span className='CalendarRow--numberText'>{day}</span>
            </div>
            <div className={`CalendarRow-separatorLine ${renderType().className}`}></div>
            <div className='CalendarRow--eventArea'>
                <span className='CalendarRow--eventText'>{renderType().event}</span>
            </div>
            <div className='CalendarRow--nameArea'>
                <h5 className='CalendarRow--nameText'>{name}</h5>
            </div>
            {auName &&
            <div className='CalendarRow--auName'>
                <h5 className={`CalendarRow--auName--text `}>{auName}</h5>
            </div>
            }

        </div>
    )
}


export default function CalendarItem({ events, month }) {

    return (
        <div className='CalendarItem-container'>
            <h5 className='CalendarItem--month'>{month}</h5>
            <div className='CalendarItem-items--container'>
                {events.map(item => {
                    const date = new Date(item.initDate);
                    const day = date.toLocaleString('es-ES', { day: 'numeric' });
                    return (
                        < CalendarRow key={item._id} day={day} name={item.name} event={item.event} type={item.type} auName={item.auName}  />
                    )
                })}
            </div>
        </div>
    )
}
