import React, {useEffect, useState} from 'react';
import {Select} from "@mantine/core";

function MantineSelect(props) {
    const {form, name, label, description = null, options} = props;
    const [value, setValue] = useState(form.values[name] || null);

    useEffect(() => {
        setValue(form.values[name] || null);
    }, [form.values[name]]);

    return (
        <Select
            label={label}
            value={value}
            onChange={(_val) => {
                setValue(_val);
                form.setFieldValue(name, _val);
            }}
            data={options}
            description={description}
            searchable
            {...props}
        />
    );
}

export default MantineSelect;