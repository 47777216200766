import React from "react";
import {Button} from "@mantine/core";
import "./LessonCard.css";
import moment from "moment/moment";
import {openUrl} from "../../utils/openUrl";

const LessonCard = (props) => {
    const {name, roomUrl, initDate} = props;
    return (
        <div
            className="coursesCards-father-div"
            key={name}
        >
            <div className="coursesCards-div-date">
                <span className="coursesCards-text-span-date">Clase</span>
                <span className="coursesCards-span-date">
                    {moment(initDate).format("DD/MM")}
                </span>
            </div>
            <div className="coursesCards-description-color">
                <p className="coursesCards-description">{name.toUpperCase()}</p>
                <div className="coursesCards-buttons-div">
                    <Button
                        fw="normal"
                        color="var(--color-secondary-dark)"
                        onClick={() => openUrl(`${roomUrl}`)}
                    >
                        Ver Clase
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default LessonCard;
