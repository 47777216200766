import React, {useContext} from 'react';
import AcademicContextProvider from './AcademicContextProvider';
import AcademicContext from './AcademicContext.js';
import {Loader} from "@mantine/core";

/**
 * Inserts UserContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withAcademicContextProvider = (Component) => (props) => (
    <AcademicContextProvider>
        <Component {...props} />
    </AcademicContextProvider>
);

/**
 * Renders the given component when the user context is ready.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withAcademicContextReady = (Component) => (props) => {

    const {isReady} = useContext(AcademicContext);
    return isReady ? <Component {...props} /> :
        <div style={{height: "100%", minHeight: "100vh", display: "flex", alignItems: "center"}}>
            <Loader/>
        </div>
};

export {
    withAcademicContextReady,
    withAcademicContextProvider,
};