import SectionTitle from '../SectionTitle/SectionTitle';
import './SponsorSectionHeader.css';

export default function SponsorSectionHeader() {

   
   return (
      <div className='aboutUsPage-sponsor-heading'>
         <SectionTitle upText='Sponsor del mes' downText='NUESTROS VALIOSOS COLABORADORES' />
         <div className='aboutUsPage-sponsor-heading-paragraph-container'>
            <p className='aboutUsPage-sponsor-heading-text'>En FORVET, valoramos enormemente la colaboración y el apoyo de nuestros socios y sponsors. Gracias a su compromiso con la excelencia en la Medicina Veterinaria, podemos ofrecer recursos, oportunidades y espacios de aprendizaje excepcionales a nuestros miembros de la comunidad.</p>
         </div>
      </div>
   )
}
