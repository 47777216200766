import ContentCreator from '../../../../components/ContentCreator/ContentCreator'
import TanStackTable from '../../../../components/Table/TanStackTable/TanStackTable'
import {Skeleton, Table, Tabs} from '@mantine/core';
import useAdministrativeUsers from '../../../../hooks/Administrative/Users/useAdministrativeUsers'
import './AdministrativeUsersView.css'
import AdministrativeUserDetails from '../AdministrativeUserDetails/AdministrativeUserDetails';
import React from "react";
import StudentModal from "../../../../components/StudentModal/StudentModal";
import AdministrativeFeeForm from "../AdministrativeFeeForm/AdministrativeFeeForm";

const USER_TYPES_DICT = {
    student: "Estudiante",
    teacher: "Profesor",
    staff: "Staff"
}

export default function AdministrativeUsersView() {
    const {
        data,
        columnsStudents,
        columnsTeachers,
        columnsStaff,
        editFunc,
        displayForm,
        setDisplayForm,
        initialValuesEdit,
        setInitialValuesEdit,
        handleSubmit,
        formValidation,
        isLoading,
        studentModalData,
        setStudentModalData
    } = useAdministrativeUsers()

    const getFormToRender = (_type, _form) => {
        switch (_type) {
            case "staff":
                return <AdministrativeFeeForm form={_form.form} isLoading={isLoading}/>
            case "teacher":
                return <AdministrativeFeeForm form={_form.form} isLoading={isLoading}/>
            default: // type === "student"
                return <AdministrativeUserDetails form={_form.form} isLoading={isLoading}/>
        }
    }

    const getPreviewToRender = (_type, _form) => {
        if (_type === "student") return null;
        const name = `${_form.form.values.name} ${_form.form.values.lastname}`
        return (
            <Table.ScrollContainer minWidth={800}>
                <Table verticalSpacing="xs">
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th>Nombre</Table.Th>
                            <Table.Th>Email</Table.Th>
                            <Table.Th>Tipo</Table.Th>
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                        <Table.Tr>
                            <Table.Td>{name}</Table.Td>
                            <Table.Td>{_form.form.values.email}</Table.Td>
                            <Table.Td>{USER_TYPES_DICT[_form.form.values.type] || ""}</Table.Td>
                        </Table.Tr>
                    </Table.Tbody>
                </Table>
            </Table.ScrollContainer>
        )
    }

    return (
        <section className='AdministrativeView-layout--container'>
            {displayForm && (
                <ContentCreator
                    title={"Detalles del usuario"}
                    submitFunc={(values) => handleSubmit(values)}
                    onCancel={() => {
                        setDisplayForm(null)
                        setInitialValuesEdit(null)
                    }}
                    initialValues={initialValuesEdit ?? {}}
                    validation={formValidation}
                    renderPreview={(_form) => getPreviewToRender(displayForm, _form)}
                    renderForm={(_form) => getFormToRender(displayForm, _form)}
                />
            )}

            {!displayForm && data && (
                <>
                    <Tabs
                        defaultValue="students"
                        style={{width: "100%"}}
                    >
                        <Tabs.List>
                            <Tabs.Tab value="students">
                                Estudiantes
                            </Tabs.Tab>
                            <Tabs.Tab value="staff">
                                Staff
                            </Tabs.Tab>
                            <Tabs.Tab value="teachers">
                                Profesores
                            </Tabs.Tab>
                        </Tabs.List>
                        {data.students && !isLoading && (
                            <Tabs.Panel value="students" pt={20}>
                                < TanStackTable
                                    data={data.students}
                                    columns={columnsStudents}
                                    editFunc={editFunc}
                                />
                            </Tabs.Panel>)}
                        {data.staff && !isLoading && (
                            <Tabs.Panel value="staff" pt={20}>
                                < TanStackTable
                                    data={data.staff}
                                    columns={columnsStaff}
                                    editFunc={editFunc}
                                />
                            </Tabs.Panel>)}
                        {data.teachers && !isLoading && (
                            <Tabs.Panel value="teachers" pt={20}>
                                < TanStackTable
                                    data={data.teachers}
                                    columns={columnsTeachers}
                                    editFunc={editFunc}
                                />
                            </Tabs.Panel>)}
                    </Tabs>
                </>
            )}

            {isLoading &&
                < Skeleton h='450px' w='100%'/>}

            {studentModalData && (
                <StudentModal
                    open={studentModalData}
                    setOpen={setStudentModalData}
                    studentData={studentModalData}
                />
            )}
        </section>
    )
}


