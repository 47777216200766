import './TablePagination.css'
import { Button, Select } from '@mantine/core'
import { IconChevronLeft, IconChevronRight, IconChevronsLeft, IconChevronsRight } from '@tabler/icons-react'
import React, {useState} from "react";

export default function TablePagination({table}) {
    const [pageSize, setPageSize] = useState(10);
    return (
      <div className='TablePagination-container'>
         <Button
            variant='outline'
            color='var(--color-tertiary)'
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.setPageIndex(0)}
         >
            < IconChevronsLeft />
            <span className='TablePagination-button--text'>Primer página</span>
         </Button>
         <Button
            variant='outline'
            color='var(--color-tertiary)'
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
         >
            < IconChevronLeft />
            <span className='TablePagination-button--text'>Anterior página</span>
         </Button>
         <Button
            variant='outline'
            color='var(--color-tertiary)'
            disabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
         >
            <span className='TablePagination-button--text'>Siguiente página</span>
            < IconChevronRight />
         </Button>
         <Button
            variant='outline'
            color='var(--color-tertiary)'
            disabled={!table.getCanNextPage()}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
         >
            <span className='TablePagination-button--text'>Ultima página</span>
            < IconChevronsRight />
         </Button>
          <div className="tablePaginationItems__container">
              <Select
                  className="tablePaginationItems__select"
                  value={pageSize.toString()}
                  onChange={(e) => {
                      setPageSize(parseInt(e));
                      table.setPageSize(Number(e));
                  }}
                  data={[
                        {value: "10", label: "10"},
                        {value: "20", label: "20"},
                        {value: "30", label: "30"},
                        {value: "50", label: "50"},
                  ]}
                  // label={"Items por página"}
              />
          </div>

      </div>
   )
}
