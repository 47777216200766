import React, {useContext, useEffect} from 'react';
import {Button, Grid, Loader, NumberInput} from "@mantine/core";
import NotificationBanner from "../../../components/NotificationBanner/NotificationBanner";
import MantineDateInput from "../../../components/MantineDateInput/MantineDateInput";
import AuthContext from "../../../contexts/AuthContext";
import axios from "axios";
import showNotification from "../../../utils/showNotification";
import moment from "moment";
import './SalesInstallmentsForm.css'
import {IconCircleCheck} from "@tabler/icons-react";

const generateInstallmentItem = (form, index) => {
    return (
        <Grid.Col span={12}>
            <div className="SalesPaymentForm-installments-grid-container">
                <Grid className="SalesPaymentForm-installments-grid" justify="flex-start" align="center">
                    <Grid.Col span={6}>
                        <NumberInput
                            withAsterisk
                            label={`Monto de cuota ${index + 1}`}
                            min={0}
                            step={0.1}
                            {...form.getInputProps(`installments.data.${index}.agreedAmount`)}
                            disabled={form.values.installments.data[index].state === 'PAID'}
                        />
                    </Grid.Col>
                    <Grid.Col span={5}>
                        <MantineDateInput
                            withAsterisk
                            label={`Fecha de cobro de cuota ${index + 1}`}
                            form={form}
                            name={`installments.data.${index}.due_date`}
                            disabled={form.values.installments.data[index].state === 'PAID'}
                        />
                    </Grid.Col>
                    <Grid.Col span={1}>
                        {
                            form.values.installments.data[index].state === 'PAID' &&
                                <IconCircleCheck size={24} color="green"/>
                        }
                    </Grid.Col>
                </Grid>
            </div>
        </Grid.Col>
    );
}

function SalesInstallmentsForm(props) {
    const {form, loading} = props;

    const {authToken} = useContext(AuthContext);
    const axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authToken}`
        }
    }

    const getInstallmentsData = async () => {
        const paymentId = form.values._id
        const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/installments/${paymentId}`
        try {
            const { data } = await axios.get(url, axiosConfig)

            // Build installments data
            const installmentsData = data.installments.map(installment => {
                return {
                    due_date: moment(installment.date).toDate(), // Cast to Date and name it due_date
                    ...installment,
                    // amount: installment.agreedAmount, Review Merge Conflict
                }
            })
            // Set values
            form.setFieldValue("installments", {})
            form.setFieldValue("installments.data", installmentsData)
        } catch (error) {
            showNotification({
                color: "red",
                status: "error",
                title: "Operación fallida.",
                message: `No se pudo obtener la información de las cuotas restantes. Detalle: ${
                    error.response ? error.response.data.message : error.message
                }`,
                autoClose: 12000,
            });
        }
    }

    useEffect(() => {
        getInstallmentsData()
    }, []);

    if (!form.values.installments?.data) return <Loader/>;
    return (
        <Grid
            style={{width: "100%"}}
            className="SalesInstallmentsForm__grid__container"
            justify="center" align="center"
        >
            <NotificationBanner
                type="information"
                message={
                    `El importe de las cuotas fue calculado en base al valor del dólar de la fecha de la venta. 
                    Considerar para cualquier ajuste posterior. 
                    Referencia valor del dólar: $${form.values.dollar_day}`
                }
            />
            <Grid className="SalesInstallmentsForm__grid">
                {Array.from({length: form.values.installments.data.length}).map((_, index) => (
                        generateInstallmentItem(form, index)
                ))}
            </Grid>

            <Grid.Col span={12}>
                <Button
                    type="submit"
                    style={{marginTop: "16px"}}
                    loading={loading}
                >
                    Guardar cuotas
                </Button>
            </Grid.Col>
        </Grid>
    );
}

export default SalesInstallmentsForm;