import {searchFlattenedObject} from "./searchFlattenedObject";

const filterFunctions = {
    selectFilter: (row, id, filterValue) => {
        if (!filterValue) return true;
        return row.original[id]?.toLowerCase() === filterValue.toString().toLowerCase() || false;
    },
    textFilter: (row, id, filterValue) => {
        if (!filterValue) return true;
        return row.original[id].toLowerCase().includes(filterValue.toLowerCase());
    },
    objectFilter: (row, id, filterValue) => {
        if (!filterValue) return true;
        return searchFlattenedObject(row.original[id], filterValue)
    },
    dateRangeFilter: (row, id, filterValue) => {
        if (!filterValue) return true;
        const [startDate, endDate] = filterValue;
        const date = new Date(row.original[id]);
        
        return date >= startDate && date <= endDate;
    }
}

export default filterFunctions;