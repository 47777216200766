import React from 'react';

const AcademicContext = React.createContext({
    isReady: false,
    modulesOptions: [],
    lessonsOptions: [],
    specialtiesOptions: [],
    lessonsData: [],
    lessonsDict: {},
    questionnairesData: [],
    handleModified: null,
});

export default AcademicContext;