function flattenObject(obj, prefix = '') {
    const result = [];
    for (const [key, value] of Object.entries(obj)) {
        const newPrefix = prefix ? `${prefix}.${key}` : key;

        if (typeof value === 'string') {
            result.push(`${newPrefix}: ${value}`);
        } else if (typeof value === 'object' && value !== null) {
            result.push(flattenObject(value, newPrefix));
        }
    }
    return result.join('\n');
}

function searchFlattenedObject(obj, searchTerm) {
    const searchTermLower = searchTerm.toLowerCase();
    const flattenedString = flattenObject(obj);
    return flattenedString.toLowerCase().includes(searchTermLower);
}

export { searchFlattenedObject }