import { Button } from "@mantine/core";
import "./SidebarFooter.css";
import { NavLink } from "react-router-dom";

export default function SidebarFooter({
  to = "/",
  text,
  icon,
  isButton,
  ...others
}) {
  if (isButton) {
    return (
      <Button
        classNames={{ root: "sidebarFooter-button-root" }}
        styles={{ root: { width: "100%", alignSelf: "flex-start", justifyContent: "flex-start" } }}
        color="transparent"
        fw="normal"
        variant="subtle"
        to={to}
        {...others}
      >
        {icon}
        <span className="sidebarFooter-text">{text}</span>
      </Button>
    );
  }

  return (
    <NavLink
      className="sidebar-navLink-container"
      to={to}
      {...others}
    >
      {icon && icon}
      <span>{text}</span>
    </NavLink>
  );
}