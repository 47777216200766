import { Avatar, Badge, Flex, Grid, Group, Stack, Tooltip } from '@mantine/core'
import { IconAwardFilled, IconPercentage, IconTrendingUp, IconUsersGroup } from '@tabler/icons-react'
import { useState } from 'react'
import { renderInitialLetterToAvatar } from '../../../../utils/renderInitialLetterToAvatar'
import { isUserTop } from '../../../../utils/isUserTop'
import ModDashboardTeacherStatsModal from '../ModDashboardTeacherStatsModal/ModDashboardTeacherStatsModal'


export default function ModDashboardTeachersTop({ tenMostRatedTeachers }) {

   const [isModal, setIsModal] = useState(null)

   const renderTooltipContent = (unitsAca) => {
      if (!unitsAca || unitsAca.length === 0) return ''
      return unitsAca.map(unit => (
         <Flex key={unit.acaUnit}>
            <span style={{
               fontSize: 'var(--fs-tiny)',
               color: "var(--color-text-primary)",
               textOverflow: 'hidden'
            }}>{unit.acaUnitName}
            </span>
         </Flex>
      ))
   }


   return (
      <Grid.Col span={{ base: 12, md: 6 }}>
         <article className='AdministrativeDashboard-card AdministrativeDashboard-card-morePopular'>
            <Group>
               <Group gap='0'>
                  < IconUsersGroup />
                  < IconTrendingUp />
               </Group>
               <h3 className='AdministrativeDashboard-card-title'>Profesores populares</h3>
            </Group>
            <Stack className="AdministrativeDashboard-stack-wrapper">

               <Flex gap={'2%'} justify={'space-between'} align={'center'} style={{ color: 'var(--color-text-primary)' }}>
                  <Flex w='100%' >
                     <span style={{
                        fontSize: 'var(--fs-body)',
                     }}>Podrás hacer click en cada uno para obtener mas detalles</span>
                  </Flex>
               </Flex>

               {tenMostRatedTeachers?.map(item => (
                  <Tooltip position="top" label={renderTooltipContent(item?.units)} key={item.teacherName} >

                     <Flex
                        onClick={() => setIsModal(item)}
                        className='ModDashboard-TeacherRow--container'
                        direction={{ base: 'column', xs: 'row' }}
                     >
                        <Flex w={{ base: '100%', xs: '60%' }} gap={'8px'} justify={'flex-start'} >
                           <Group pos='relative'>
                              {isUserTop(item.overallTeacherScore) &&
                                 <IconAwardFilled color='var(--color-tertiary)' size={30} style={{ position: 'absolute', left: '-3px', top: '-15px', zIndex: 1 }} />}
                              <Avatar src={item?.profilePicture} alt={item.teacherName} key={item.teacherName}>
                                 {!item?.profilePicture && renderInitialLetterToAvatar(item.teacherName)}
                              </Avatar>
                           </Group>
                           <Group>
                              <Stack gap='0' p={0}>
                                 <h4
                                    className='ModDashboard-text-tiny'
                                 >{item?.teacherName}</h4>
                                 {item?.email && <p className='ModDashboard-text-tiny' style={{
                                    opacity: '.9'
                                 }}>{item?.email}</p>}
                              </Stack>
                           </Group>
                        </Flex>
                        <Flex style={{ flexGrow: 1 }} p={{ base: '12px', xs: '0' }} gap={'10px'} justify={{ base: 'flex-start', xs: 'flex-end' }} >

                           <Stack align='center' gap='0' justify='center' >
                              <span className='ModDashboard-text-tiny-secondary'>Puntaje</span>
                              <Badge fz='var(--fs-tiny)' color='var(--color-tertiary)'>
                                 <Group gap='0' align='center' wrap='nowrap'>
                                    < IconPercentage color="var(--color-text-tertiary)" size={'12px'} />
                                    <span style={{
                                       color: 'var(--color-text-tertiary)'
                                    }}>{item.overallTeacherScore}</span>
                                 </Group>
                              </Badge>
                           </Stack>
                           <Stack align='center' gap='0' justify='center' >
                              <span className='ModDashboard-text-tiny-secondary'>Votos</span>
                              <Badge fz='var(--fs-tiny)' color='var(--color-secondary)'>
                                 <span style={{
                                    color: 'var(--color-text-tertiary)'
                                 }}>{item.totalReviews}</span>
                              </Badge>
                           </Stack>
                        </Flex>
                     </Flex>
                  </Tooltip>

               ))}

            </Stack>
         </article>
         <ModDashboardTeacherStatsModal opened={isModal} onClose={setIsModal} />
      </Grid.Col>
   )
}
