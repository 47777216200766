import moment from "moment";

const monthNames = [
    'enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio',
    'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'
];

function convertDate(dateString, format) {
    if (!dateString) return null;

    // Convert to lowercase and remove "de"
    let [day, month, year] = dateString.toLowerCase().replace(/\bde\b/g, "").trim().split(/\s+/);
    const monthIndex = monthNames.indexOf(month);

    if (monthIndex === -1) return null;

    const finalDateString = `${day}-${monthIndex + 1}-${year}`;

    return format === "date"
        ? moment(finalDateString, "DD-MM-YYYY").toDate()
        : moment(finalDateString, "DD-MM-YYYY").format("DD/MM/YYYY");
}

export { convertDate }