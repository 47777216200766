import './AdministrativePaymentModal.css'
import { Group, Modal, Stack } from '@mantine/core';

export default function AdministrativePaymentModal({ opened, onClose, payment }) {
   function getPaymentBadge(state) {
      const mapPaymentState = {
         pending: {
            text: 'pendiente',
            className: 'AdministrativePaymentModal--pending'
         },
         success: {
            text: 'pagado',
            className: 'AdministrativePaymentModal--success'
         },
         cancelled: {
            text: 'cancelado',
            className: 'AdministrativePaymentModal--cancelled'
         },
         default: {
            text: 'desconocido',
            className: 'AdministrativePaymentModal--default'
         }
      };

      const lowerState = state?.toLowerCase();
      return mapPaymentState[lowerState] || mapPaymentState.default;
   }

   const mapPaymentTypeEnums = {
      SINGLE_PAYMENT: 'PAGO ÚNICO', SUBSCRIPTION: "SUSCRIPCIÓN"
   }

   const paymentStateText = getPaymentBadge(payment.state.toLowerCase()).text
   const paymentStateClassName = getPaymentBadge(payment.state.toLowerCase()).className
   const paymentTypeText = `${payment.payment.type} - ${mapPaymentTypeEnums[payment.type]}`
   const academicUnitName = `${payment.details[0].academicUnitId.comercialInformation.name}`


   return (
      <Modal
         size='auto'
         opened={opened}
         onClose={() => onClose()}
      >
         <Stack>
            <Group>
               <h3 className='AdministrativePaymentModal--title'>Detalles del pago</h3>
            </Group>
            <Group>
               <Group align='center'>
                  <h4 className='AdministrativePaymentModal--itemTitle'>Unidad Académica:</h4>
                  <span className='AdministrativePaymentModal--itemText'>{academicUnitName}</span>
               </Group>
            </Group>
            <Group>
               <Group align='center'>
                  <h4 className='AdministrativePaymentModal--itemTitle'>Código de seguimiento:</h4>
                  <span className='AdministrativePaymentModal--itemText'>{payment.tracking_code}</span>
               </Group>
            </Group>
            <Group>
               <Group align='center'>
                  <h4 className='AdministrativePaymentModal--itemTitle'>Numero identificador:</h4>
                  <span className='AdministrativePaymentModal--itemText'>{payment._id}</span>
               </Group>
            </Group>
            <Group>
               <Group align='center'>
                  <h4 className='AdministrativePaymentModal--itemTitle'>Tipo:</h4>
                  <span className='AdministrativePaymentModal--itemText'>{paymentTypeText}</span>
               </Group>
            </Group>
            <Group>
               <h4 className='AdministrativePaymentModal--itemTitle'>Estado:</h4>
               <span className={`AdministrativePaymentModal--badge ${paymentStateClassName}`}>
                  {paymentStateText}
               </span>
            </Group>

         </Stack>
      </Modal>
   )
}
