import './ModerationStructuresView.css'
import useUnitsModeration from '../../../../hooks/Moderation/Units/useUnitsModeration'
import ContentCreator from '../../../../components/ContentCreator/ContentCreator'
import TanStackTable from '../../../../components/Table/TanStackTable/TanStackTable'
import ModerationStructuresForm from '../ModerationStructuresForm/ModerationStructuresForm'

export default function ModerationStructuresView() {
   const {
      columns,
      data,
      isLoading,
      displayForm,
      setDisplayForm,
      initialValuesEdit,
      setInitialValuesEdit,
      handleSubmit,
      onEditUnit,
      formValidation
   } = useUnitsModeration()
   
   return (
      <section className='ModerationView-layout--container ModerationStructuresView-main-container'>

         {displayForm && (
            <ContentCreator
               title={initialValuesEdit ? "Editar clases" : ""}
               onCancel={() => {
                  setDisplayForm(false)
                  setInitialValuesEdit(null)
               }}
               initialValues={initialValuesEdit ?? {
               }}
               validation={formValidation}
               renderPreview={(_form) => (
                  <></>
               )}
               renderForm={(_form) => (
                  < ModerationStructuresForm handleSubmit={handleSubmit} form={_form.form} loading={isLoading} />
               )}
            />
         )}

        
         
         {!displayForm  && (
            < TanStackTable
               data={data}
               columns={columns}
               editFunc={onEditUnit}
               loading={isLoading}
            />
         )}

      </section>
   )
}
