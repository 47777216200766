import * as yup from "yup";

const LessonsModel = () => {
    const initialValues = {
        _id: null,
        selectedModule: "",
        modules: {},
        selectedLesson: "",
        specialties: [],
        uploadWithCSV: false,
        csvData: [],
        csvFile: null,
    }

    const validationSchema = yup.object().shape({
        uploadWithCSV: yup.boolean(),
        modules: yup.object().when('uploadWithCSV', {
            is: false,
            then: yup.object().test('is-empty', 'Debe seleccionar al menos un módulo', (value) => {
                return Object.keys(value).length > 0;
            }),
            otherwise: yup.object().nullable()
        }),
        specialties: yup.array().test('is-empty', 'Debe seleccionar al menos una especialidad', (value) => {
            return value.length > 0;
        })
    });

    return { initialValues, validationSchema };
}

export { LessonsModel };
