const currencyFormat = (num) => {
    let numString = num.toString();

    // Split the number in integer and decimal parts
    let parts = numString.split(".");
    let integerPart = parts[0].replace(/\./g, "");
    let decimalPart = parts.length > 1 ? "," + parts[1] : "";

    // Format only the integer part
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");

    // Join the parts
    return integerPart + decimalPart;
}

export { currencyFormat };