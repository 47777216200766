import {IconChevronDown, IconChevronUp, IconEdit, IconTrash, IconInfoCircleFilled} from '@tabler/icons-react'
import './TableContent.css'
import {flexRender} from '@tanstack/react-table'
import ColumnFilter from '../ColumnFilter/ColumnFilter'
import {Group, Stack, Tooltip} from '@mantine/core'

export default function TableContent({table, editFunc, onDelete, actionsFunc}) {

    return (
        <table className='TableContent-content-container' style={{minWidth: table.getTotalSize()}}>
            <thead className='TableContent-content--thead'>
            {table?.getHeaderGroups()?.map(headerGroup => (
                <tr className='TableContent-content-thead--row' key={headerGroup.id}>
                    {headerGroup.headers?.map(header => (
                        <th
                            onMouseDown={header.getResizeHandler()}
                            onTouchStart={header.getResizeHandler()}
                            className='TableContent-content-thead--cell' key={header.id}
                            style={{maxWidth: header.getSize()}}
                        >
                            <Stack>
                                <div className='TableContent-content-thead--cell-container'>
                                    {flexRender(header.column.columnDef.header)}
                                    {
                                        header.column.columnDef.tooltip &&
                                        <Tooltip label={header.column.columnDef.tooltip} color="rgba(54,54,54,1)"
                                                 multiline w={304}
                                        >
                                            <IconInfoCircleFilled size={16} className='TableContent-thead--icon'/>
                                        </Tooltip>
                                    }
                                </div>
                                {header.column.getCanSort() &&
                                <Group gap={4} align='center' /* justify='center' w='100%' */>
                                    <IconChevronUp
                                        size={20}
                                        onClick={header.column.getToggleSortingHandler()}
                                        className={{asc: 'TableContent--sortActive'}[header.column.getIsSorted()]}
                                    />
                                    <IconChevronDown
                                        size={20}
                                        onClick={header.column.getToggleSortingHandler()}
                                        className={{desc: 'TableContent--sortActive'}[header.column.getIsSorted()]}
                                    />
                                </Group>
                                }
                                {header.column.getCanFilter() ? < ColumnFilter column={header.column}/> : null}

                                <div className={
                                    `TableContent-resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`
                                }></div>
                            </Stack>
                        </th>
                    ))}
                    {(editFunc || onDelete || actionsFunc) &&
                    <th className='TableContent-content-thead--cell TableContent--sticky--header'>ACCIONES</th>}
                </tr>
            ))}
            </thead>
            <tbody className='TableContent-content--tbody'>
            {table?.getRowModel().rows?.map(row => (
                <tr className='TableContent-content-tbody--row' key={row.id}>
                    {row.getVisibleCells()?.map(cell => (
                        <td style={{maxWidth: cell.column.getSize()}} className='TableContent-content-tbody--cell'
                            key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                    ))}
                    {(editFunc || onDelete || actionsFunc) &&
                    <td className='TableContent-content-tbody--cell TableContent--actionButtons TableContent--sticky--row'>
                        <div className='TableContent--actionButtons'>
                            {editFunc &&
                            <IconEdit className='TableContent--editBtn' size={30}
                                      onClick={() => editFunc(row.original)} m={'0 auto'}/>
                            }
                            {onDelete &&
                            <IconTrash className='TableContent--editBtn' size={30}
                                       onClick={() => onDelete(row.original)} m={'0 auto'}/>
                            }
                            {actionsFunc && actionsFunc(row.original)}
                        </div>
                    </td>
                    }
                </tr>
            ))}
            </tbody>
        </table>
    )
}
