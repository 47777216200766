import React, {useContext, useState} from 'react';
import "./NewLessonModal.css"
import showNotification from "../../../../../utils/showNotification";
import {useForm} from "@mantine/form";
import axios from "axios";
import {Grid, TextInput, Button, MultiSelect} from "@mantine/core";
import AcademicContext from "../../../../../contexts/AcademicContext";
import AuthContext from "../../../../../contexts/AuthContext";
import AppContext from "../../../../../contexts/AppContext";
import {DateTimePicker} from "@mantine/dates";

function NewLessonModal(props) {
    const {setOpen} = props;
    const [loading, setLoading] = useState(false);
    const {handleModified} = useContext(AcademicContext);
    const {teachersUsers, staffUsers} = useContext(AppContext);
    const teacherOptions = [...teachersUsers, ...staffUsers]
    const {authToken} = useContext(AuthContext);
    const form = useForm({
        initialValues: {
            "name": "",
            "moderator": "", // Empty value, will be filled by moderator admin
            "teachers": [],
            "roomUrl": "",  // Empty value, will be filled by moderator admin
            "initDate": "",
            "state": "Open", // Default value
            "modules": [], // Default value
        },
        validate: {},
    });

    const handleSubmit = async (values) => {
        setLoading(true)

        const data = {
            data: {
                ...values,
                name: values.name.toUpperCase()
            }
        }
        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            }
        }

        try {
            await axios.post(`${process.env.REACT_APP_BACKEND_SERVER}admin/academico/lessons`, data, config)
            showNotification({
                color: "green",
                status: "success",
                title: "¡Operación exitosa!",
                message: "Clase creada exitosamente.",
                autoClose: 5000,
            });
            handleModified()
            setOpen(false)
        } catch (err) {
            const error = err.message || err.response.data.message || err || "Error al crear la clase";
            showNotification({
                color: "red",
                status: "error",
                title: "Error",
                message: `Error al crear la clase: ${error}`,
            });
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="createLesson__container">
            <h2 className="createLesson__title">Crear clase</h2>
            <form
                onSubmit={form.onSubmit((values) => handleSubmit(values))}
                className="createLesson__form"
            >
                <Grid style={{width: "100%"}}>
                    <Grid.Col span={12}>
                        <TextInput
                            {...form.getInputProps('name')}
                            withAsterisk
                            label="Nombre de la clase"
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <MultiSelect
                            {...form.getInputProps("teachers")}
                            label="Profesores"
                            data={teacherOptions}
                            withAsterisk
                            searchable
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <DateTimePicker
                            label="Fecha y hora de la clase"
                            {...form.getInputProps('initDate')}
                            withAsterisk
                        />
                    </Grid.Col>
                </Grid>
                <Button type="submit" className="createLesson__button" loading={loading}>Crear clase</Button>
            </form>
        </div>
    );
}

export default NewLessonModal;