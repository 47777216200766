import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../../contexts/AuthContext";
import {ERROR_HANDLER} from "../../../utils/error-handler";
import showNotification from "../../../utils/showNotification";
import axios from "axios";
import {createColumnHelper} from "@tanstack/react-table";
import moment from "moment";
import UserCard from "../../../components/UserCard/UserCard";
import {IconEye, IconCreditCardPay} from '@tabler/icons-react'
import {Badge} from '@mantine/core';
import AdministrativeInstallmentsAccordion
    from "../../../features/Administrative/AdministrativePayments/AdministrativeInstallmentsAccordion/AdministrativeInstallmentsAccordion"
import filterFunctions from "../../../utils/filterFunctions";
import {currencyFormat} from "../../../utils/currencyFormat";
import AdministrativeCommissionsModal
    from "../../../features/Administrative/AdministrativeCommissionsModal/AdministrativeCommissionsModal";
import {calculateAmountValues} from "../../../utils/calculateAmountValues";
import {PAYMENT_TYPES} from "../../../utils/payments-enums";

const getCustomerBadge = (isNew) => {
    const text = isNew ? "Nuevo" : "Preexistente";
    return <div className="salesPayment__badge__container">
        <Badge color={isNew ? "rgb(158, 192, 152)" : "rgb(150,61,61)"}>{text}</Badge>
    </div>
}

const getStateBadge = (state) => {
    let text, color;
    switch (state) {
        case "CONFIRMED":
            text = "Confirmado";
            color = "rgb(158, 192, 152)"; // var(--color-badge-green)
            break;
        case "ERROR":
            text = "Error";
            color = "rgb(150,61,61)"; // var(--color-badge-red)
            break;
        default:
            text = "Pendiente";
            color = "rgb(197,189,132)"; // var(--color-badge-yellow)

    }
    return <div className="salesPayment__badge__container">
        <Badge color={color}>{text}</Badge>
    </div>
}

export default function useAdministrativePayments() {
    const {authToken} = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false)
    const [displayForm, setDisplayForm] = useState(false);
    const [initialValuesEdit, setInitialValuesEdit] = useState(null);
    const [payments, setPayments] = useState(null)
    const [hasToRender, setHasToRender] = useState(false)
    const [cellModal, setCellModal] = useState(null)

    const axiosConfig = {
        headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + authToken,
        }
    }

    useEffect(() => {
        const getPayments = async () => {
            try {
                setIsLoading(true)
                const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/getRegisters`
                const {data} = await axios.get(url, axiosConfig)

                const allPayments = [...data.payments, ...data.installments]
                // Here we sort the payments by date, but we have to take into account that the payments can be future payments
                // For those future payments, we have to sort them at the end of the list
                const currentDate = new Date();
                const sortedPayments = allPayments.sort((a, b) => {
                    const dateA = new Date(a.created_at);
                    const dateB = new Date(b.created_at);

                    // Compare the dates with the current date
                    if (dateA > currentDate && dateB > currentDate) {
                        return dateA - dateB;
                    } else if (dateA > currentDate) {
                        return 1;
                    } else if (dateB > currentDate) {
                        return -1;
                    } else {
                        return dateB - dateA;
                    }
                });

                setPayments(sortedPayments)
            } catch (error) {
                console.error(error)
                showNotification({
                    color: "red",
                    status: "error",
                    title: "Error al cargar los registros.",
                    message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
                    autoClose: 12000
                });
            } finally {
                setIsLoading(false)
            }
        }
        getPayments()
    }, [authToken, hasToRender]);

    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor('created_at', {
            cell: info => {
                return <div className="administrativePayment-cell-center">
                    {moment(info.getValue()).format("DD/MM/YYYY")}
                </div>;
            },
            header: 'Fecha',
            size: 248,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "date",
            filterFn: filterFunctions.dateRangeFilter,
        }),
        columnHelper.accessor("payment.status", {
            cell: (info) => {
                let state = info.getValue();
                if (info.row.original.isInstallment)
                    state = info.row.original.status;

                if (!state) return null
                return getStateBadge(state.toUpperCase());
            },
            header: "Estado",
            size: 144,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            filterOptions: [
                {value: 'CONFIRMED', label: 'Confirmado'},
                {value: 'PENDING', label: 'Pendiente'},
                {value: 'ERROR', label: 'Error'},
            ],
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return row.original.payment.status.toString() === filterValue.toString();
            },
            tooltip: "Indica si se requieren acciones adicionales para completar el pago."
        }),
        columnHelper.accessor('payer', {
            cell: info => {
                const {name, lastname, email, phone, profilePicture} = info.getValue();
                return <span className="cell-pointer"
                             onClick={() => setCellModal({
                                 title: 'Estudiante',
                                 render: < UserCard
                                     profilePicture={profilePicture}
                                     email={email}
                                     phone={phone}
                                     name={name}
                                     lastname={lastname}
                                 />
                             })}>
                   {`${name} ${lastname}`}
            </span>
            },
            header: 'Estudiante',
            size: 200,
            filter: "text",
            enableSorting: false,
            filterFn: filterFunctions.objectFilter,
        }),
        columnHelper.accessor("payer.isNewCustomer", {
            cell: (info) => {
                const customer = info.getValue();
                return getCustomerBadge(customer);
            },
            header: "Nuevo",
            size: 88,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            filterOptions: [
                {value: "true", label: 'Nuevo'},
                {value: "false", label: 'Preexistente'},
            ],
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return row.original.payer.isNewCustomer.toString() === filterValue.toString();
            },
        }),
        columnHelper.accessor('agreedAmount', {
            cell: (info) => {
                const {finalAmount} = calculateAmountValues(info.row.original);
                // Since all installments adjust its currency to the main one, we don't need to convert it
                return `$${currencyFormat(finalAmount)}`
            },
            header: 'Importe',
            size: 112,
            enableColumnFilter: false,
            enableSorting: true,
        }),
        columnHelper.accessor('payment.currency', {
            cell: info => info.getValue(),
            header: 'Moneda',
            size: 88,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            filterOptions: [
                {value: 'ARS', label: 'ARS'},
                {value: 'USD', label: 'USD'},
            ],
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return row.original.payment.currency.toLowerCase() === filterValue.toLowerCase();
            }
        }),
        columnHelper.accessor('', {
            cell: info => {
                return <div className="cell-pointer administrativePayment-cell-center"
                            onClick={() => setCellModal({
                                title: 'Comisiones',
                                render: <AdministrativeCommissionsModal rowData={info.row.original}/>
                            })}
                >
                    < IconEye/>
                </div>
            },
            header: 'Detalles'
        }),
        columnHelper.accessor('payment.type', {
            cell: info => {
                return <span className="cell-pointer" onClick={() => setCellModal({
                    title: 'Tipo de pago',
                    render: <p className="cell-modal-text">{info.getValue()}</p>
                })}>{info.getValue()}</span>
            },
            header: "Plataforma",
            size: 164,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            filterOptions: [
                {value: 'PAYPAL', label: 'PAYPAL'},
                {value: 'PAGOS360', label: 'PAGOS360'},
                {value: 'TRANSFERENCIA', label: 'TRANSFERENCIA'},
            ],
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return row.original.payment.type.toLowerCase() === filterValue.toLowerCase();
            },
        }),
        columnHelper.accessor("details", {
            cell: (info) => {
                const value = info.getValue()[0]?.description;
                return value ? value : "No hay capacitación disponible";
            },
            header: "Capacitación",
            size: 304,
            filter: "text",
            enableSorting: false,
            enableColumnFilter: true,
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return row.original.details[0]?.description.toLowerCase().includes(filterValue.toLowerCase());
            },
        }),
        columnHelper.accessor("type", {
            cell: (info) => {
                return <div className="administrativePayment-cell-center">
                    {PAYMENT_TYPES[info.getValue().toUpperCase()]}
                </div>;
            },
            header: "Concepto",
            size: 120,
            enableColumnFilter: true,
            enableSorting: false,
            filter: "select",
            filterOptions: [
                {value: 'SINGLE_PAYMENT', label: 'PAGO ÚNICO'},
                {value: 'SUBSCRIPTION', label: 'CUOTA'},
            ],
            filterFn: (row, id, filterValue) => {
                if (!filterValue) return true;
                return row.original.type.toString() === filterValue.toString();
            },
            tooltip: "Indica si se trata de un pago único o una cuota."
        }),
        columnHelper.accessor('installments', {
            cell: info => {
                if (typeof info.getValue() === 'string') {
                    return (<div className="administrativePayment-cell-center">
                        {info.getValue()}
                    </div>)
                } else {
                    return (info.row.original.installments?.length > 0 &&
                    <div style={{display: 'grid', placeItems: "center"}} className="cell-pointer"
                         onClick={() => setCellModal({
                             title: 'Cuotas',
                             render: < AdministrativeInstallmentsAccordion
                                 data={info.row.original.installments}
                                 currency={info.row.original.payment.currency}
                             />
                         })}
                    >
                        < IconCreditCardPay/>
                    </div>)
                }
            },
            header: 'Cuotas',
            size: 304,
            enableColumnFilter: false,
            enableSorting: false,
        }),
    ];

    function editFunc(rowData) {
        if (rowData.state === 'APPROVAL_PENDING')
            return showNotification({
                color: "red",
                status: "error",
                title: "No es posible realizar esta operación.",
                message: `De momento, no es posible configurar comisiones para cada cuota.`,
                autoClose: 12000,
            });
        if (rowData) {
            setDisplayForm(true)

            const platformCommissionPercentage = rowData.amount_breakdown?.fee_amount / rowData.amount_breakdown?.net_amount * 100
            setInitialValuesEdit({
                rowData,
                id: rowData._id,
                amountReceivedOnPlatform: rowData.agreedAmount - rowData.gateway?.amount || rowData.amount_breakdown.net_amount || 0,
                platformCommission: {
                    amount: rowData.gateway?.amount || rowData.amount_breakdown?.fee_amount || 0,
                    percentage: rowData.gateway?.percentage || platformCommissionPercentage || 0,
                    currency: rowData.payment?.currency || '',
                },
                salesCommission: {
                    amount: rowData.commission?.amount || 0,
                    percentage: rowData.commission?.percentage || 0,
                }
            })
        }
    }

    async function handleSubmit(values) {
        try {
            const paymentId = initialValuesEdit.id
            if (!paymentId) return showNotification({
                color: "red",
                status: "error",
                title: "Error al editar el registro.",
                message: `Detalle: no se ha proporcionado el identificador del registro.`,
                autoClose: 12000
            });

            setIsLoading(true)
            const putURL = `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/setRegister/${paymentId}`

            const installments = values.rowData.installments
            const installmentsArray = installments && installments.length > 0 ? values.rowData.installments.split('/') : null;
            const installmentIndex = installmentsArray !== null ? parseInt(installmentsArray) - 1 : null;

            const jsonData = {
                ...values,
                payment: values.rowData.payment,
                installmentIndex: installmentIndex
            }

            await axios.put(
                putURL,
                {data: jsonData},
                axiosConfig
            )

            showNotification({
                color: "green",
                status: "success",
                title: "Registro editado con éxito.",
                message: `Detalle: el registro se ha guardado correctamente.`,
            });
            setDisplayForm(false)
            setInitialValuesEdit(null)
            setHasToRender(!hasToRender)

        } catch (error) {
            console.error(error)
            showNotification({
                color: "red",
                status: "error",
                title: "Error al editar el registro.",
                message: `Detalle: ${ERROR_HANDLER[error.request?.status] || ERROR_HANDLER.defaultError}`,
                autoClose: 12000
            });
        } finally {
            setIsLoading(false)
        }
    }

    const formValidation = {

    }

    return {
        data: payments,
        columns,
        displayForm, setDisplayForm,
        isLoading,
        initialValuesEdit, setInitialValuesEdit,
        cellModal, setCellModal,
        editFunc,
        handleSubmit,
        formValidation
    }
}
