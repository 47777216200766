import React, { useState, useEffect } from "react";
import "./MktgNotificationsForm.css";
import { Button, FileInput, Grid, MultiSelect, Select, TextInput } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import moment from "moment";
import "moment/locale/es";
import { EVENT_NATIONALITY } from "../../../../utils/countries-list";
import { IconPhoto } from "@tabler/icons-react";
import { useIsMount } from "../../../../hooks/useIsMount";
import {renderFile} from "../../../../utils/renderFile";

const EVENT_TYPES = [
  { value: "General", label: "General" },
  { value: "paises", label: "Elegir paises" },
];

function MktgNotificationsForm(props) {
  const { form, loading } = props;
  const [dateValue, setDateValue] = useState(moment().toDate());
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [eventType, setEventType] = useState("General");
  const isMount = useIsMount();
  moment.locale("es");

  useEffect(() => {
    if (isMount) {
      if (form.values.place) {
        if (Array.isArray(form.values.place)) {
          setEventType("paises");
          setSelectedCountries(form.values.place);
        } else {
          setEventType("General");
        }
      } else {
        form.setFieldValue("place", "General");
      }
    }
  }, [isMount, form]);

  const handlePlaceChange = (value) => {
    setEventType(value);
    if (value === "General") {
      setSelectedCountries([]);
      form.setFieldValue("place", "General");
    } else if (value === "paises") {
      setSelectedCountries([]);
      form.setFieldValue("place", []);
    }
  };

  const handleCountriesChange = (values) => {
    setSelectedCountries(values);
    form.setFieldValue("place", values);
  };

  return (
    <Grid style={{ width: "100%" }} justify="center" align="center">
      <Grid.Col span={12}>
        <FileInput
          {...form.getInputProps("img")}
          label="Imagen"
          w={'max-content'}
          placeholder='Cargar imagen'
          leftSection={<IconPhoto size={30} />}
          disabled={loading}
          onChange={(file) => {
              renderFile(file).then((_fileBase64) => {
                form.setFieldValue("img", _fileBase64)
              })
          }}
          accept={['image/*']}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <TextInput label="Link" {...form.getInputProps("link")} w={"100%"} />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput label="Titulo" {...form.getInputProps("title")} w={"100%"} />
      </Grid.Col>
      <Grid.Col span={6}>
        <DateInput
          label="Fecha del expiracion"
          dateParser={(dateString) => moment(dateString, "DD/MM/YYYY").toDate()}
          allowFreeInput
          locale="es"
          placeholder="dd/mm/yyyy"
          inputFormat="DD/MM/YYYY"
          valueFormat="DD/MM/YYYY"
          value={dateValue}
          onChange={(e) => setDateValue(moment(e, "DD/MM/YYYY").toDate())}
          onBlur={() => {
            form.setFieldValue("expireAt", dateValue);
          }}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Select
          value={eventType}
          withAsterisk
          label="Tipo de evento"
          data={EVENT_TYPES}
          onChange={handlePlaceChange}
        />
      </Grid.Col>
      {eventType === "paises" &&
        <Grid.Col span={12}>
          <MultiSelect
            value={selectedCountries}
            onChange={handleCountriesChange}
            withAsterisk
            label="Seleccione países"
            data={EVENT_NATIONALITY}
          />
        </Grid.Col>}
      <Grid.Col span={12}>
        <Button
          type="submit"
          className="mktgNotificationsForm__form__button"
          loading={loading}
        >
          Guardar evento
        </Button>
      </Grid.Col>
    </Grid>
  );
}

export default MktgNotificationsForm;
