import React, { useState } from "react";
import { Button, FileInput, Grid, Select, Switch, TextInput } from "@mantine/core";
import "moment/locale/es";
import { IconPhoto } from "@tabler/icons-react";
import {renderFile} from "../../../../utils/renderFile";

function AdminBankAccountForm({ form, loading }) {
  const [checked, setChecked] = useState(form.getInputProps("is_monthly_sponsor").value);

  const handleSwitch = (value) => {
    setChecked(value);
    form.setFieldValue( "is_monthly_sponsor", value );
  };

  const DATA_VALUES = [
    { value: "/", label: "Inicio" },
    { value: "/nosotros", label: "Nosotros" },
  ];

  return (
    <>
    <Grid style={{ width: "100%" }} justify="center" align="center">
      <Grid.Col span={12}>
        <TextInput
          label="Nombre"
          {...form.getInputProps("name")}
          w={"100%"}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <TextInput
          label="Descripcion"
          {...form.getInputProps("description")}
          w={"100%"}
        />
      </Grid.Col>
      <Grid.Col span={6}>
      <FileInput
               {...form.getInputProps("image_url")}
               label="Imagen"
               w={'100%'}
               placeholder='Cargar imagen'
               leftSection={< IconPhoto size={30} />}
               disabled={loading}
               onChange={(file) => {
                   renderFile(file).then((_fileBase64) => {
                        form.setFieldValue("image_url", _fileBase64)
                     })
               }}
               accept={['image/*']}
            />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="Link de redireccion"
          {...form.getInputProps("link")}
          w={"100%"}
        />
      </Grid.Col>
      <Grid.Col span={6}>
      <FileInput
               {...form.getInputProps("logo_url")}
               label="Imagen del logo"
               w={'100%'}
               placeholder='Cargar imagen del logo'
               leftSection={< IconPhoto size={30} />}
               disabled={loading}
               onChange={(file) => {
                   renderFile(file).then((_fileBase64) => {
                        form.setFieldValue("logo_url", _fileBase64)
                     })
               }}
               accept={['image/*']}
            />
      </Grid.Col>
      <Grid.Col span={6}>
        <Select
          label="Espacio a ocupar"
          data={DATA_VALUES}
          {...form.getInputProps("location")}
          w={"100%"}
        />
      </Grid.Col>
    
        <Switch
          checked={checked}
          label="Sponsor del mes"
          {...form.getInputProps("is_monthly_sponsor")}
          w={"100%"}
          onChange={(event) => handleSwitch(event.currentTarget.checked)}
        />
</Grid>
      <Grid style={{ width: "100%" }} justify="center" align="center">
        <Button
          type="submit"
          className="adminBankAccountForm__form__button"
          loading={loading}
        >
          Guardar evento
        </Button>
      </Grid>
    </>
  );
}

export default AdminBankAccountForm;
