import { Skeleton } from '@mantine/core'
import './OpportunitiesCard.css'

export default function OpportunitiesCard({ title = 'medify', description = 'Praesent luctus nisl nec nisl lobortis nec sollicitudin', redirectUrl, logoUrl, handleClickCard, bannerImageUrl, isActive }) {


   return (
      <article className={`OpportunitiesCard-container ${isActive ? "OpportunitiesCard-container--active" : ""}`}>
         {logoUrl
            ? <figure className='OpportunitiesCard-logo-container'>
               < img className='OpportunitiesCard--logo' src={logoUrl} alt={`foto de ${title}`} />
            </figure>
            : < Skeleton h={125} w={125} radius={'lg'} />
         }

         <div className='OpportunitiesCard-texts-container'>
            <h4 className='OpportunitiesCard--title'>{title}</h4>
            <p className='OpportunitiesCard--description'>{description}</p>


            <button className='OpportunitiesCard--link' onClick={() => handleClickCard({ title, description, redirectUrl, logoUrl, bannerImageUrl })}>
               Conocer más
            </button>
         </div>
      </article>
   )
}
