import { Accordion } from '@mantine/core';
import './AccordionItem.css';
import { IconUserQuestion } from '@tabler/icons-react';
const AccordionItem = ({question, answer, id}) => {
  return (
    <div className="helpBody-accordion-div">
              <Accordion
                transitionDuration={1000}
                classNames={{
                  control: "helpBody-accordion-control",
                  panel: "helpBody-accordion-panel",
                }}
              >
                
                  <Accordion.Item value={!id ? "empty" : id}>
                    <Accordion.Control icon={<IconUserQuestion />}>
                      {question}
                    </Accordion.Control>
                    <Accordion.Panel> {answer} </Accordion.Panel>
                  </Accordion.Item>
              </Accordion>
            </div>
  )
}
export default AccordionItem