import React from 'react'
import { Avatar, Badge, Flex, Grid, Group, Stack } from '@mantine/core'
import { IconAwardFilled, IconPercentage, IconTrendingUp, IconUsersGroup } from '@tabler/icons-react'
import { isUserTop } from '../../../../utils/isUserTop'
import { renderInitialLetterToAvatar } from '../../../../utils/renderInitialLetterToAvatar'

export default function ModDashboardModerators({ mostRatedModerators }) {



   return (
      <Grid.Col span={{ base: 12, md: 6 }}>
         <article className='AdministrativeDashboard-card AdministrativeDashboard-card-morePopular'>
            <Group>
               <Group gap='0'>
                  < IconUsersGroup />
                  < IconTrendingUp />
               </Group>
               <h3 className='AdministrativeDashboard-card-title'>Moderadores populares</h3>
            </Group>
            <Stack className="AdministrativeDashboard-stack-wrapper">
               <Flex gap={'2%'} justify={'space-between'} align={'center'} style={{ color: 'var(--color-text-primary)' }}>
                  <Flex miw={'200px'} w={'39%'} gap={'8px'}>
                     <span style={{
                        fontSize: 'var(--fs-body)',
                     }}>Nombre</span>
                  </Flex>
                  <Flex style={{ flexGrow: 1, fontSize: '10px' }} justify='space-between' gap={'10px'}>

                     <Stack align='center' w='100%' miw='55px' gap='0' justify='center' >
                        <span>Promedio</span>
                     </Stack>
                     <Stack align='center' w='100%' miw='55px' gap='0' justify='center' >
                        <span>Realizadas</span>
                     </Stack>
                     <Stack align='center' w='100%' miw='55px' gap='0' justify='center' >
                        <span>Bien</span>
                     </Stack>
                     <Stack align='center' w='100%' miw='55px' gap='0' justify='center' >
                        <span>Regular</span>
                     </Stack>
                     <Stack align='center' w='100%' miw='55px' gap='0' justify='center' >
                        <span>Mal</span>
                     </Stack>
                  </Flex>

               </Flex>
               {mostRatedModerators?.map(item => (
                  <Flex gap={'2%'} w={'100%'}>

                     <Flex w={'39%'} miw={'200px'} gap={'8px'} >
                        <Group pos='relative'>
                           {isUserTop(item.average) &&
                              <IconAwardFilled color='var(--color-tertiary)' size={30} style={{ position: 'absolute', left: '-3px', top: '-15px', zIndex: 1 }} />}
                           <Avatar src={item?.profilePicture} alt={item.name}>
                              {!item?.profilePicture && renderInitialLetterToAvatar(item?.name)}
                           </Avatar>
                        </Group>
                        <Group style={{ overflowX: 'hidden' }}>
                           <Stack gap='0' p={0}>
                              <h4
                                 className='ModDashboard-text-tiny'
                              >{item?.name}</h4>
                              <p className='ModDashboard-text-tiny' style={{
                                 opacity: '.9'
                              }}>{item?.email}</p>
                           </Stack>
                        </Group>
                     </Flex>
                     <Flex style={{ flexGrow: 1 }} justify='space-between' gap={'10px'}>

                        <Stack align='center' w='100%' miw='55px' gap='0' justify='center' >
                           <Badge fz='12px' color='var(--color-tertiary)'>
                              <Group gap='0' align='center' wrap='nowrap'>
                                 < IconPercentage color="var(--color-text-tertiary)" size={'12px'} />
                                 <span style={{
                                    color: 'var(--color-text-tertiary)'
                                 }}>{item.average}</span>
                              </Group>
                           </Badge>
                        </Stack>
                        <Stack align='center' w='100%' miw='55px' gap='0' justify='center' >
                           <Badge fz='12px' color='var(--color-secondary)'>
                              <span
                                 style={{
                                    color: 'var(--color-text-tertiary)'
                                 }}
                              >{item.totalModeratios}</span>
                           </Badge>
                        </Stack>
                        <Stack align='center' w='100%' miw='55px' gap='0' justify='center' >
                           <span
                              className='ModDashboard-text-tiny'
                              style={{
                                 fontWeight: 700
                              }}
                           >{item.totalGood}</span>
                        </Stack>
                        <Stack align='center' w='100%' miw='55px' gap='0' justify='center' >
                           <span className='ModDashboard-text-tiny'
                              style={{
                                 fontWeight: 700
                              }}
                           >{item.totalAverage}</span>
                        </Stack>
                        <Stack align='center' w='100%' miw='55px' gap='0' justify='center' >
                           <span
                              className='ModDashboard-text-tiny'
                              style={{
                                 fontWeight: 700
                              }}
                           >{item.totalBad}</span>
                        </Stack>
                     </Flex>
                  </Flex>
               ))}
            </Stack>
         </article>
      </Grid.Col>
   )
}
