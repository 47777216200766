import * as yup from 'yup';

const PricingModel = () => {
    const initialValues = {
        price: {
            usd: 0,
            ars: 0
        },
        subscriptionPlans: []
    }

    const validationSchema = yup.object().shape({
        price: yup.object().shape({
            usd: yup.number().required("El precio en dólares es obligatorio").min(0, "El precio en dólares no puede ser negativo"),
            ars: yup.number().required("El precio en pesos es obligatorio").min(0, "El precio en pesos no puede ser negativo")
        }),
    })

    return {initialValues, validationSchema}
}

export {PricingModel};
