import "./MainLayout.css";
import Sidebar from "../../features/Sidebar/Sidebar";

function MainLayout({ children }) {
  return (
    <div className="mainLayout__container">
      <aside className="mainLayout__aside">
        <Sidebar />
      </aside>
      <main className="mainLayout__main">{children}</main>
      {/*<FooterContainer/>*/}
    </div>
  );
}

export default MainLayout;
