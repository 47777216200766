import {Badge, Button, Card, Group} from "@mantine/core";
import {
    IconDeviceImac,
    IconCalendarDue,
    IconCreditCard,
} from "@tabler/icons-react";
import "./SalesCardPesos.css";

export default function SalesCardPesos({
                                           classes = 8,
                                           priceArs,
                                           date,
                                           image,
                                           title,
                                           tag,
                                           type = "curso",
                                           buttonText = "mas",
                                           buttonColor = "dark",
                                           buttonVariant = "light",
                                           buttonAction,
                                       }) {
    return (
        <Card className="cardInfo-column" shadow="sm" padding="15px" radius="md">
            <Card.Section className="cardInfo-image-container">
                <img
                    className="cardInfo-image"
                    src={image}
                    height={200}
                    alt="Sales Card Pesos image preview"
                />
            </Card.Section>
            <div className="cardInfo-description-container">
                <Group justify="space-between" mt="md" mb="xs">
                    <Group w={"100%"}>
                        <Badge classNames={{root: "cardInfo-description-badge-tag"}} variant="light">
                            {tag}
                        </Badge>
                        <Badge classNames={{root: "cardInfo-description-badge-type"}}>
                            {type}
                        </Badge>
                    </Group>
                    <Group>
                        <h3 className="card-info-title">{title}</h3>
                    </Group>
                </Group>
                    <Group justify="flex-start" align="center" grow={1}
                           className="cardInfo-commercialInformation-container">
                        <div className="cardInfo-commercialInformation--row">
                            <div className="card-items-info--group">
                                <IconDeviceImac className="card-items-icon" size={24}/>
                                <span className="text-overflow-ellipsis">{classes} clases</span>
                            </div>

                            <div className="card-items-info--group">
                                <IconCalendarDue className="card-items-icon" size={24}/>
                                <span className="text-overflow-ellipsis">{date.toUpperCase()}</span>
                            </div>
                        </div>
                        <div className="card-items-info--group">
                            <IconCreditCard className="card-items-icon" size={24}/>
                            <span
                                className="text-overflow-ellipsis">ARS {priceArs}</span>
                        </div>
                    </Group>

                <Button
                    onClick={buttonAction}
                    variant={buttonVariant}
                    color={buttonColor}
                    fullWidth
                    mt="md"
                    radius="sm"
                >
                    {buttonText.toUpperCase()}
                </Button>
            </div>
        </Card>
    );
}
