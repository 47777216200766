import React from 'react';

const AppContext = React.createContext({
    isReady: false,
    academicUnits: null,
    avals: null,
    currentUser: null,
    staffUsers: null,
    teachersUsers: null,
    materialsData: null,
    materialOptions: null,
    handleModified: null,
    dollarValue: null,
});

export default AppContext;