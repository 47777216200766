import React from 'react';
import "./ContentCreator.css";
import {Button} from "@mantine/core";
import {useForm} from "@mantine/form";
import {useNavigate} from "react-router";

const defaultConvertInitialValues = (initialValues) => {
    return {
        ...initialValues,
    }
}

function ContentCreator(props) {
    const navigate = useNavigate()
    const {
        initialValues,
        convertInitialValues = defaultConvertInitialValues,
        validation,
        submitFunc,
        renderPreview,
        renderForm,
        onCancel = () => {navigate(-1)},
        title = "Creación de contenido"
    } = props;


    const form = useForm({
        validateInputOnChange: true,
        validateInputOnBlur: true,
        initialValues: convertInitialValues(initialValues),
        validate: {...validation}
    })

    return (
        <div className="contentCreator__container">
            <div className="contentCreator__heading">
               <h3 className='contentCreator__title'>{title}</h3>
                <Button color='var(--color-tertiary)' variant='outline' onClick={onCancel}>
                    Cancelar
                </Button>
            </div>

            <div className="contentCreator__preview">
                {renderPreview({form})}
            </div>

            <div className="contentCreator__editor">
                <form
                    className='eventCalendarForm__form'
                    onSubmit={form.onSubmit((values) => submitFunc(values))}
                >
                    {renderForm({form})}
                </form>
            </div>
        </div>
    );
}

export default ContentCreator;