import { useNavigate } from 'react-router-dom'
import './ViewLayout.css'
import { Button } from '@mantine/core'
import { IconArrowLeft } from '@tabler/icons-react'

export default function ViewLayout({
   children,
   withBackButton,
   onBackClickFunc,
   backBtnText = 'Ir atrás',
   title,
   description,
}) {

   const navigate = useNavigate()

   const onBackClick = () => {
      if (onBackClickFunc) {
         onBackClickFunc();
      } else {
         navigate(-1);
      }
   }

   return (
      <main>
         <section className='ViewPage-section-container'>
            <header className='ViewPage-header-container'>
               <h1 className='ViewPage-header--title'>{title}</h1>
               <p className='ViewPage-header--description'>{description}</p>
               {withBackButton &&
                  <Button w='max-content' onClick={onBackClick}>
                     < IconArrowLeft />
                     <span>{backBtnText}</span>
                  </Button>}
            </header>
            {children}
         </section>
      </main>
   )
}
