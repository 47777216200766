import './MktgNewsView.css'
import TanStackTable from '../../../../components/Table/TanStackTable/TanStackTable'
import useMktgNewsView from '../../../../hooks/Marketing/News/useMktgNewsView';
import ContentCreator from '../../../../components/ContentCreator/ContentCreator';
import MktgNewsCreatorForm from '../MktgNewsCreatorForm/MktgNewsCreatorForm';
import NewsCard from '../../../../components/NewsCard/NewsCard';
import DeleteConfirmationModal from '../../../../components/DeleteConfirmationModal/DeleteConfirmationModal';

export default function MktgNewsView() {
   const {
      isLoading,
      data,
      columns,
      editNew,
      handleSubmit,
      displayForm,
      setDisplayForm,
      initialValuesEdit,
      setInitialValuesEdit,
      deleteModalOpen,
      setDeleteModalOpen,
      setSelectedNewId,
      deleteNew,
      formValidations
   } = useMktgNewsView()

   return (
      <section className='MktgNewsView-container'>
         {displayForm && (
            <ContentCreator
               title={initialValuesEdit ? "Editar novedad" : "Crear novedad"}
               submitFunc={(values) => handleSubmit(values)}
               onCancel={() => {
                  setDisplayForm(false)
                  setInitialValuesEdit(null)
               }}
               initialValues={initialValuesEdit ?? {
                  active: true,
                  title: "",
                  text: "",
                  url: "",
                  image: ""
               }}
               validation={formValidations}
               renderPreview={(_form) => (
                  < NewsCard
                     title={_form.form.values.title}
                     description={_form.form.values.text}
                     buttonText='Ver mas'
                     route={_form.form.values.url}
                     image={_form.form.values.image}
                     active={_form.form.values.active}
                  />
               )}
               renderForm={(_form) => (
                  < MktgNewsCreatorForm form={_form.form} isLoading={isLoading} />
               )}
            />
         )}

         {data && (
            <TanStackTable
               loading={isLoading}
               data={data}
               columns={columns}
               displayForm={displayForm}
               editFunc={editNew}
               onDelete={deleteNew}
               addButtonFunc={() => {
                  setDisplayForm(!displayForm)
                  if (displayForm) setInitialValuesEdit(null)
               }}
            />
         )}

         <DeleteConfirmationModal
            opened={deleteModalOpen}
            loading={isLoading}
            onClose={() => {
               setDeleteModalOpen(false)
               setSelectedNewId(null)
            }}
            onCancel={() => {
               setDeleteModalOpen(false)
               setSelectedNewId(null)
            }}
            onConfirm={deleteNew}
         />

      </section>
   )
}
