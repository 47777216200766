export const mapInstallmentsEnums = {
    PAID: 'pagado',
    PENDING: 'pendiente'
}

export const mapCostumerState = {
    CUSTOMER: 'cliente',
    COMPANY: 'empresa',
}

export const PAYMENT_TYPES = {
    SINGLE_PAYMENT: 'PAGO ÚNICO',
    SUBSCRIPTION: 'CUOTA',
}

export const mapInscriptionOriginEnums = {
    GENERAL: 'General',
    SCHOOL_SCHOLARSHIP: 'Beca escolar',
    UNIVERSITY_SCHOLARSHIP: 'Beca universitaria',
    FORVET_SCHOLARSHIP: 'Beca Forvet'
}

export const BANK_ACCOUNTS_OPTIONS = [
    { value: 'TRANS. NX', label: 'NX ARANTXA' },
    { value: 'TRANS. MP NOEL', label: 'MP NOEL' },
    { value: 'TRANS. MP SUSI', label: 'MP SUSI' },
    { value: 'TRANS. MP BETY', label: 'MP BETY' },
    { value: 'TRANS. MP PAOLA', label: 'MP PAOLA' },
    { value: 'TRANS. MACRO', label: 'MACRO PAOLA' },
    { value: 'PAYPAL', label: 'PAYPAL' },
    { value: 'TRANS. CTA UY', label: 'TRANS. CTA UY' },
    { value: 'WESTERN UNION', label: 'WESTERN UNION' },
    { value: 'EFECTIVO', label: 'EFECTIVO' },
    { value: 'OTRAS CTAS', label: 'OTRAS CTAS' } // Keep this option at the end, it's the default one
];



