import './MktgHome.css'
import { useContext } from 'react'
import MarketingContext from '../../../contexts/MarketingContext/MarketingContext'
import ViewCard from '../../../components/ViewCard/ViewCard'

export default function MktgHome() {

   const { MARKETING_ITEMS } = useContext(MarketingContext)

   return (
      <div className='MarketingPage-grid-container'>
         {MARKETING_ITEMS.map(item => (
            < ViewCard item={item} />
         ))}
      </div>
   )
}
