import {Button, Checkbox, Grid, Group, Loader, Modal, Select, Stack, TextInput} from '@mantine/core'
import {IconBrandZoom, IconUser, Icon123} from '@tabler/icons-react'
import React, {useContext} from 'react'
import AppContext from '../../../../contexts/AppContext'

export default function ModerationStructuresFormModal({
                                                          isModal,
                                                          resetInitalValues,
                                                          classesToEdit,
                                                          selectZoom,
                                                          setSelectZoom,
                                                          selectZoomCode,
                                                          setSelectZoomCode,
                                                          selectModerator,
                                                          setSelectModerator,
                                                          form,
                                                          sendNotification,
                                                          setSendNotification,
                                                          isLoading,
                                                          handleSubmit
                                                      }) {
    const {staffUsers} = useContext(AppContext)

    return (
        < Modal
            opened={isModal}
            onClose={() => resetInitalValues()}
        >
            {!classesToEdit && null}
            <Grid w='100%'>
                <Grid.Col span={12}>
                    <Stack gap={4}>
                        <Select
                            leftSection={< IconBrandZoom/>}
                            label="Editar link de zoom"
                            placeholder="Selecciona uno:"
                            defaultValue={selectZoom}
                            value={selectZoom}
                            onChange={setSelectZoom}
                            data={[
                                {value: 'actual', label: 'Mantener valor actual'},
                                {value: 'newZoom', label: 'Agregar uno nuevo'},
                            ]}
                        />
                        {(selectZoom === 'newZoom') &&
                        < TextInput
                            placeholder='Escribe el link de zoom'
                            name='zoomInput'
                            onChange={({target}) => {
                                form.setFieldValue('roomUrl', target.value)
                            }}
                        />
                        }
                    </Stack>

                </Grid.Col>
                <Grid.Col span={12}>
                    <Stack gap={4}>
                        <Select
                            leftSection={< Icon123/>}
                            label="Editar código de sala de zoom"
                            placeholder="Selecciona uno:"
                            defaultValue={selectZoomCode}
                            value={selectZoomCode}
                            onChange={setSelectZoomCode}
                            data={[
                                {value: 'actual', label: 'Mantener valor actual'},
                                {value: 'newZoomCode', label: 'Agregar uno nuevo'},
                            ]}
                        />
                        {(selectZoomCode === 'newZoomCode') &&
                        < TextInput
                            placeholder='Escribe el código de sala de zoom'
                            name='zoomCodeInput'
                            onChange={({target}) => {
                                form.setFieldValue('zoomCode', target.value)
                            }}
                        />
                        }
                    </Stack>

                </Grid.Col>
                <Grid.Col span={12}>
                    <Stack gap={4}>
                        <Select
                            leftSection={< IconUser/>}
                            label="Editar moderador"
                            placeholder="Selecciona uno:"
                            defaultValue={selectModerator}
                            value={selectModerator}
                            onChange={setSelectModerator}
                            data={[
                                {value: 'actual', label: 'Mantener valor actual'},
                                {value: 'newModerator', label: 'Agregar uno nuevo'},
                            ]}
                        />

                        {(selectModerator === 'newModerator') &&
                        < Select
                            placeholder="Selecciona uno:"
                            data={staffUsers}
                            name='moderatorInput'
                            searchable
                            onChange={value => {
                                form.setFieldValue('moderator', value)
                            }}
                        />
                        }
                    </Stack>

                    <Group w='100%' py={20}>
                        <Checkbox checked={sendNotification} onChange={() => setSendNotification(!sendNotification)}
                                  label="Enviar notificación sobre este cambio"/>
                    </Group>


                    <Group py={20} w='100%' align='center' justify='center'>
                        <Button
                            variant='outline'
                            color='#DF3576'
                            onClick={() => resetInitalValues()}
                            w='max-content'
                        >
                            Cancelar
                        </Button>
                        <Button
                            w='max-content'
                            type='submit'
                            onClick={() => handleSubmit({
                                    selectZoom,
                                    selectModerator,
                                    selectZoomCode,
                                    roomUrl: form.values.roomUrl,
                                    zoomCode: form.values.zoomCode,
                                    moderator: form.values.moderator,
                                    classesToEdit,
                                    sendNotification
                                }
                            )}
                        >
                            {isLoading
                                ? < Loader color='#fff' size={20}/>
                                : "Guardar cambios"}
                        </Button>
                    </Group>
                </Grid.Col>

            </Grid>
        </Modal>
    )
}
