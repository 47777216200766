import './TanStackTable.css'
import {
    useReactTable,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    getFilteredRowModel
} from "@tanstack/react-table"
import React, {useContext, useState} from "react"
import TableHeader from '../TableHeader/TableHeader'
import TableContent from '../TableContent/TableContent'
import TablePagination from '../TablePagination/TablePagination'
import TableNoContet from '../TableNoContent/TableNoContet'
import {Skeleton} from '@mantine/core'
import showNotification from "../../../utils/showNotification";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import {flattenObject} from "../../../utils/flattenObject";

export default function TanStackTable({
                                          children,
                                          data,
                                          columns,
                                          withInputSearch = true,
                                          withPagination = true,
                                          title,
                                          editFunc,
                                          onDelete,
                                          actionsFunc = null,
                                          addButtonFunc = null,
                                          addButtonText = 'Crear',
                                          loading,
                                          canRezise = false,
                                          displayForm // Adjustment to keep filters to reset
                                      }) {

    const {authToken} = useContext(AuthContext)
    const [sorting, setSorting] = useState([])
    const [columnFilters, setColumnFilters] = useState([])

    const table = useReactTable({
        columns,
        data,
        columnResizeMode: canRezise ? 'onChange' : null,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        state: {
            sorting,
            columnFilters,
        },
        onColumnFiltersChange: setColumnFilters,
        onSortingChange: setSorting,
        defaultColumn: {
            size: 200, //starting column size
            minSize: 50, //enforced during column resizing
            maxSize: 400, //enforced during column resizing
        },
    })

    const downloadCSV = async (_res) => {
        const url = window.URL.createObjectURL(new Blob([_res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "export.csv");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    const handleExport = async () => {
        const filteredRows = table.getFilteredRowModel().flatRows.map(row => row.original)
        if (!filteredRows || filteredRows.length === 0) {
            showNotification({
                color: "red",
                status: "error",
                title: "Error al descargar archivo CSV",
                message: `Detalle: No se han encontrado datos para exportar.`
            })
        }

        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`,
            },
            responseType: 'blob'
        };

        const flattenData = filteredRows.map((row) => flattenObject(row))
        const jsonData = {
            data: flattenData,
            columns: Object.keys(flattenData[0]),
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BACKEND_SERVER}admin/exportCSV`,
                jsonData,
                config
            );

            await downloadCSV(response)

            showNotification({
                color: "green",
                status: "success",
                title: "¡Excelente! Operación exitosa.",
                message: `El CSV se ha exportado correctamente.`,
            });
        } catch (error) {
            showNotification({
                color: "red",
                status: "error",
                title: "Error al exportar CSV",
                message: `Detalle: ${error.message}`
            })
        }
    }

    if (!data) return < TableNoContet/>
    return (
        <div className='TanStackTable-first-container' style={{display: displayForm ? "none" : "block"}}>
            <div className='TanStackTable-wrapper-container'>
                {withInputSearch &&
                < TableHeader
                    title={title}
                    addButtonFunc={addButtonFunc}
                    addButtonText={addButtonText}
                    handleExport={handleExport}
                />
                }

                <div className='TanStackTable-TableContent-wrapper'>
                    {loading
                        ? < Skeleton h='450px' w='100%'/>
                        : < TableContent
                            table={table}
                            editFunc={editFunc}
                            onDelete={onDelete}
                            actionsFunc={actionsFunc}
                        />
                    }
                </div>

            </div>

            {withPagination &&
            < TablePagination
                table={table}
            />
            }

            {children}
        </div>
    )
}
