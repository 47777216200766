import React from 'react';
import "./DeleteConfirmationModal.css";
import { Modal, Button } from '@mantine/core';

function DeleteConfirmationModal(props) {
    const {opened, onClose, onCancel, onConfirm, loading} = props;
    return (
        <>
            <Modal opened={opened} onClose={onClose} title="Confirmar acción">
                <h2 className="deleteConfirmationModal__title">¿Está seguro que desea eliminar este elemento?</h2>
                <div className="deleteConfirmationModal__buttons">
                    <Button onClick={onCancel} variant="outline" loading={loading}>Cancelar</Button>
                    <Button onClick={onConfirm} loading={loading}>Confirmar</Button>
                </div>
            </Modal>
        </>
    );
}

export default DeleteConfirmationModal;