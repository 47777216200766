import React, {useState} from 'react';
import moment from "moment";
import {DateInput} from "@mantine/dates";

function MantineDateInput(props) {
    const {form, name, label, initialValue = moment().toDate(), description = null, disabled = false} = props;
    const [dateValue, setDateValue] = useState(initialValue);
    return (
        <DateInput
            label={label}
            description={description}
            disabled={disabled}
            dateParser={(dateString) => moment(dateString, 'DD/MM/YYYY').toDate()}
            allowFreeInput
            locale="es"
            placeholder="dd/mm/yyyy"
            inputFormat="DD/MM/YYYY"
            valueFormat="DD/MM/YYYY"
            value={dateValue}
            onChange={(e) => setDateValue(moment(e, 'DD/MM/YYYY').toDate())}
            onBlur={() => {
                form.setFieldValue(name, dateValue)
            }}
            autoComplete='off'
            {...form.getInputProps(name)}
            error={form?.errors?.[name]}
        />
    );
}

export default MantineDateInput;