import React from 'react';
import "./TabButton.css";

function TabButton(props) {
    const {icon: Icon, text, onClick, active} = props;
    return (
        <div className={`tabButton__container ${active && "tabButton__container--active"}`} onClick={onClick}>
            <Icon className="tabButton__icon"/>
            <span className="tabButton__text">{text}</span>
        </div>
    );
}

export default TabButton;