import "./DailyRecommendationCard.css";
import defaultImg from '../../assets/DailyRecommendation/RecommendedVet.png'
import { useContext } from "react";
import AppContext from "../../contexts/AppContext/AppContext";
import { Skeleton } from "@mantine/core";

const DailyRecommendationCard = ({ advice, user, userType = 'Staff' }) => {
   const { staffUsers } = useContext(AppContext)

   if(!staffUsers) return < Skeleton w='100%' />
   const userName = staffUsers?.find(userId => userId.value === user)?.label ?? ''
   
   return (
      <section className="dailyRecomendationCard--mainContainer">
         <h3 className="dailyRecomendationCard-header">Consejo del día:</h3>
         <div className="dailyRecomendationCard-testimonial-card">
             <img
               className="dailyRecomendationCard-testimonial-image"
               src={defaultImg} alt="veterinaria con perro"
            />
            <div className="dailyRecomendationCard-testimonial-details">
               <p className="dailyRecomendationCard-recomendation">
                  {advice}
               </p>
               <p className="dailyRecomendationCard-author">
                  {`${userName}`} - {userType}
               </p>
            </div>
         </div>
      </section>
   );
};

export default DailyRecommendationCard;
