export const openBase64InNewTab = (base64) => {
    if (typeof base64 !== "string") {
        console.error("El contenido proporcionado no es una cadena base64.");
        return;
    }

    // Extrae el tipo MIME y los datos base64
    const match = base64.match(/^data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+);base64,(.*)$/);
    if (!match) {
        console.error("El formato base64 proporcionado no es válido.");
        return;
    }

    const mimeType = match[1];
    const base64Data = match[2];

    // Decodifica la cadena base64
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    // Crea un objeto Blob a partir de los bytes decodificados
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });

    // Crea una URL para el Blob y ábrela en una nueva pestaña
    const blobUrl = URL.createObjectURL(blob);
    const newTab = window.open();
    newTab.document.write(`<iframe src="${blobUrl}" style="border: none; width: 100%; height: 100%;" allowfullscreen></iframe>`);
};
