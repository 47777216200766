import './AcaStudentCertificatesAccordion.css'
import { Group, Loader } from "@mantine/core";
import { IconCertificate, IconDownload } from "@tabler/icons-react";
import { useContext, useState } from 'react';
import AuthContext from '../../../../contexts/AuthContext';
import axios from 'axios';
import showNotification from '../../../../utils/showNotification';
import { ERROR_HANDLER } from '../../../../utils/error-handler';


export default function AcaStudentCertificatesAccordion({ data, userId }) {
   const { authToken } = useContext(AuthContext)
   const [loadingIcons, setLoadingIcons] = useState({});


   const handleDownload = async (item) => {
      try {
         showNotification({
            color: "green",
            status: "success",
            title: "Estamos creando el certificado.",
            message: `Detalle: esto puede tardar unos segundos.`,
         });
         setLoadingIcons(prevState => ({ ...prevState, [item.certificateAcaUnitId]: true }));
         const postURL = `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/printCertificate`

         const axiosConfig = {
            headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer ${authToken}`
            },
            responseType: 'blob'
         }

         const jsonData = {
            idUser: userId,
            idAcaUnit: item.certificateAcaUnitId,
            withGrade: true,
            analytic: true
         }

         const response = await axios.post(postURL, jsonData, axiosConfig)

         const blob = new Blob([response.data], { type: "application/pdf" })
         const url = window.URL.createObjectURL(blob)
         window.open(url)

         showNotification({
            color: "green",
            status: "success",
            title: "El certificado se ha creado correctamente.",
            message: `Detalle: se ha abierto en una nueva pestaña.`,
         });
      } catch (error) {
         console.error(error)
         showNotification({
            color: "red",
            status: "error",
            title: "Error al generar el certificado.",
            message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
            autoClose: 12000
         });
      } finally {
         setLoadingIcons(prevState => ({ ...prevState, [item.certificateAcaUnitId]: false }));
      }
   }

   return (
      <section className='AcaStudentCertificatesAccordion--container'>
         {data?.map(item => (
            <Group key={item.certificateAcaUnitId} align='center' justify='center' className='AcaStudentCertificatesAccordion--certificateRow'>
               <Group style={{ flexGrow: 1 }} justify='center'>
                  {item.note
                     ? <span className='AcaStudentCertificatesAccordion--note'>{item.note}%</span>
                     : < IconCertificate className='AcaStudentCertificatesAccordion--noteIcon' color="#243D48" />}

                  <h4 className='AcaStudentCertificatesAccordion--title'>{item.certificateName}</h4>
               </Group>

               <Group>
                  {loadingIcons[item.certificateAcaUnitId] ? < Loader size={20} /> : (
                     <IconDownload
                        className='AcaStudentCertificatesAccordion--downloadIcon'
                        onClick={() => {
                           if (Object.keys(loadingIcons).length > 0) return null
                           handleDownload(item)
                        }}
                     />
                  )}
               </Group>
            </Group>
         ))}
      </section>
   );
}