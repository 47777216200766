import './AdministrativePaymentsView.css'
import useAdministrativePayments from '../../../../hooks/Administrative/Payments/useAdministrativePayments'
import ContentCreator from '../../../../components/ContentCreator/ContentCreator'
import TanStackTable from '../../../../components/Table/TanStackTable/TanStackTable'
import { Skeleton, Modal } from '@mantine/core';
import AdministrativePaymentsForm from '../AdministrativePaymentsForm/AdministrativePaymentsForm';
import {AdministrativePaymentsTablePreview} from "../../AdministrativePaymentsTablePreview/AdministrativePaymentsTablePreview";
export default function AdministrativePaymentsView() {

   const { 
      data,
      columns, 
      displayForm,
      setDisplayForm,
      initialValuesEdit,
      setInitialValuesEdit,
      editFunc,
      handleSubmit,
      isLoading,
      cellModal,
      setCellModal,
      formValidation

   } = useAdministrativePayments()

   return (
      <section className='AdministrativeView-layout--container'>
         {displayForm && (
            <ContentCreator
               title={"Detalles del pago"}
               submitFunc={(values) => handleSubmit(values)}
               onCancel={() => {
                  setDisplayForm(false)
                  setInitialValuesEdit(null)
               }}
               initialValues={initialValuesEdit ?? {}}
               validation={formValidation}
               renderPreview={(_form) => (
                  <AdministrativePaymentsTablePreview rowData={_form.form.values} />
               )}
               renderForm={(_form) => (
                  <AdministrativePaymentsForm form={_form.form} isLoading={isLoading} />
               )}
            />
         )}

         {isLoading &&
            < Skeleton h='450px' w='100%' />}

         {!displayForm && data && !isLoading && (
            < TanStackTable
               data={data}
               columns={columns}
               editFunc={editFunc}
            />
         )}

         <Modal
            classNames={{title: 'cell-modal--title'}}
            opened={cellModal}
            onClose={() => setCellModal(null)}
            title={cellModal?.title}
         >
            {cellModal?.render}
         </Modal>

      </section>
   )
}
