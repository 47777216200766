import React from 'react';
import {Modal} from "@mantine/core";
import SubscriptionPlanModal from "./SubscriptionPlanModal/SubscriptionPlanModal";
import NewModuleModal from "./NewModuleModal/NewModuleModal";
import NewLessonModal from "./NewLessonModal/NewLessonModal";
import NewQuestionnaireModal from "./NewQuestionnaireModal/NewQuestionnaireModal";
import NewUserModal from "./NewUserModal/NewUserModal";
import EditLessonFormWrapper from "../../EditLessonFormWrapper/EditLessonFormWrapper";

function AcademicUnitModals(props) {
    const {
        formHandler,
        createSubscriptionModalOpen,
        setCreateSubscriptionModalOpen,
        createModuleModal,
        setCreateModuleModal,
        createLessonModal,
        setCreateLessonModal,
        createQuestionnaireModal,
        setCreateQuestionnaireModal,
        createUserModal,
        setCreateUserModal,
        editLessonModal,
        setEditLessonModal
    } = props;
    return (
        <>
            <Modal opened={createSubscriptionModalOpen} onClose={() => setCreateSubscriptionModalOpen(false)}
                   closeOnClickOutside={false}>
                <SubscriptionPlanModal
                    formHandler={formHandler}
                    setOpen={setCreateSubscriptionModalOpen}
                />
            </Modal>
            <Modal opened={createModuleModal} onClose={() => setCreateModuleModal(false)}
                   closeOnClickOutside={false}>
                <NewModuleModal
                    setOpen={setCreateModuleModal}
                    formHandler={formHandler}
                />
            </Modal>
            <Modal opened={createLessonModal} onClose={() => setCreateLessonModal(false)}
                   closeOnClickOutside={false}>
                <NewLessonModal
                    setOpen={setCreateLessonModal}
                />
            </Modal>
            <Modal opened={createQuestionnaireModal} onClose={() => setCreateQuestionnaireModal(false)}
                   size={"80%"}
                   closeOnClickOutside={false}>
                <NewQuestionnaireModal
                    setOpen={setCreateQuestionnaireModal}
                    formHandler={formHandler}
                />
            </Modal>
            <Modal opened={createUserModal} onClose={() => setCreateUserModal(false)}
                   size="80%"
                   closeOnClickOutside={false}>
                <NewUserModal
                    setOpen={setCreateUserModal}
                />
            </Modal>
            <Modal opened={editLessonModal} onClose={() => setEditLessonModal(null)}
                   size="80%"
                   closeOnClickOutside={false}>
                <EditLessonFormWrapper
                    lessonId={editLessonModal}
                    setOpen={setEditLessonModal}
                />
            </Modal>
        </>
    );
}

export default AcademicUnitModals;