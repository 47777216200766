import React, {useEffect, useMemo, useState,} from "react";
import MarketingContext from "./MarketingContext.js";
import {useIsMount} from "../../hooks/useIsMount.js";
import {
    IconAd2,
    IconCalendarPlus,
    IconMessage,
    IconNews,
    IconNotification,
    IconQuestionMark,
    IconTooltip
} from "@tabler/icons-react";

const MARKETING_ITEMS = [
    {
        title: 'Novedades',
        text: 'Generar nuevas entradas para el campus y web ventas.',
        href: 'novedades',
        icon: < IconNews size={50} className='ViewCard--icon' color='var(--color-white)'/>,
    },
    {
        title: 'Testimonios',
        text: 'Recepción y lectura de testimonios.',
        href: 'testimonios',
        icon: < IconMessage size={50} className='ViewCard--icon' color='var(--color-white)'/>,
    },
    {
        title: 'Calendario',
        text: 'Generar eventos en calendario.',
        href: 'calendario',
        icon: < IconCalendarPlus size={50} className='ViewCard--icon' color='var(--color-white)'/>,
    },
    {
        title: 'Consejos',
        text: 'Crear nuevos mensajes y recomendados.',
        href: 'consejos',
        icon: < IconTooltip size={50} className='ViewCard--icon' color='var(--color-white)'/>,
    },
    {
        title: 'Oportunidades',
        text: 'Incorporar o editar oportunidades disponibles.',
        href: 'oportunidades',
        icon: < IconAd2 size={50} className='ViewCard--icon' color='var(--color-white)'/>,
    },
    {
        title: 'Notificaciones',
        text: 'Incorporar o editar notificaciones disponibles.',
        href: 'notificaciones',
        icon: < IconNotification size={50} className='ViewCard--icon' color='var(--color-white)'/>,
    },
    {
        title: 'Preguntas Frecuentes',
        text: 'Incorporar o editar preguntas disponibles.',
        href: 'preguntas-frecuentes',
        icon: < IconQuestionMark size={50} className='ViewCard--icon' color='var(--color-white)'/>,
    }
]

const MarketingContextProvider = ({children}) => {
    const isMount = useIsMount();
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (!isMount) return

        const init = async () => {
            setIsReady(true);
        }

        init();
    }, []);

    const context = useMemo(
        () => ({
            MARKETING_ITEMS,
            isReady,
        }),
        [
            isReady,
        ]
    );
    return (
        <MarketingContext.Provider value={context}>{children}</MarketingContext.Provider>
    );
};
export default MarketingContextProvider;
