import React, {createRef, useContext, useState} from 'react';
import "./NewSalesPaymentsForm.css";
import {Button, Grid, TextInput} from "@mantine/core";
import MantineSwitchButton from "../../../../components/MantineSwitchButton/MantineSwitchButton";
import PhoneInputField from "../../../../components/PhoneInputField/PhoneInputField";
import MantineSelect from "../../../../components/MantineSelect/MantineSelect";
import AppContext from "../../../../contexts/AppContext";
import axios from "axios";
import showNotification from "../../../../utils/showNotification";

const PAYMENT_METHODS = [
    {value: "paypal", label: "PayPal"},
    {value: "transfer", label: "Transferencia Bancaria"},
    {value: "pagos360", label: "Pagos360"},
];

const CURRENCIES = [
    {value: "USD", label: "USD"},
    {value: "ARS", label: "ARS"},
]

function NewSalesPaymentsForm(props) {
    const {form, loading} = props;
    const {academicUnits} = useContext(AppContext);
    const ref = createRef();
    const [loadingDiscount, setLoadingDiscount] = useState(false)

    const handleApplyDiscount = async (_discountCode) => {
        try {
            setLoadingDiscount(true);

            const discountResponse = await axios.post(
                `${process.env.REACT_APP_BACKEND_SERVER}info/checkDiscountCode`,
                {code: _discountCode}
            );

            const discountInfoData = discountResponse.data.data;

            if (discountInfoData && discountInfoData.active) {
                form.setFieldValue("discount", discountInfoData._id);
                form.setFieldValue("discountPercentage", discountInfoData.percentage);
            } else {
                showNotification({
                    color: "yellow",
                    status: "warning",
                    title: "El descuento no es válido.",
                    message: `Detalle: Parece que el descuento no existe o ya fue aplicado.`,
                    autoClose: 12000
                });
            }
        } catch (error) {
            console.error("Error fetching discount data:", error);
            showNotification({
                color: "red",
                status: "error",
                title: "Ha ocurrido un problema al aplicar el descuento.",
                message: `Detalle: ${error.message}`,
                autoClose: 12000
            });
        } finally {
            setLoadingDiscount(false);
        }
    };

    const getUserForm = (_isAlreadyUser) => {
        if (_isAlreadyUser) {
            return (
                <>
                    <Grid.Col span={6}>
                        <TextInput
                            label="Email"
                            {...form.getInputProps("email")}
                            onBlur={(event) =>
                                form.setFieldValue("email", event.currentTarget.value)
                            }
                            withAsterisk
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <TextInput
                            label="Confirmación de email"
                            {...form.getInputProps("emailConfirmation")}
                            onBlur={(event) =>
                                form.setFieldValue("emailConfirmation", event.currentTarget.value)
                            }
                            withAsterisk
                        />
                    </Grid.Col>
                </>
            );
        } else {
            return (
                <>
                    <Grid.Col span={6}>
                        <TextInput label="Nombre" {...form.getInputProps("name")} w={"100%"}
                                   withAsterisk/>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <TextInput label="Apellido" {...form.getInputProps("lastname")} w={"100%"}
                                   withAsterisk/>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <TextInput
                            label="Email"
                            {...form.getInputProps("email")}
                            onBlur={(event) =>
                                form.setFieldValue("email", event.currentTarget.value)
                            }
                            withAsterisk
                        />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <TextInput
                            label="Confirmación de email"
                            {...form.getInputProps("emailConfirmation")}
                            onBlur={(event) =>
                                form.setFieldValue("emailConfirmation", event.currentTarget.value)
                            }
                            withAsterisk
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <PhoneInputField
                            ref={ref}
                            name="phone"
                            fieldError={form.errors.phone}
                            {...form.getInputProps("phone")}
                            onChange={(value) => {
                                form.setFieldValue("phone", value)
                            }}
                            withAsterisk
                        />
                    </Grid.Col>
                    {/*<Grid.Col span={12}>*/}
                    {/*    <TextInput label="DNI" {...form.getInputProps("DNI")} w={"100%"}/>*/}
                    {/*</Grid.Col>*/}
                </>
            )
        }
    }

    return (
        <Grid style={{width: "100%"}} justify="center" align="center">
            <Grid.Col span={12} justify="center" align="center">
                <MantineSwitchButton
                    styles={{
                        root: {
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                        }
                    }}
                    checked={form.values.alreadyUser}
                    onChange={() =>
                        form.setFieldValue("alreadyUser", !form.values.alreadyUser)
                    }
                    label="El usuario ya existe en FORVET"
                />
            </Grid.Col>
            {getUserForm(form.values.alreadyUser)}
            <Grid.Col span={12}>
                <MantineSelect
                    form={form}
                    name="academicUnitId"
                    label="Unidad académica"
                    description="Selecciona la capacitación a la que corresponde la inscripción"
                    options={academicUnits}
                    withAsterisk
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <TextInput
                    label="Código de descuento"
                    withAsterisk
                    placeholder="COVETSALT-AEEC-D7SN"
                    {...form.getInputProps("discount")}
                    w={"100%"}
                />
            </Grid.Col>
            <Grid.Col span={2}>
                <Button
                    type="button"
                    className="eventCalendarForm__form__button"
                    loading={loadingDiscount}
                    onClick={() => handleApplyDiscount(form.values.discount)}
                    w={"100%"}
                >
                    Validar
                </Button>
            </Grid.Col>
            <Grid.Col span={4}>
                <TextInput
                    disabled
                    label="Porcentaje descuento"
                    {...form.getInputProps("discountPercentage")}
                    w={"100%"}
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <MantineSelect
                    form={form}
                    name="paymentMethod"
                    label="Plataforma de pago"
                    options={PAYMENT_METHODS}
                    withAsterisk
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <MantineSelect
                    form={form}
                    name="currency"
                    label="Moneda"
                    options={CURRENCIES}
                    withAsterisk
                />
            </Grid.Col>
            <Grid.Col span={12}>
                <Button
                    type="submit"
                    className="eventCalendarForm__form__button"
                    loading={loading}
                >
                    Guardar inscripción
                </Button>
            </Grid.Col>
        </Grid>
    );
}

export default NewSalesPaymentsForm;