import './ModerationHome.css'
import { IconSchool } from '@tabler/icons-react'
import React from 'react'
import ViewCard from '../../../components/ViewCard/ViewCard'
import ModerationDashboard from '../ModerationDashboard/ModerationDashboard'

// add here
const MODERATION_ITEMS = [
   {
      title: 'Estructuras',
      text: 'Ver clases por unidad académica. Editar moderadores y links de zoom.',
      href: 'estructuras',
      icon: < IconSchool size={50} className='ViewCard--icon' color='var(--color-white)' />,
   },
   {
      title: 'Clases',
      text: 'Buscar clases segun fecha, unidades académicas o profesores.',
      href: 'clases',
      icon: < IconSchool size={50} className='ViewCard--icon' color='var(--color-white)' />,
   },
]

export default function ModerationHome() {
   return (
      <section className='ModerationHome-main-container'>
         <div className='ModerationHome-grid-container'>
            {MODERATION_ITEMS.map(item => (
               < ViewCard item={item} />
            ))}
         </div>
         <ModerationDashboard/>
      </section>
   )
}