import {Table, Tooltip} from "@mantine/core";
import {currencyFormat} from "../../../utils/currencyFormat";
import {calculateAmountValues} from "../../../utils/calculateAmountValues";
import {IconInfoCircleFilled} from "@tabler/icons-react";

export function AdministrativePaymentsTablePreview({rowData}) {
    const {details, payer, payment, installments} = rowData.rowData;
    const {type: paymentType, bankTransferAccount} = payment;
    const academicUnitName = details[0].description;
    const {amountAfterDiscount} = calculateAmountValues(rowData.rowData);
    const isInstallment = installments.length > 0;

    return (
        <Table.ScrollContainer minWidth={800}>
            <Table verticalSpacing="xs">
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>Capacitación</Table.Th>
                        <Table.Th>Cliente</Table.Th>
                        <Table.Th>Importe inicial</Table.Th>
                        <Table.Th>Moneda</Table.Th>
                        <Table.Th>¿Es cuota?</Table.Th>
                        <Table.Th>Plataforma</Table.Th>
                        <Table.Th>Cuenta</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                    <Table.Tr>
                        <Table.Td>{academicUnitName}</Table.Td>
                        <Table.Td>{`${payer.name} ${payer.lastname}`}</Table.Td>
                        <Table.Td>
                            {`$${currencyFormat(amountAfterDiscount)}`}
                            <Tooltip label="Este importe corresponde al monto abonado por el cliente en la plataforma, luego del descuento otorgado por ventas"
                                     multiline
                                     w={304}
                                     color="rgba(54,54,54,1)">
                                <IconInfoCircleFilled size={16} className='TableContent-thead--icon'/>
                            </Tooltip>
                        </Table.Td>
                        <Table.Td>{payment.currency}</Table.Td>
                        <Table.Td>{isInstallment ? "Si" : "No"}</Table.Td>
                        <Table.Td>{paymentType}</Table.Td>
                        <Table.Td>{bankTransferAccount}</Table.Td>
                    </Table.Tr>
                </Table.Tbody>
            </Table>
        </Table.ScrollContainer>
    );
}
