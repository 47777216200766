import React from "react";
import { createColumnHelper } from "@tanstack/react-table";
import TanStackTable from "../../../../components/Table/TanStackTable/TanStackTable";
import "./TeachersTable.css";
import { Badge, Button } from "@mantine/core";

const TeachersTable = ({ data, reviewsCount, onBack, unitName }) => {
  // Calcular la suma de cada columna
  const summary = data.reduce(
    (acc, teacher) => {
      acc.good += teacher.good;
      acc.average += teacher.average;
      acc.bad += teacher.bad;
      acc.notRated += teacher.notRated;
      acc.totalScore += teacher.totalScore - teacher.notRated;
      return acc;
    },
    { good: 0, average: 0, bad: 0, notRated: 0, totalScore: 0 }
  );

  const averageSummary =
    ((summary.good + summary.average * 0.5 + summary.bad * 0) /
      summary.totalScore) *
    100;

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: "Profesor",
      enableColumnFilter: true,
      enableSorting: false,
      filter: "text",
    }),
    columnHelper.accessor("good", {
      cell: (info) => info.getValue(),
      header: "Buena",
      enableColumnFilter: false,
      enableSorting: true,
    }),
    columnHelper.accessor("average", {
      cell: (info) => info.getValue(),
      header: "Regular",
      enableColumnFilter: false,
      enableSorting: true,
      filter: "text",
    }),
    columnHelper.accessor("bad", {
      cell: (info) => info.getValue(),
      header: "Mala",
      enableColumnFilter: false,
      enableSorting: true,
      filter: "text",
    }),
    columnHelper.accessor("notRated", {
      cell: (info) => info.getValue(),
      header: "No valorado",
      enableColumnFilter: false,
      enableSorting: true,
      filter: "text",
    }),
    columnHelper.accessor("averageScore", {
      cell: (info) => {
        const value = info.getValue().toFixed(2) + "%";
        const calculateBadge =
          parseFloat(info.getValue()) >= 95
            ? "teachersTable-greenBadge"
            : parseFloat(info.getValue()) <= 60
            ? "teachersTable-redBadge"
            : parseFloat(info.getValue()) >= 50 &&
              parseFloat(info.getValue()) <= 70
            ? "teachersTable-orangeBadge"
            : "teachersTable-yellowBadge";
        return (
          <div className="teachersTable-divBadge">
            {" "}
            <Badge classNames={{ root: calculateBadge }}>{value}</Badge>{" "}
          </div>
        );
      },
      header: "Promedio por Profesor",
      enableColumnFilter: false,
      enableSorting: true,
      filter: "text",
    }),
  ];

  // Añadir la fila de resumen a los datos
  const dataWithSummary = [
    ...data,
    {
      name: "Total de todos los profesores",
      good: summary.good,
      average: summary.average,
      bad: summary.bad,
      notRated: summary.notRated,
      averageScore: averageSummary,
    },
  ];

  return (
    <div className="teachersTable-mainDiv">
      <div className="teacherTable-headerDiv">
        <div className="teacherTable-titlesDiv">
          <h3 className="teachersTable-mainHeader">
            Calificación de los profesores del curso
          </h3>
          <h6 className="teachersTable-headerQuantity-acaUnitName">
            <Badge
              classNames={{
                root: "teachersTable-headerQuantity-acaUnitName-badge",
              }}
            >
              {unitName}
            </Badge>
          </h6>
          <h5 className="teachersTable-headerQuantity">
            Total de alumnos que valoraron la U.A: {reviewsCount}
          </h5>
        </div>
        <Button
          classNames={{ root: "teachersTable-buttonRoot" }}
          onClick={onBack}
          w={"max-content"}
          variant="outline"
          color="var(--color-tertiary)"
        >
          Volver atrás
        </Button>
      </div>

      <TanStackTable
        data={dataWithSummary}
        columns={columns}
        withPagination={true}
      />
    </div>
  );
};

export default TeachersTable;
