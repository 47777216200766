import React from 'react';
import "./AdministrativeCommissionsModal.css";
import {currencyFormat} from "../../../utils/currencyFormat";
import {calculateAmountValues} from "../../../utils/calculateAmountValues";

function AdministrativeCommissionsModal(props) {
    const {rowData} = props;
    const {
        initialAmount,
        discountAmount,
        amountAfterDiscount,
        percentageVariationDiscount,
        feeAmount,
        amountAfterDiscountAndFee,
        percentageVariationDiscountAndFee,
        amountCommission,
        percentageVariationCommission,
        finalAmount,
        percentageVariationFinal
    } = calculateAmountValues(rowData);

    const data = [
        {concept: 'Importe inicial', amount: initialAmount, finalAmount: initialAmount, percentageVariation: 0},
        {concept: 'Descuento', amount:discountAmount, finalAmount: amountAfterDiscount, percentageVariation: percentageVariationDiscount},
        {concept: 'Comisión plataforma', amount: feeAmount, finalAmount: amountAfterDiscountAndFee, percentageVariation: percentageVariationDiscountAndFee},
        {concept: 'Comisión vendedor', amount: amountCommission, finalAmount: finalAmount, percentageVariation: percentageVariationCommission},
    ];
    return <div className="administrativeCommissionsModal__container">
        <table className="administrativeCommissionsModal__table">
            <thead>
            <tr>
                <th className="administrativeCommissionsModal__table__head">Concepto</th>
                <th className="administrativeCommissionsModal__table__head">Importe</th>
                <th className="administrativeCommissionsModal__table__head">Importe
                    resultante
                </th>
                <th className="administrativeCommissionsModal__table__head">Variación</th>
            </tr>
            </thead>
            <tbody>
            {data.map((row, index) => (
                <tr key={index}>
                    <td className="administrativeCommissionsModal__table__row">{row.concept}</td>
                    <td className="administrativeCommissionsModal__table__row">${currencyFormat(row.amount)}</td>
                    <td className="administrativeCommissionsModal__table__row">${currencyFormat(row.finalAmount)}</td>
                    <td className="administrativeCommissionsModal__table__row">{row.percentageVariation}%</td>
                </tr>
            ))}
            </tbody>
        </table>
        <p className="administrativeCommissionsModal__text">Importe final: <span>${currencyFormat(finalAmount)}</span></p>
        <p className="administrativeCommissionsModal__text">Variación total: <span>{percentageVariationFinal}%</span></p>
        <p className="administrativeCommissionsModal__text">Moneda: <span>{rowData.payment.currency}</span></p>
    </div>
}

export default AdministrativeCommissionsModal;