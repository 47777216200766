import moment from "moment";
import { useState } from "react";


export default function useQuestionnaires({initialValues, academicUnitName, isLoading}) {

   const [questions, setQuestions] = useState(initialValues.questions);
   const [activePage, setPage] = useState(1);
   const [confirmModal, setConfirmModal] = useState(false)
   const [questionsModal, setQuestionsModal] = useState(null)

   const handleAddQuestion = () => {
      setQuestions(prevQuestions => [
         ...prevQuestions,
         {
            title: initialValues.title || `PREGUNTA ${questions.length + 1} - ${academicUnitName.toUpperCase()} - ${moment().format('MMMM - YYYY')}`,
            questionText: '',
            responseOptions: [{ label: '', value: 1 }],
            trueOption: 1
         }
      ]);
      setPage(prevPage => prevPage + 1)
   };

   const handleAddQuestionFromDB = (newQuestion) => {
      setQuestions(prevQuestions => [
         ...prevQuestions, { ...newQuestion }
      ])
      setPage(prevPage => prevPage + 1)
   }

   const handleQuestionChange = (e, index) => {
      const newQuestions = [...questions];
      newQuestions[index].questionText = e.target.value;
      setQuestions(newQuestions);
   };

   const handleDeleteQuestion = (questionIndex) => {
      const newQuestions = questions.filter((_, index) => index !== questionIndex);
      setQuestions(newQuestions);
      setPage(prevPage => {
         if (prevPage === 1) return 1
         return prevPage - 1
      })
   };

   const moveQuestionBack = () => {
      if (activePage > 1) {
         const index = activePage - 1;
         const newQuestions = [...questions];
         const movedQuestion = newQuestions.splice(index, 1)[0]
         newQuestions.splice(index - 1, 0, movedQuestion)
         setQuestions(newQuestions);
         setPage(index);
      }
   };

   const moveQuestionForward = () => {
      if (activePage < questions.length) {
         const index = activePage - 1;
         const newQuestions = [...questions];
         const movedQuestion = newQuestions.splice(index, 1)[0]
         newQuestions.splice(index + 1, 0, movedQuestion)
         setQuestions(newQuestions);
         setPage(index + 2)
      }
   };

   const isDisabledQuestionForwardBtn = () => {
      return activePage === questions.length
   }

   const isDisabledQuestionBackBtn = () => {
      return activePage === 1
   }

   const handleCheckboxChange = (e, questionIndex) => {
      const newQuestions = [...questions];
      newQuestions[questionIndex].trueOption = Number(e.target.value)
      setQuestions(newQuestions);
   };

   const handleAnswerChange = (e, questionIndex, answerIndex) => {
      const newQuestions = [...questions];
      newQuestions[questionIndex].responseOptions[answerIndex].label = e.target.value;
      setQuestions(newQuestions);
   };

   const handleAddAnswer = (questionIndex) => {
      const newQuestions = [...questions];
      newQuestions[questionIndex].responseOptions.push({ label: '', value: newQuestions[questionIndex].responseOptions.length + 1 });
      setQuestions(newQuestions);
   };

   const handleDeleteAnswer = (questionIndex, answerIndex) => {
      const newQuestions = [...questions];
      newQuestions[questionIndex].responseOptions = newQuestions[questionIndex].responseOptions.filter((_, index) => index !== answerIndex);

      if (newQuestions[questionIndex].responseOptions.length < newQuestions[questionIndex].trueOption) {
         // puede ser igual al maximo de preguntas que hay
         // newQuestions[questionIndex].trueOption = newQuestions[questionIndex].responseOptions.length
         newQuestions[questionIndex].trueOption = null
      }

      newQuestions[questionIndex].responseOptions.forEach((answer, index) => {
         answer.value = index + 1;
      });
      setQuestions(newQuestions);
   };

   const isCreateBtnDisabled = () => {
      const notAnswerCoincidence = questions.some(question => {
         return !question.responseOptions.some(option => option.value === question.trueOption)
      })
      const notSomeAnswerEmpty = questions.some(question => {
         return question.responseOptions.some(res => !res.label)
      })
      return (notAnswerCoincidence || notSomeAnswerEmpty || isLoading)
   }

   const isAddQuestionBtnDisabled = () => {
      return (
         !questions[activePage - 1].trueOption
         || !questions[activePage - 1].questionText
         || questions[activePage - 1].responseOptions.some(option => !option.label)
         || isLoading
      )
   }

   return {
      questions,
      activePage,
      setPage,
      confirmModal, 
      setConfirmModal,
      questionsModal, 
      setQuestionsModal,
      handleAddQuestion, 
      handleAddQuestionFromDB, 
      handleQuestionChange,
      handleDeleteQuestion,
      moveQuestionBack,
      moveQuestionForward,
      isDisabledQuestionForwardBtn,
      isDisabledQuestionBackBtn,
      handleCheckboxChange,
      handleAnswerChange,
      handleAddAnswer,
      handleDeleteAnswer,
      isCreateBtnDisabled,
      isAddQuestionBtnDisabled
   }
}
