import React, {useContext} from 'react';
import MarketingContextProvider from './MarketingContextProvider';
import MarketingContext from './MarketingContext.js';
import {Loader} from "@mantine/core";

/**
 * Inserts UserContextProvider in the react tree
 * before adding the given component.
 *
 * @param {Object} Component The component that needs the context provider.
 * @returns {Object} The component to render wrapped with the provider.
 */
const withMarketingContextProvider = (Component) => (props) => (
    <MarketingContextProvider>
        <Component {...props} />
    </MarketingContextProvider>
);

/**
 * Renders the given component when the user context is ready.
 *
 * @param {Object} Component The component to render.
 * @param {Object} Component The rendered component.
 * @returns {Object} The component to render or the LoadingPage component.
 */
const withMarketingContextReady = (Component) => (props) => {

    const {isReady} = useContext(MarketingContext);
    return isReady ? <Component {...props} /> :
        <div style={{height: "100%", minHeight: "100vh", display: "flex", alignItems: "center"}}>
            <Loader/>
        </div>
};

export {
    withMarketingContextReady,
    withMarketingContextProvider,
};