import "./NewsCard.css";
import { Button } from "@mantine/core";
import useButtonSize from "../../hooks/useButtonSize";
import { openUrl } from "../../utils/openUrl";

const NewsCard = ({ title, route, image, description, buttonText, active }) => {
   
   const buttonSize = useButtonSize();
   return (
      <div className={`NewsCard__wrapper NewsCard__wrapper--${active}`} style={{ background: `no-repeat center/100% url(${image})`, backgroundSize: 'cover' }}>
         <div className="NewsCard__container">
            <h2 className="NewsCard__title">{title}</h2>
            <p className="NewsCard__text">{description}</p>

            <div className="NewsCard__button">
               <Button variant="filled"
                  fw='normal'
                  size={buttonSize}
                  onClick={() => openUrl(route)}
               >
                  {buttonText}
               </Button>
            </div>
         </div>
      </div>
   );
};
export default NewsCard;
