import {Flex, Group, Stack, Switch} from '@mantine/core'
import {
    Icon360,
    IconArrowsExchange,
    IconBrandPaypal,
    IconCashBanknote,
    IconCoins,
    IconCreditCardPay,
    IconPlus,
    IconTrendingDown,
    IconTrendingUp,
} from '@tabler/icons-react'
import {LineChart} from '@mantine/charts';
import {currencyFormat} from '../../../../utils/currencyFormat';
import {useState} from "react";

export default function AdDasboardIncomes({data}) {

    const mapChartMonth = {
        0: 'Enero',
        1: 'Febrero',
        2: 'Marzo',
        3: 'Abril',
        4: 'Mayo',
        5: 'Junio',
        6: 'Julio',
        7: 'Agosto',
        8: 'Septiembre',
        9: 'Octubre',
        10: 'Noviembre',
        11: 'Diciembre'
    }

    const [activeCurrency, setActiveCurrency] = useState("ARS");
    const [currencySwitch, setCurrencySwitch] = useState(false);
    const handleChangeCurrency = (active) => {
        if (active) {
            setActiveCurrency("USD")
            setCurrencySwitch(true)
        } else {
            setActiveCurrency("ARS")
            setCurrencySwitch(false)
        }
    }
    const incomesByPlatform = [
        {
            platformName: "Pagos360",
            USDEarnings: parseInt(data?.USD?.totalPerPlataform?.pagos360) || 0,
            ARSEarnings: parseInt(data?.ARS?.totalPerPlataform?.pagos360) || 0,
            icon: <Icon360/>,
        },
        {
            platformName: "Paypal",
            USDEarnings: parseInt(data?.USD?.totalPerPlataform?.paypal) || 0,
            ARSEarnings: parseInt(data?.ARS?.totalPerPlataform?.paypal) || 0,
            icon: <IconBrandPaypal/>,
        },
        {
            platformName: "Transferencia",
            USDEarnings: parseInt(data?.USD?.totalPerPlataform?.transferencia) || 0,
            ARSEarnings: parseInt(data?.ARS?.totalPerPlataform?.transferencia) || 0,
            icon: <IconCreditCardPay/>,
        },
    ].sort((a, b) => b.USDEarnings - a.USDEarnings);

    return (
        <section className='AdministrativeDashboard-section-container'>
            <Group justify="space-between">
                <h3 className='AdministrativeDashboard-titleSection'>Ingresos</h3>
                <Switch
                    size="xl" onLabel="USD" offLabel="ARS"
                    styles={{
                        track: {backgroundColor: 'yellowgreen', borderColor: 'yellowgreen'},
                        trackLabel: {color: 'white'},
                    }}
                    checked={currencySwitch}
                    onChange={(event) => handleChangeCurrency(event.currentTarget.checked)}
                />

                <Flex justify='space-around'>
                    <Stack bg='var(--color-base-light)' h='max-content' p={10}
                           style={{borderRadius: 'var(--border-radius-md)', boxShadow: 'var(--box-shadow)'}}
                    >
                        <Group justify='space-between'>
                            <Group align='center'>
                                <Group gap='0' align='center'>
                                    < IconPlus size={14}/>
                                    <IconCashBanknote/>
                                </Group>
                                <span className='AdministrativeDashboard-card-bodyText'>
                              Ingresos este mes:
                           </span>
                            </Group>
                            <Group>

                           <span style={{
                               backgroundColor: '#4fa04fff',
                               color: '#fff',
                               fontSize: 'var(--fs-tiny)',
                               padding: '2px 8px',
                               borderRadius: 'var(--border-radius-md)',
                               fontWeight: 'bold'
                           }}>${currencyFormat(data[activeCurrency].thisMonth)}</span>
                            </Group>
                        </Group>
                        <Group justify='space-between'>
                            <Group align='center'>
                                <Group gap='0' align='center'>
                                    < IconPlus size={14}/>
                                    <IconCoins/>
                                </Group>
                                <span className='AdministrativeDashboard-card-bodyText'>
                              Ingreso total:
                           </span>
                            </Group>
                            <Group>
                           <span style={{
                               backgroundColor: '#4fa04fff',
                               color: '#fff',
                               fontSize: 'var(--fs-tiny)',
                               padding: '2px 8px',
                               borderRadius: 'var(--border-radius-md)',
                               fontWeight: 'bold'
                           }}>${currencyFormat(data[activeCurrency].total)}</span>
                            </Group>
                        </Group>
                    </Stack>
                </Flex>
            </Group>
            {data &&
            <Flex w='100%' direction='column' gap='30px'>
                <div className='AdministrativeDashboard-gridContainer'>
                    <article className='AdministrativeDashboard-card'>
                        <Group>
                            <Group gap='0'>
                                < IconCashBanknote/>
                                < IconTrendingUp/>
                            </Group>
                            <h3 className='AdministrativeDashboard-card-title'>Mayor ingreso</h3>
                        </Group>
                        <Stack className='AdministrativeDashboard-stack-wrapper'>
                            {data.threeHigherIncomeCourses.map(item => (
                                <Flex className='AdministrativeDashboard-card-rowItem' align='start'
                                      justify='space-between' w='100%' gap='5px' key={item._id}>

                                    <p className='AdministrativeDashboard-card-bodyText'>{item._id}</p>
                                    <span style={{
                                        backgroundColor: '#4fa04fff',
                                        color: '#fff',
                                        fontSize: 'var(--fs-tiny)',
                                        padding: '2px 8px',
                                        borderRadius: 'var(--border-radius-md)',
                                        fontWeight: 'bold'
                                    }}>
                                   ${currencyFormat(item[`totalEarnings${activeCurrency}`])}
                                </span>
                                </Flex>))}
                        </Stack>
                    </article>

                    <article className='AdministrativeDashboard-card'>
                        <Group>
                            <Group gap='0'>
                                < IconCashBanknote/>
                                < IconTrendingDown/>
                            </Group>
                            <h3 className='AdministrativeDashboard-card-title'>Menor ingreso</h3>
                        </Group>
                        <Stack className='AdministrativeDashboard-stack-wrapper'>
                            {data.threeLowerIncomeCourses.map(item => (
                                <Flex className='AdministrativeDashboard-card-rowItem' justify='space-between'
                                      align='start' w='100%' gap='5px' key={item._id}>
                                    <p className='AdministrativeDashboard-card-bodyText'>{item._id}</p>
                                    <span style={{
                                        backgroundColor: '#a04f4fff',
                                        color: '#fff',
                                        fontSize: 'var(--fs-tiny)',
                                        padding: '2px 8px',
                                        borderRadius: 'var(--border-radius-md)',
                                        fontWeight: 'bold'
                                    }}>
                                   ${currencyFormat(item[`totalEarnings${activeCurrency}`])}
                                </span>
                                </Flex>))}
                        </Stack>
                    </article>

                    <article className='AdministrativeDashboard-card'>
                        <Group>
                            < IconArrowsExchange/>
                            <h3 className='AdministrativeDashboard-card-title'>Por medio de pago</h3>
                        </Group>
                        <Stack className='AdministrativeDashboard-stack-wrapper'>
                            {incomesByPlatform &&
                            incomesByPlatform?.map(item => (
                                <Flex className='AdministrativeDashboard-card-rowItem' align='start' w='100%' gap='5px'
                                      justify='space-between'>
                                    <Group>
                                        {item.icon}
                                        <h4 className='AdministrativeDashboard-card-bodyText'
                                            style={{fontWeight: '400'}}>{item.platformName}</h4>
                                    </Group>
                                    <span style={{
                                        backgroundColor: '#4fa04fff',
                                        color: '#fff',
                                        fontSize: 'var(--fs-tiny)',
                                        padding: '2px 8px',
                                        borderRadius: 'var(--border-radius-md)',
                                        fontWeight: 'bold'
                                    }}>
                                   ${currencyFormat(item[`${activeCurrency}Earnings`])} {activeCurrency}
                                 </span>
                                </Flex>
                            ))
                            }
                        </Stack>
                    </article>
                </div>

                {/* graph */}
                <Flex direction={{base: 'column', sm: 'row'}} w='100%'
                      justify={{base: 'unset', sm: 'space-around'}}>

                    <Stack
                        p={10}
                        w='100%'
                        align='center'
                        bg='var(--color-base-light)'
                        style={{
                            boxShadow: 'var(--box-shadow)',
                            borderRadius: 'var(--border-radius-md)'
                        }}
                    >
                        <Group>
                            < IconArrowsExchange/>
                            <h3 className='AdministrativeDashboard-card-title'>Pagos mensuales</h3>
                        </Group>
                        <LineChart
                            h={{base: '100%', sm: 200}}
                            w={{base: '90%', sm: '90%'}}
                            m='0 auto'
                            data={
                                Object.values(data[activeCurrency].paymentsPerMonth)?.map((item, idx) => {
                                    if (idx > 11) return null
                                    return {
                                        month: mapChartMonth[idx],
                                        Pagos: item
                                    }
                                })
                            }
                            dataKey="month"
                            series={[
                                {name: 'Pagos', color: '#489151'},
                            ]}
                            valueFormatter={(value) => currencyFormat(value)}
                            curveType="linear"
                        />
                    </Stack>

                </Flex>
            </Flex>
            }
        </section>
    )
}
