import React, {useState} from 'react';
import "./AcademicUnitForm.css";
import TabButton from "../../../components/TabButton/TabButton";
import {IconBriefcase2, IconCoin, IconUsers, IconSchool, IconListDetails} from '@tabler/icons-react'
import {CommercialInformationModel} from "./Steps/CommercialInformation/CommercialInformation.Model";
import {LessonsModel} from "./Steps/Lessons/Lessons.Model";
import {PricingModel} from "./Steps/Pricing/Pricing.Model";
import {UsersModel} from "./Steps/Users/Users.Model";
import {ExamModel} from "./Steps/Exam/Exam.Model";
import CommercialInformationForm from "./Steps/CommercialInformation/CommercialInformation.Form";
import LessonsForm from "./Steps/Lessons/Lessons.Form";
import PricingForm from "./Steps/Pricing/Pricing.Form";
import UsersForm from "./Steps/Users/Users.Form";
import ExamForm from "./Steps/Exam/Exam.Form";

import {useForm} from "@mantine/form";
import { yupResolver } from 'mantine-form-yup-resolver';
import {Button} from "@mantine/core";
import AcademicUnitModals from "./Modals/AcademicUnitModals";
import {convertDate} from "../../../utils/convertDate";
import {convertArrayToDictionary} from "../../../utils/convertArrayToDictionary";
import * as yup from "yup";
import moment from "moment";
import showNotification from "../../../utils/showNotification";

const convertInitialValues = (_data) => {
    if (!_data) return null;
    return {
        "_id": _data._id || "",
        "type": _data.type || "",
        "name": _data.comercialInformation.name || "",
        "description": _data.comercialInformation.description || "",
        "initialDate": convertDate(_data.comercialInformation.initialDate, "date") || null,
        "endDate": convertDate(_data.comercialInformation.endDate, "date") || null,
        "closeCampusDate": convertDate(_data.closeCampusDate, "date") || null,
        "specialties": _data.specialties || [],
        "initBreak": convertDate(_data.comercialInformation.initBreak, "date") || null,
        "endBreak": convertDate(_data.comercialInformation.endBreak, "date") || null,
        "classQuantity": _data.comercialInformation.classQuantity || 1,
        "image": _data.comercialInformation.image || "",
        "classDays": _data.comercialInformation.classDays || "",
        "classHour": _data.comercialInformation.classHour || "",
        "selectedModule": "",
        "modules": convertArrayToDictionary(_data.modules, []),
        "selectedLesson": "",
        "price": {
            "usd": _data.comercialInformation.price.usd || 0,
            "ars": _data.comercialInformation.price.ars || 0
        },
        "comercialInformation": _data.comercialInformation || {},
        "subscriptionPlans": _data.comercialInformation.subscriptionPlans || [],
        "director": _data.director || [],
        "codirector": _data.codirector || [],
        "coordinator": _data.coordinator || "",
        "teachers": _data.teachers || [],
        "exam": _data.exams && _data.exams[0] ? {
            // Coupled with ExamModel initial values
            initDate: moment(_data.exams[0].initDate).toDate(),
            endDate: moment(_data.exams[0].endDate).toDate(),
            duration: _data.exams[0].duration,
            approvalPercentage:     _data.exams[0].approvalPercentage,
            academicUnit: _data.exams[0].academicUnit, // Review
            questionary: _data.exams[0].questionary,
            recoveryExam: _data.exams[0].recoveryExam,
            tryQuantity: _data.exams[0].tryQuantity
        } : {},
    };
}

const tabs = [
    {
        icon: IconBriefcase2,
        text: 'Información comercial',
        step: 0,
    },
    {
        icon: IconCoin,
        text: 'Precios',
        step: 1,
    },
    {
        icon: IconUsers,
        text: 'Usuarios',
        step: 2,
    },
    {
        icon: IconSchool,
        text: 'Módulos y clases',
        step: 3,
    },
    {
        icon: IconListDetails,
        text: 'Exámenes',
        step: 4,
    },
];

const renderStepContent = ({currentStep, form, setCreateSubscriptionModalOpen, setCreateModuleModal, setCreateLessonModal, setCreateUserModal, setCreateQuestionnaireModal, initialValues, setEditLessonModal}) => {
    switch (currentStep) {
        case 0:
            return <CommercialInformationForm form={form}/>;
        case 1:
            return <PricingForm form={form} setCreateSubscriptionModalOpen={setCreateSubscriptionModalOpen}/>;
        case 2:
            return <UsersForm form={form} setCreateUserModal={setCreateUserModal}/>;
        case 3:
            return <LessonsForm form={form} setCreateModuleModal={setCreateModuleModal}
                                setCreateLessonModal={setCreateLessonModal}
                                setEditLessonModal={setEditLessonModal}
                                isCreateNew={!initialValues}/>;
        case 4:
            return <ExamForm form={form} setCreateQuestionnaireModal={setCreateQuestionnaireModal}/>;
        default:
            return null;
    }
}

const formInitializations = () => {
    const {
        initialValues: commercialInformationInitialValues,
        validationSchema: commercialInformationValidationSchema
    } = CommercialInformationModel();
    const {
        initialValues: lessonsInitialValues,
        validationSchema: lessonsValidationSchema
    } = LessonsModel();
    const {
        initialValues: pricingInitialValues,
        validationSchema: pricingValidationSchema
    } = PricingModel();
    const {
        initialValues: usersInitialValues,
        validationSchema: usersValidationSchema
    } = UsersModel();
    const {
        initialValues: examInitialValues,
        validationSchema: examValidationSchema
    } = ExamModel();

    const combinedValidationSchema = yup.object().shape({
        ...commercialInformationValidationSchema.fields,
        ...lessonsValidationSchema.fields,
        ...pricingValidationSchema.fields,
        ...usersValidationSchema.fields,
        ...examValidationSchema.fields,
    });
    return {
        initialValues: {
            ...commercialInformationInitialValues,
            ...lessonsInitialValues,
            ...pricingInitialValues,
            ...usersInitialValues,
            ...examInitialValues,
        },
        validate: yupResolver(combinedValidationSchema)
    }
}

function AcademicUnitForm(props) {
    const {initialValues, submitFunc, loading, onCancel} = props;
    const [currentStep, setCurrentStep] = useState(0);

    // Modals states
    const [createSubscriptionModalOpen, setCreateSubscriptionModalOpen] = useState(false);
    const [createModuleModal, setCreateModuleModal] = useState(false);
    const [createLessonModal, setCreateLessonModal] = useState(false);
    const [createUserModal, setCreateUserModal] = useState(false);
    const [createQuestionnaireModal, setCreateQuestionnaireModal] = useState(false);
    const [editLessonModal, setEditLessonModal] = useState(false);

    const formInit = formInitializations();
    const form = useForm({
        initialValues: convertInitialValues(initialValues) ?? formInit.initialValues,
        validate: formInit.validate,
    });

    return (
        <div className="academicUnitsForm__wrapper">
            <Button color='var(--color-tertiary)'
                    variant='outline' onClick={onCancel}
                    className="academicUnitsForm__button--cancel">
                Cancelar
            </Button>
            <form
                className="academicUnitsForm__form"
                onSubmit={
                    form.onSubmit(
                        (values) => submitFunc(values)
                    )
                }
            >
                <div className="academicUnitsForm__container">
                    <div className="academicUnitsForm__header">
                        {tabs.map((tab) => (
                            <TabButton icon={tab.icon} text={tab.text} active={currentStep === tab.step}
                                       onClick={() => setCurrentStep(tab.step)}/>
                        ))}
                    </div>
                    <div className="academicUnitsForm__body">
                        {renderStepContent(
                            {currentStep, form, setCreateSubscriptionModalOpen, setCreateModuleModal, setCreateLessonModal, setCreateQuestionnaireModal, setCreateUserModal, initialValues, setEditLessonModal})}
                    </div>
                </div>

                <Button
                    variant="filled" loading={false}
                    className="academicUnitsForm__button"
                    color="secondaryColor"
                    disabled={loading || !form.isValid}
                    type="submit"
                    onClick={() => {
                        if (Object.keys(form.errors).length > 0)
                            showNotification({
                                color: "red",
                                status: "error",
                                title: "Operación fallida.",
                                message: `Hay errores en los campos del formulario. Detalle: ${
                                    Object.keys(form.errors).map((key) => form.errors[key])
                                }`,
                                autoClose: 12000,
                            });
                    }}
                >
                    Guardar cambios
                </Button>
            </form>


            <AcademicUnitModals
                formHandler={form}
                createSubscriptionModalOpen={createSubscriptionModalOpen}
                setCreateSubscriptionModalOpen={setCreateSubscriptionModalOpen}
                createModuleModal={createModuleModal}
                setCreateModuleModal={setCreateModuleModal}
                createLessonModal={createLessonModal}
                setCreateLessonModal={setCreateLessonModal}
                createQuestionnaireModal={createQuestionnaireModal}
                setCreateQuestionnaireModal={setCreateQuestionnaireModal}
                editLessonModal={editLessonModal}
                setEditLessonModal={setEditLessonModal}
                createUserModal={createUserModal}
                setCreateUserModal={setCreateUserModal}
            />


        </div>
    );
}

export default AcademicUnitForm;