import { Flex, Skeleton } from '@mantine/core'
import React from 'react'

export default function AdDashboardIncomesSkeleton() {
   return (
      <section className='AdministrativeDashboard-section-container'>
         < Skeleton w='175px' h='30px' />
         <Flex w='100%' direction='column' gap='30px'>
            <div className='AdministrativeDashboard-gridContainer'>
               < Skeleton w='100%' h='300px' />
               < Skeleton w='100%' h='300px' />
               < Skeleton w='100%' h='300px' />
            </div>

            <Flex>
               < Skeleton h='200px' w='100%' />
            </Flex>

            <Flex w='100%' justify='center'>
               < Skeleton h='65px' w='255px' />
            </Flex>
         </Flex>
      </section>
   )
}
