import axios from "axios";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../../contexts/AuthContext";
import { createColumnHelper } from "@tanstack/table-core";
import showNotification from "../../../utils/showNotification";
import { ERROR_HANDLER } from "../../../utils/error-handler";

const ADVICES_URL = `${process.env.REACT_APP_BACKEND_SERVER}admin/marketing/advices`

export default function useMktgAdvicesView() {
   const { authToken } = useContext(AuthContext);
   const [isLoading, setIsLoading] = useState(false)
   const [displayForm, setDisplayForm] = useState(false);
   const [initialValuesEdit, setInitialValuesEdit] = useState(null);
   const [allAdvices, setAllAdvices] = useState(null)
   const [hasToRender, setHasToRender] = useState(false)
   const [deleteModalOpen, setDeleteModalOpen] = useState(false);
   const [selectedAdviceId, setSelectedAdviceId] = useState(null);

   const axiosConfig = {
      headers: {
         "Content-Type": "application/json",
         "Authorization": "Bearer " + authToken,
      }
   }

   useEffect(() => {
      const getAllAdvices = async () => {
         try {
            const url = `${process.env.REACT_APP_BACKEND_SERVER}campus/advices`
            const config = {
               headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${authToken}`
               },
            }

            const { data } = await axios.get(url, config)
            setAllAdvices(data)
         } catch (error) {
            console.error(error)
            showNotification({
               color: "red",
               status: "error",
               title: "Error al cargar los todos los consejos creados.",
               message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
               autoClose: 12000
            });
         }
      }
      getAllAdvices()
   }, [authToken, hasToRender]);

   const mapEventsType = {
      staff: 'Staff',
      director: 'Director',
      teacher: 'Profesor/a',
   }

   const columnHelper = createColumnHelper();
   const columns = [
      columnHelper.accessor('advice', {
         cell: info => info.getValue(),
         header: 'Consejo',
      }),
      columnHelper.accessor('user', {
         cell: info => `${info.getValue().name} ${info.getValue().lastname}`,
         header: 'Usuario',
      }),
      columnHelper.accessor('user.type', {
         cell: info => mapEventsType[info.getValue()],
         header: 'Tipo de usuario',
      }),
   ];

   const postAdvice = async (values, config) => {
      // TO DO: acomodar la data que se corresponda al endpoint
      const jsonData = {
         data: {
            ...values,
         }
      }

      // FALTA EL ENDPOINT
      return axios.post(
         ADVICES_URL,
         jsonData,
         config
      );
   }

   const putAdvice = async (values, config) => {
      const { _id, advice, user } = values
      const jsonData = {
         item: { _id },
         field: {
            advice,
            user
         }
      }
      return axios.put(
         ADVICES_URL,
         jsonData,
         config
      );
   }

   const editFunc = (rowData) => {
      if (rowData) {
         setInitialValuesEdit({
            _id: rowData._id,
            advice: rowData.advice,
            user: rowData.user._id,
         })
         setDisplayForm(true)
      }
   }

   const deleteAdvice = async (rowData) => {
      // Si el modal está abierto
      if (deleteModalOpen) {
         try {
            setIsLoading(true);
            await axios.delete(ADVICES_URL, {
               ...axiosConfig,
               data: { _id: selectedAdviceId }
            });
            setHasToRender(!hasToRender);
            showNotification({
               color: "green",
               status: "success",
               title: "Operación exitosa.",
               message: `Se ha eliminado el consejo correctamente.`,
               autoClose: 12000
            });
         } catch (error) {
            console.error(error);
            showNotification({
               color: "red",
               status: "error",
               title: "Operación fallida.",
               message: `No se pudo eliminar el consejo. Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
               autoClose: 12000
            });
         } finally {
            setIsLoading(false);
            setDeleteModalOpen(false);
         }
      } 
      // Si el modal no está abierto, lo abre
      else {
         setDeleteModalOpen(true);
         const adviceId = rowData?._id;
         if (!adviceId) return setDeleteModalOpen(false);
         setSelectedAdviceId(adviceId);
      }
   };
   

   const formValidation = {
      advice: (value) => {
         if (!value) return 'El consejo no puede estar vacío.'
         const isValid = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\s.,;:'"(){}[\]<>?¡¿]+$/.test(value)
         if (!isValid) return 'El mensaje no puede caracteres con símbolos extraños.'
         if (value.length > 250) return 'El mensaje debe ser menor o igual a 250 caracteres';
         return null
      },
      user: (value) => {
         if (!value) return 'No se ha seleccionado ningun usuario'
         if (typeof value !== 'string') return 'No esta llegando de forma correcta la carga del usuario'
         return null
      }
   }

   const handleSubmit = async (values) => {
      try {
         setIsLoading(true);
         // Check if it's an edit or a new event
         if (initialValuesEdit) {
            await putAdvice(values, axiosConfig)
         } else {
            await postAdvice(values, axiosConfig)
         }

         showNotification({
            color: 'green',
            status: 'success',
            title: '¡Excelente! Operación exitosa.',
            message: `El evento ${values.name} ha sido creado / editado con éxito.`,
         });
         setHasToRender(!hasToRender)

      } catch (error) {
         showNotification({
            color: "red",
            status: "error",
            title: "Operación fallida.",
            message: `No se pudo crear / editar el evento. Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
            autoClose: 12000
         });
      } finally {
         setIsLoading(false);
         setDisplayForm(false);
      }
   }

   return {
      data: allAdvices,
      columns,
      editFunc,
      deleteAdvice,
      initialValuesEdit,
      setInitialValuesEdit,
      displayForm,
      setDisplayForm,
      isLoading,
      handleSubmit,
      formValidation,
      deleteModalOpen,
      setDeleteModalOpen,
      selectedAdviceId,
      setSelectedAdviceId
   }
}
