import React from 'react';
import {Modal} from "@mantine/core";
import "./StudentModal.css";
import {openUrl} from "../../utils/openUrl";

function StudentModal(props) {
    const {open, setOpen, studentData} = props;
    const {name, lastname, email, phone} = studentData;
    const whatsappLink = `https://wa.me/${phone}`;
    return (
        <Modal
            classNames={{
                title: "studentModal__modalTitle",
                content: "studentModal__modalContent",
                header: "studentModal__modalHeader",
                close: "studentModal__modalClose",
            }}
            title="Datos del estudiante"
            opened={open}
            onClose={() => setOpen(false)}
        >
            <div className="studentModal__modal__fatherDiv">
                {name &&
                <p className="studentModal__userData__tags">
                    Nombre: <p> {name}</p>
                </p>
                }
                {lastname &&
                <p className="studentModal__userData__tags">
                    Apellido: <p> {lastname}</p>
                </p>
                }
                {email &&
                <p className="studentModal__userData__tags">
                    Email: <p> {email}</p>
                </p>
                }
                {phone &&
                <p className="studentModal__userData__tags">
                    Teléfono: <span className="studentModal__userData__link" onClick={() => openUrl(whatsappLink)}>{phone}</span>
                </p>
                }
            </div>
        </Modal>
    );
}

export default StudentModal;