import ContentCreator from '../../../../components/ContentCreator/ContentCreator'
import DailyRecommendationCard from '../../../../components/DailyRecommendationCard/DailyRecommendationCard'
import TanStackTable from '../../../../components/Table/TanStackTable/TanStackTable'
import useMktgAdvicesView from '../../../../hooks/Marketing/Advices/useMktgAdvicesView'
import MktgAdvicesForm from '../MktgAdvicesForm/MktgAdvicesForm'
import './MktgAdvicesView.css'
import DeleteConfirmationModal from '../../../../components/DeleteConfirmationModal/DeleteConfirmationModal'

export default function MktgAdvicesView() {

   const {
      columns,
      data,
      editFunc,
      setDisplayForm,
      displayForm,
      setInitialValuesEdit,
      initialValuesEdit,
      isLoading,
      handleSubmit,
      deleteAdvice,
      deleteModalOpen,
      setDeleteModalOpen,
      formValidation,
      setSelectedAdviceId
   } = useMktgAdvicesView()

   return (
      <section className='MktgView-layout--container MktgAdvicesView-main-container'>

         {displayForm && (
            <ContentCreator
               title={initialValuesEdit ? "Editar consejo" : "Crear consejo"}
               submitFunc={(values) => handleSubmit(values)}
               onCancel={() => {
                  setDisplayForm(false)
                  setInitialValuesEdit(null)
               }}
               initialValues={initialValuesEdit ?? {
                  advice: "",
                  user: "",
               }}
               validation={formValidation}
               renderPreview={(_form) => (
                  < DailyRecommendationCard advice={_form.form.values.advice} user={_form.form.values.user} />
               )}
               renderForm={(_form) => (
                  <MktgAdvicesForm form={_form.form} loading={isLoading} />
               )}
            />
         )}

         {!displayForm && data && (
            < TanStackTable
               data={data}
               columns={columns}
               editFunc={editFunc}
               onDelete={deleteAdvice}
               addButtonFunc={() => {
                  setDisplayForm(!displayForm)
                  if (displayForm) setInitialValuesEdit(null)
               }}
            />
         )}

         <DeleteConfirmationModal
            opened={deleteModalOpen}
            loading={isLoading}
            onClose={() => {
               setDeleteModalOpen(false)
               setSelectedAdviceId(null)
            }}
            onCancel={() => {
               setDeleteModalOpen(false)
               setSelectedAdviceId(null)
            }}
            onConfirm={deleteAdvice}
         />
      </section>
   )
}
