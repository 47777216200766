import {TextInput, Select} from '@mantine/core';
import {IconSearch, IconX} from '@tabler/icons-react';
import React, {useState} from "react";
import {DatePickerInput} from "@mantine/dates";

export default function ColumnFilter({column}) {
   const [dateValue, setDateValue] = useState([null, null]);

   const filterType = column.columnDef.filter;
   switch (filterType) {
      case "select":
         return (
             <Select
                 data={column.columnDef.filterOptions}
                 onChange={(value) => column.setFilterValue(value)}
                 searchable={true}
                 clearable
             />
         )
      case "date":
         return (
            <DatePickerInput
                locale="es"
                type="range"
                value={dateValue}
                onChange={(value) => {
                    setDateValue(value)
                    if (value[0] && value[1]) {
                        column.setFilterValue(value)
                    }
                }}
                rightSection={dateValue[0] ? < IconX onClick={() => {
                  setDateValue([null, null])
                  column.setFilterValue(null)
                }} /> : null}
            />
         )
        default:
           return (
               <TextInput
                   leftSection={< IconSearch size={16}/>}
                   type="text"
                   onChange={(e) => {
                       column.setFilterValue(e.target.value.toUpperCase())
                   }}
               />
           )
   }
}