export const ERROR_HANDLER = {
   defaultError: 'Error interno del servidor',
   0: 'Error interno del servidor',
   400: 'La solicitud es incorrecta o no se puede procesar',
   401: 'El usuario no está autorizado para acceder al recurso',
   403: 'El servidor ha entendido la solicitud, pero se niega a cumplirla',
   404: 'El recurso solicitado no se pudo encontrar en el servidor',
   405: 'El método de solicitud utilizado no está permitido para el recurso solicitado.',
   409: 'La solicitud no pudo ser completada debido a un conflicto con el estado actual del recurso.',
   429: 'El usuario ha enviado demasiadas solicitudes en un período de tiempo determinado.',
   500: 'Error interno del servidor',
   502: 'El servidor, al actuar como puerta de enlace, ha recibido una respuesta no válida del servidor ascendente',
   503: 'El servidor no está listo para manejar la solicitud',
   504: 'El servidor, al actuar como puerta de enlace, no pudo recibir una respuesta oportuna del servidor ascendente.'
}