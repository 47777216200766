import MainLayout from "../../layouts/MainLayout/MainLayout";

const Support = () => {
  return (
    <MainLayout>
      <div>Support</div>
    </MainLayout>
  );
};
export default Support;
