import { randomValueFromArray } from "./randomValueFromArray"

export const renderInitialLetterToAvatar = name => {
   if (!name) return ''
   const AVATAR_BG_COLORS = [
      {
         backgroundColor: '#a1df946b',
         color: '#81ce6a'
      },
      {
         backgroundColor: '#722f2f28',
         color: '#ce6a6a'
      },
      {
         backgroundColor: '#ce976a70',
         color: '#bb7033'
      }
   ]
   const colors = randomValueFromArray(AVATAR_BG_COLORS)
   const splitedName = name.split(" ")
   return <span style={{
      height: '100%',
      width: '100%',
      borderRadius: '50%',
      display: 'grid',
      placeItems: 'center',
      fontSize: 'var(--fs-tiny)',
      backgroundColor: colors.backgroundColor,
      color: colors.color
   }}>{`${splitedName[0].charAt(0)}${splitedName[splitedName.length - 1].charAt(0)}`}
   </span>
}