import { Modal, Accordion, Button, Loader, Text, Badge } from "@mantine/core";
import React, { useContext, useState } from "react";
import AuthContext from "../../contexts/AuthContext";
import { CERTIFICATE_DOWNLOAD_OPTIONS } from "../../utils/certificates-download-options";
import showNotification from "../../utils/showNotification";
import axios from "axios";
import "./CertificateDownloadModal.css";

const CertificateDownloadModal = ({ student, open, onClose, academicUnit }) => {
  const { authToken } = useContext(AuthContext);
  const [state, setState] = useState({
    isLoading: false,
    success: null,
    error: null,
  });

  const handleDownload = async (option) => {
    setState({ ...state, isLoading: true, error: null, success: null });
    try {
      const postData = {
        ...option.value,
        idUser: student.id,
        idAcaUnit: academicUnit,
      };
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/printCertificate`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            "Content-Type": "application/json",
          },
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      window.open(url);

      setState({
        ...state,
        isLoading: false,
        success: `Tu certificado ha sido creado con éxito y se abrirá en una nueva pestaña.`,
      });
      showNotification({
        color: "green",
        status: "success",
        title: "Certificado descargado con éxito.",
        message: `El certificado se ha generado y está listo para descargarse.`,
      });
    } catch (error) {
      setState({
        ...state,
        isLoading: false,
        error: `Ha ocurrido un error al descargar el certificado: ${error.message}`,
      });
      showNotification({
        color: "red",
        status: "error",
        title: "Error al descargar el certificado",
        message: `Detalle: ${error.message}`,
      });
    }
  };

  return (
    <Modal
      opened={open}
      onClose={onClose}
      classNames={{ title: "certificateDownloadModal--title" }}
      title={
        <div>
          Certificado para <Badge>{student.name}</Badge>
        </div>
      }
      size="lg"
    >
      <Accordion>
        {CERTIFICATE_DOWNLOAD_OPTIONS.map((option) => (
          <Accordion.Item key={option.id} value={option.text}>
            <Accordion.Control
              style={{ fontSize: "var(--fs-body)" }}
              icon={option.icon}
            >
              {option.text}
            </Accordion.Control>
            <Accordion.Panel>
              <Button
                onClick={() => handleDownload(option)}
                loading={state.isLoading}
              >
                Descargar
              </Button>
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
      {state.error && <Text color="red">{state.error}</Text>}
      {state.success && <Text color="green">{state.success}</Text>}
    </Modal>
  );
};

export default CertificateDownloadModal;
