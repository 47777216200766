import { currencyFormat } from '../../utils/currencyFormat';
import AccountInfoItem from './AccountInfoItem/AccountInfoItem';
import './BankAccount.css';

export default function BankAccount({ cbu, alias, bank, cuit, owner, accountType, accountNumber, formToShow }) {
    if (accountNumber || formToShow === "Cuenta en dolares") {
        return (
            <article className='bank-account-container'>
                < AccountInfoItem headline='Número de cuenta:' text={accountNumber} canCopy />
                < AccountInfoItem headline='Nombre:' text={owner} />
                < AccountInfoItem headline='DNI:' text={cuit ? currencyFormat(cuit) : ''} />
                < AccountInfoItem headline='Tipo:' text={accountType} />
                < AccountInfoItem headline='Banco:' text={bank} />
            </article>
        )
    }
   return (
      <article className='bank-account-container'>
         < AccountInfoItem headline='CBU:' text={cbu} canCopy />
         < AccountInfoItem headline='Alias:' text={alias} canCopy />
         < AccountInfoItem headline='Nombre:' text={owner} />
         < AccountInfoItem headline='CUIT:' text={cuit} />
         < AccountInfoItem headline='Tipo:' text={accountType} />
         < AccountInfoItem headline='Banco:' text={bank} />
      </article>
   )
}
