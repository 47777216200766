import { Table } from "@mantine/core";
import "./SalesPaymentsView.css";
import { currencyFormat } from "../../utils/currencyFormat";

export function SalesPaymentsView({ data }) {
  const { details, payer, payment } = data.rowData;

  return (
    <Table.ScrollContainer minWidth={800}>
      <Table verticalSpacing="xs">
        <Table.Thead>
          <Table.Tr>
            <Table.Th>Capacitación</Table.Th>
            <Table.Th>Cliente</Table.Th>
            <Table.Th>Importe abonado</Table.Th>
            <Table.Th>Moneda</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td>{details}</Table.Td>
            <Table.Td>{`${payer.name} ${payer.lastname}`}</Table.Td>
            <Table.Td>{`$${currencyFormat(data.agreedAmount)}`}</Table.Td>
            <Table.Td>{payment.currency}</Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}
