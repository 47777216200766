import React, {useContext, useState} from 'react';
import "./NewModuleModal.css"
import showNotification from "../../../../../utils/showNotification";
import {useForm} from "@mantine/form";
import axios from "axios";
import {Grid, Textarea, TextInput, Button, NumberInput} from "@mantine/core";
import AcademicContext from "../../../../../contexts/AcademicContext";
import AuthContext from "../../../../../contexts/AuthContext";
import moment from "moment";

const academicUnitTypesDict = {
    "Curso": "C",
    "Masterclass": "MC",
    "Diplomado": "D",
    "Posgrado": "PG",
    "Webinar": "WB",
    "Taller": "T"
}

function NewModuleModal(props) {
    const {setOpen, formHandler} = props;
    const [loading, setLoading] = useState(false);
    const {handleModified} = useContext(AcademicContext);
    const {authToken} = useContext(AuthContext);
    const form = useForm({
        initialValues: {
            name: "",
            realName: "",
            description: "",
            moduleNumber: 1, // Used to generate the realName
            academicUnitName: formHandler.values.name, // Used to generate the realName
            academicUnitType: academicUnitTypesDict[formHandler.values.type] ?? "D", // Used to generate the realName
            academicUnitYear: moment().year(), // Used to generate the realName
            academicUnits: [], // Default value
            lessons: [] // Default value
        },
        validate: {},
    });

    const handleSubmit = async (values) => {
        setLoading(true)

        const { moduleNumber, academicUnitType, name, academicUnitYear, academicUnitName } = values;
        const realName = `MÓDULO ${moduleNumber}: ${academicUnitType}. ${name.toUpperCase()} - ${academicUnitName} (${academicUnitYear})`;

        const data = {
            data: {
                ...values,
                name: name.toUpperCase(),
                realName
            }
        }

        const config = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + authToken,
            }
        }
        axios.post(`${process.env.REACT_APP_BACKEND_SERVER}admin/academico/modules`, data, config)
            .then(() => {
                handleModified()
                showNotification({
                    color: "green",
                    status: "success",
                    title: "¡Operación exitosa!",
                    message: "El módulo ha sido creado.",
                    autoClose: 5000,
                });
            })
            .catch((err) => {
                const error = err.message || err.response.data.message || err || "Error al crear el módulo";
                showNotification({
                    color: "red",
                    status: "error",
                    title: "Error",
                    message: `Error al crear el módulo: ${error}`,
                });
            })
            .finally(() => {
                setLoading(false)
                setOpen(false)
            })
    }

    return (
        <div className="createModule__container">
            <h2 className="createModule__title">Crear módulo</h2>
            <form
                onSubmit={form.onSubmit((values) => handleSubmit(values))}
                className="createModule__form"
            >
                <Grid style={{width: "100%"}}>
                    <Grid.Col span={12}>
                        <NumberInput
                            {...form.getInputProps('academicUnitYear')}
                            withAsterisk
                            label="Año de la unidad académica"
                            min={2024}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <NumberInput
                            {...form.getInputProps('moduleNumber')}
                            withAsterisk
                            label="Número del módulo"
                            min={1}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <TextInput
                            {...form.getInputProps('name')}
                            withAsterisk
                            label="Nombre del módulo"
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <Textarea
                            {...form.getInputProps('description')}
                            withAsterisk
                            label="Descripción del módulo"
                            autosize
                            minRows={3}
                        />
                    </Grid.Col>
                </Grid>
                <Button type="submit" className="createModule__button" loading={loading}>Crear módulo</Button>
            </form>
        </div>
    );
}

export default NewModuleModal;