import './ModerationStructuresForm.css'
import { Button, Checkbox, Grid, Group, Stack } from '@mantine/core'
import { IconCalendar, IconClockHour12, IconEdit, IconSchool } from '@tabler/icons-react'
import React, { useState } from 'react'
import ModerationStructuresModules from '../ModerationStructuresModules/ModerationStructuresModules'
import ModerationStructuresFormModal from '../ModerationStructuresFormModal/ModerationStructuresFormModal'

export default function ModerationStructuresForm({ form, isLoading, handleSubmit }) {
   const { name, classDays, classHour } = form.values.comercialInformation

   const [classesToEdit, setClassesToEdit] = useState([])
   const [isModal, setIsModal] = useState(false)

   const [selectZoom, setSelectZoom] = useState('actual')
   const [selectZoomCode, setSelectZoomCode] = useState('actual')
   const [selectModerator, setSelectModerator] = useState('actual')
   const [sendNotification, setSendNotification] = useState(false)

   const resetInitalValues = () => {
      setIsModal(false)
      setClassesToEdit([])
      setSelectModerator('actual')
      setSelectZoom('actual')
      setSelectZoomCode('actual')
      setSendNotification(false)
   }

   const onClickSelectAll = () => {
      if (!form?.values?.modules) return null

      if (classesToEdit.length === 0 ) {
         const allLessons = form.values.modules.flatMap(module => {
            return module.lessons.map(lesson => lesson._id)
         })

         return setClassesToEdit(allLessons)
      }


      return setClassesToEdit([])
   }

   return (
      <>
         <Grid w='100%' >
            <Grid.Col span={12}>
               <Stack >
                  <Group gap={6} align='center'>
                     < IconSchool className='ModerationStructuresForm-academicName--icon' size='100%' />
                     <h3 className='ModerationStructuresForm--academicName'>{name}</h3>
                  </Group>

                  <Group>
                     <Group>
                        < IconCalendar />
                        <span className='ModerationStructuresForm--bodyText'>{classDays}</span>
                     </Group>
                     <Group>
                        < IconClockHour12 />
                        <span className='ModerationStructuresForm--bodyText'>{classHour}</span>
                     </Group>
                  </Group>

                  <Stack>
                     <Group align='center'>
                        <span className='ModerationStructuresForm--bodyText'><strong>{classesToEdit.length}</strong> clases seleccionadas</span>
                        <Button
                           disabled={!classesToEdit.length}
                           color='var(--color-tertiary)'
                           onClick={() => {
                              setIsModal(true)
                           }}
                        >
                           < IconEdit />
                           <span>Editar</span>
                        </Button>
                     </Group>
                     <Button w='max-content' variant='outline'
                        onClick={() => onClickSelectAll()}
                        color='var(--color-tertiary)'
                     >{
                        classesToEdit.length === 0 ? 'Seleccionar todas' : 'Borrar selección'
                     }</Button>
                  </Stack>
               </Stack>
            </Grid.Col>

            <Grid.Col span={12}>

               <Checkbox.Group
                  value={classesToEdit}
                  onChange={setClassesToEdit}
               >
                  < ModerationStructuresModules
                     modules={form?.values?.modules}
                     setClassesToEdit={setClassesToEdit}
                     setIsModal={setIsModal}
                  />
               </Checkbox.Group>

            </Grid.Col>

         </Grid>

         < ModerationStructuresFormModal
            isModal={isModal}
            resetInitalValues={resetInitalValues}
            classesToEdit={classesToEdit}
            selectZoom={selectZoom}
            setSelectZoom={setSelectZoom}
            selectZoomCode={selectZoomCode}
            setSelectZoomCode={setSelectZoomCode}
            selectModerator={selectModerator}
            setSelectModerator={setSelectModerator}
            form={form}
            sendNotification={sendNotification}
            setSendNotification={setSendNotification}
            isLoading={isLoading}
            handleSubmit={handleSubmit}
         />
      </>
   )
}
