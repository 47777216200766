import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { createColumnHelper } from "@tanstack/react-table";
import AuthContext from "../../contexts/AuthContext";
import TanStackTable from "../Table/TanStackTable/TanStackTable";
import CertificateDownloadModal from "../CertificateDownloadModal/CertificateDownloadModal";
import { IconCircleFilled, IconDownload } from "@tabler/icons-react";
import "./NotesAndCertificatesTable.css";
import { Badge, Button, Tooltip } from "@mantine/core";

const NotesAndCertificatesTable = ({ academicUnit, acaUnitName, onBack }) => {
  const [notesTable, setNotesTable] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const { authToken } = useContext(AuthContext);
  const columnHelper = createColumnHelper();

  const getBadgeClass = (percentage) => {
    if (percentage == null) return "notesAndCertificates--noBadge";
    if (percentage >= 85) return "notesAndCertificates--badgeGreen";
    if (percentage >= 65) return "notesAndCertificates--badgeYellow";
    if (percentage >= 50) return "notesAndCertificates--badgeOrange";
    return "notesAndCertificates--badgeRed";
  };

  const getExamStatusClass = (result) => {
    switch (result) {
      case "APPROVED":
        return "notesAndCertificates--badgeGreen";
      case "FAILED":
        return "notesAndCertificates--badgeRed";
      default:
        return "notesAndCertificates--badgeYellow";
    }
  };

  const isDownloadAllowed = (examStatus) => {
    return examStatus === "APPROVED";
  };

  const columns = [
    columnHelper.accessor((row) => `${row.name} ${row.lastname}`, {
      id: "studentName",
      header: "Nombre del Estudiante",
      enableColumnFilter: true,
      enableSorting: false,
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor(
      (row) =>
        row.exams.length > 0 ? row.exams[0].result : "No rindió examen",
      {
        id: "examStatus",
        header: "Estado del Examen",
        enableColumnFilter: true,
        enableSorting: false,
        filter: "select",
        filterOptions: [
          { value: "APPROVED", label: "Aprobado" },
          { value: "FAILED", label: "Desaprobado" },
          { value: "NOT_TAKEN", label: "No rindió examen" },
        ],
        filterFn: (row, columnId, filterValue) => {
          if (!row || !filterValue) return true;
          const result =
            row.original.exams.length > 0
              ? row.original.exams[0].result
              : "NOT_TAKEN";
          return result === filterValue;
        },
        cell: (info) => (
          <Badge className={getExamStatusClass(info.getValue())}>
            {info.getValue() === "APPROVED"
              ? "Aprobado"
              : info.getValue() === "FAILED"
              ? "Desaprobado"
              : "No rindió examen"}
          </Badge>
        ),
      }
    ),
    columnHelper.accessor(
      (row) => (row.exams.length > 0 ? row.exams[0].resultPercentage : null),
      {
        id: "examPercentage",
        header: "Nota (%)",
        enableColumnFilter: false,
        enableSorting: true,
        sortType: (rowA, rowB) => {
          const valueA =
            rowA.original.exams.length > 0
              ? rowA.original.exams[0].resultPercentage
              : -1;
          const valueB =
            rowB.original.exams.length > 0
              ? rowB.original.exams[0].resultPercentage
              : -1;
          return valueA - valueB;
        },
        cell: (info) => {
          const percentage =
            info.row.original.exams.length > 0
              ? info.row.original.exams[0].resultPercentage.toFixed(1)
              : "-";
          return (
            <Badge
              className={getBadgeClass(
                info.row.original.exams.length > 0
                  ? info.row.original.exams[0].resultPercentage
                  : null
              )}
            >
              {percentage}%
            </Badge>
          );
        },
      }
    ),
    columnHelper.accessor(
      (row) => row.academicUnitsData.certificates.some((cert) => cert.download),
      {
        id: "certificateDownloaded",
        header: "Certificado Descargado",
        enableColumnFilter: false,
        enableSorting: true,
        cell: (info) => (
          <Badge
            classNames={{
              root: `${
                info.getValue()
                  ? "notesAndCertificates--badgeGreen"
                  : "notesAndCertificates--badgeRed"
              }`,
            }}
          >
            {info.getValue() ? "Sí" : "No"}
          </Badge>
        ),
      }
    ),
    columnHelper.accessor((row) => row._id, {
      id: "actions",
      header: "Acciones",
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => {
        const isAllowed = isDownloadAllowed(
          info.row.original.exams.length > 0
            ? info.row.original.exams[0].result
            : "No rindió examen"
        );
        return (
          <div
            className={`notesAndCertificatesTable-downloadIcon ${
              !isAllowed ? "notesAndCertificatesTable-downloadIconDisabled" : ""
            }`}
          >
            <Tooltip
              label={
                !isAllowed
                  ? "El estudiante no ha aprobado el examen o no lo ha rendido aún"
                  : ""
              }
              disabled={isAllowed}
              position="bottom"
            >
              <IconDownload
                style={{ cursor: !isAllowed ? "not-allowed" : "pointer" }}
                disabled={!isAllowed}
                onClick={() => {
                  if (isAllowed) {
                    setSelectedStudent({
                      id: info.getValue(),
                      name: `${info.row.original.name} ${info.row.original.lastname}`,
                    });
                    setShowModal(true);
                  }
                }}
              />
            </Tooltip>
          </div>
        );
      },
    }),
  ];

  const init = async () => {
    const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/checkAcaUnitExam/${academicUnit}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const response = await axios.get(url, config);
    setNotesTable(response.data.students);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {notesTable.length > 0 ? (
        <div className="notesAndCertificates-mainDiv">
          <div className="notesAndCertificates-headerDiv">
            <div className="notesAndCertificates-titlesDiv">
              <h6 className="notesAndCertificates-headerQuantity-acaUnitName">
                <Badge
                  classNames={{
                    root: "notesAndCertificates-headerQuantity-acaUnitName-badge",
                  }}
                >
                  {acaUnitName}
                </Badge>
              </h6>
            </div>
            <Button
              classNames={{ root: "notesAndCertificates-buttonRoot" }}
              onClick={onBack}
              w={"max-content"}
              variant="outline"
              color="var(--color-tertiary)"
            >
              Volver atrás
            </Button>
          </div>
          <TanStackTable data={notesTable} columns={columns} />
          {showModal && (
            <CertificateDownloadModal
              academicUnit={academicUnit}
              student={selectedStudent}
              open={showModal}
              onClose={() => setShowModal(false)}
            />
          )}
          <div className="notesAndCertificatesTable-colorReferences">
            <div className="notesAndCertificatesTable-colorReferences-div">
              <div className="notesAndCertificatesTable-colorReference-headerDiv">
                <p className="notesAndCertificatesTable-colorReference-p-header">
                  En la columna NOTA:
                </p>
              </div>
              <span className="notesAndCertificatesTable-colorReferences-span">
                <IconCircleFilled className="notesAndCertificatesTable-colorReferences-IconGreen" />
                <p className="notesAndCertificatesTable-colorReference-p-Styling">
                  = + de 85%.
                </p>
              </span>
              <span className="notesAndCertificatesTable-colorReferences-span">
                <IconCircleFilled className="notesAndCertificatesTable-colorReferences-IconYellow" />
                <p className="notesAndCertificatesTable-colorReference-p-Styling">
                  = menos de 85% y mas de 65%.
                </p>
              </span>
              <span className="notesAndCertificatesTable-colorReferences-span">
                <IconCircleFilled className="notesAndCertificatesTable-colorReferences-IconRed" />
                <p className="notesAndCertificatesTable-colorReference-p-Styling">
                  = menos de 65% y 50% o más.
                </p>
              </span>
              <span className="notesAndCertificatesTable-colorReferences-span">
                <IconCircleFilled className="notesAndCertificatesTable-colorReferences-IconRed" />
                <p className="notesAndCertificatesTable-colorReference-p-Styling">
                  = - 50%.
                </p>
              </span>
            </div>
          </div>
        </div>
      ) : (
        <div className="notesAndCertificates-noContentMainDiv">
          <div className="notesAndCertificates-headerDiv">
            <div className="notesAndCertificates-titlesDiv">
              <h6 className="notesAndCertificates-headerQuantity-acaUnitName">
                <Badge
                  classNames={{
                    root: "notesAndCertificates-headerQuantity-acaUnitName-badge",
                  }}
                >
                  {acaUnitName}
                </Badge>
              </h6>
            </div>
            <Button
              classNames={{ root: "notesAndCertificates-buttonRoot" }}
              onClick={onBack}
              w={"max-content"}
              variant="outline"
              color="var(--color-tertiary)"
            >
              Volver atrás
            </Button>
          </div>
          <p className="notesAndCertificates-noContent-text">Aún hay notas para esta unidad académica.</p>
        </div>
      )}
    </>
  );
};

export default NotesAndCertificatesTable;
