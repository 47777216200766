import {Accordion} from '@mantine/core';
import './QuestionnairePreview.css';
import {
    IconSelector,
    IconArrowUp,
    IconArrowDown,
} from '@tabler/icons-react'

export default function QuestionnairePreview(props) {
    const {data, moveQuestionBack, moveQuestionForward, isDisabledQuestionForwardBtn, isDisabledQuestionBackBtn} = props;

    const handleMoveQuestionBack = () => {
        if (isDisabledQuestionBackBtn()) return;
        moveQuestionBack();
    }

    const handleMoveQuestionForward = () => {
        if (isDisabledQuestionForwardBtn()) return;
        moveQuestionForward();
    }

    const items = data.map((item) => {
        return (
            <div className="QuestionnairePreview--item--wrapper">
                <Accordion.Item style={{width: "100%"}} key={item._id} value={item?._id ?? item?.value ?? 'Escribe tu pregunta'}>
                    <Accordion.Control>
                        <h4 className='QuestionnairePreview-question--title'>{item.title}</h4>
                        <h5 className='QuestionnairePreview-question--text'>{item.questionText}</h5>
                    </Accordion.Control>
                    <Accordion.Panel>{item.responseOptions?.map(res => (
                        <p className={`QuestionnairePreview--answerText ${res.value === item.trueOption ? 'QuestionnairePreview--correctAnswer' : ''}`}>{res.label}</p>
                    ))}</Accordion.Panel>
                </Accordion.Item>
                <div className="QuestionnairePreview--item--icons--container">
                    <IconArrowUp size={20} onClick={() => handleMoveQuestionBack()}
                                 className="QuestionnairePreview--item--icon"/>
                    <IconArrowDown size={20} onClick={() => handleMoveQuestionForward()}
                                   className="QuestionnairePreview--item--icon"/>
                </div>
            </div>
        )
    });

    return (
        <>
            {!data ?
                <div className='QuestionnairePreview-noContent-container'>
                    < IconSelector size={50} color='var(--color-text-primary)'/>
                    <p className='QuestionnairePreview--text'>No se ha seleccionado ningun formulario</p>
                </div>
                : <div className='QuestionnairePreview-noContent-container'>
                    <h4 className='QuestionnairePreview--title'>Previsualización del exámen</h4>
                    <Accordion style={{width: "95%"}}>
                        {items}
                    </Accordion>
                </div>
            }
        </>
    );
}
