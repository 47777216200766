import {Modal} from '@mantine/core'
import React from 'react'

export default function CellInfoModal({text, opened, onCloseFunct, title, darkMode = false}) {

    return (
        <Modal
            styles={{
                title: {
                    fontSize: 'var(--fs-body)',
                    color: '#5D5A6F'
                },
                root: darkMode ? {
                    backgroundColor: "#2c4b58", // var(--color-primary-dark)
                } : {},
                header: darkMode ? {
                    backgroundColor: "#2c4b58", // var(--color-primary-dark)
                } : {},
                body: darkMode ? {
                    backgroundColor: "#2c4b58", // var(--color-primary-dark)
                } : {},
            }}
            size={"xl"}
            opened={opened}
            onClose={() => onCloseFunct(null)}
            closeOnEscape={() => onCloseFunct(null)}
            closeOnClickOutside={() => onCloseFunct(null)}
            title={title || ''}
        >
            {text}
        </Modal>
    )
}
