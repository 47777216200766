import { Image } from "@mantine/core";
import React from "react";
import "./SpecialtiesCard.css";

const SpecialtiesCard = (props) => {
  const { title, photo } = props;
  return (
    <div className="specialtiesCard-container-div">
      <Image className="specialtiesCard-image-custom" src={photo} />
      <div className="specilatiesCard-content-container">
        <h2 className="specialtiesCard-title-for-specialties">{title}</h2>
      </div>
    </div>
  );
};
export default SpecialtiesCard;
