import './SponsorSection.css';
import { Button, Skeleton } from '@mantine/core';
import { Link } from 'react-router-dom';
import SponsorSectionHeader from "./SponsorSectionHeader/SponsorSectionHeader";
import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function SponsorSection(props) {
    const sponsorData = props;
   const { isMobile } = useWindowDimensions()/* 
   const url = process.env.REACT_APP_BACKEND_SERVER + '/info/sponsors'
   const sponsorsInfo = useFetch(url) *//* 
   const { data, error, isLoading } = sponsorsInfo
   const sponsorData = data?.find(sponsor => sponsor.location === window.location.pathname) */
   return (
      <div className='aboutUsPage-sponsor-section-container'>

         < SponsorSectionHeader />

         <div className='aboutUsPage-sponsor-content-container'>
            <div className='aboutUsPage-sponsor-content--images-container'>
               {(sponsorData) &&
                  <Link to={sponsorData?.link} target='_blank' className='sponsor-content-image-container'>
                     {sponsorData?.image_url &&
                        <>
                           < img className='sponsor-image' src={sponsorData?.image_url} alt='sponsor del mes' />
                           <div className='sponsor-logo-image' style={{ backgroundImage: `url(${sponsorData?.logo_url})` }}></div>
                        </>
                     }
                  </Link>
               }
            </div>
            <div className='aboutUsPage-sponsor-content--text-container'>
               <h3 className='aboutUsPage-sponsor-content-title'>{sponsorData?.name}</h3>
               <p>{sponsorData?.description}</p>
               <Button component={Link} to={sponsorData?.link} target='_blank' m={isMobile ? 'auto' : '0'} w='150px'>Ver más</Button>
            </div>
         </div>
      </div>
   )
}
