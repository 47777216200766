import {Route, Routes} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import Callback from "../pages/Callback/Callback";
import AdminRoutes from "./AdminRoutes";
import Login from "../pages/Login/Login";

const AppRoutes = () => {
    return (
        <Routes>
            <Route exact path="/" element={<Login/>}/>
            <Route exact path="/callback" element={<ProtectedRoute component={Callback}/>}/>
            <Route exact path="/admin/*" element={<ProtectedRoute component={AdminRoutes}/>}/>
        </Routes>
    )
}
export default AppRoutes