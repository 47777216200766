import React from "react";
import "./MarketingFaqsForm.css";
import { Button, Grid, Select, TextInput } from "@mantine/core";

const EVENT_TYPES = [
  { value: "general", label: "General" },
  { value: "Pagos", label: "Pagos" },
  { value: "Servicio", label: "Servicio" },
  { value: "Datos", label: "Datos" },
  { value: "Acceso", label: "Acceso" },
  { value: "Funcionalidad", label: "Funcionalidad" },
  { value: "Capacitacion", label: "Capacitacion" },
];

const DISPLAY_TYPE = [
  { value: "landing", label: "Web ventas" },
  { value: "campus", label: "Campus" },
];

function MktgFaqsForm(props) {
  const { form, loading } = props;

  return (
    <Grid style={{ width: "100%" }} justify="center" align="center">
      <Grid.Col span={12}>
        <Select
          {...form.getInputProps("category")}
          withAsterisk
          label="Tema de la pregunta"
          data={EVENT_TYPES}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Select
          {...form.getInputProps("display")}
          withAsterisk
          label="Espacio de la pregunta"
          data={DISPLAY_TYPE}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="Pregunta"
          {...form.getInputProps("question")}
          w={"100%"}
        />
      </Grid.Col>
      <Grid.Col span={6}>
        <TextInput
          label="Respuesta"
          {...form.getInputProps("answer")}
          w={"100%"}
        />
      </Grid.Col>
      <Grid.Col span={12}>
        <Button
          type="submit"
          className="eventCalendarForm__form__button"
          loading={loading}
        >
          Guardar evento
        </Button>
      </Grid.Col>
    </Grid>
  );
}

export default MktgFaqsForm;
