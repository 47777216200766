import './TableHeader.css'
import {Button} from '@mantine/core'
import React from "react";

export default function TableHeader({
                                        title,
                                        addButtonFunc,
                                        addButtonText,
                                        handleExport
                                    }) {

    return (
        <header className='TableHeader-container'>
            {title && <h3 className='TableHeader--title'>{title}</h3>}

            <div className='TableHeader--group'>
                {/* < TextInput
               leftSection={< IconSearch />}
               w={'275px'}
               placeholder='Buscar'
               type="text"
               value={filtering}
               onChange={(event) => setFiltering(event.target.value)} /> */}
                <Button color='var(--color-primary)' variant='light' onClick={handleExport}>
                    Exportar CSV
                </Button>
                {addButtonFunc &&
                <Button
                    onClick={() => addButtonFunc()}
                    w={'max-content'}
                    color='var(--color-tertiary)'
                    className='TableHeader-create-button'
                >
                    <span>{addButtonText}</span>
                </Button>
                }
            </div>

        </header>
    )
}
