import { Route, Routes } from "react-router";
import MainLayout from "../../layouts/MainLayout/MainLayout";
import ViewLayout from "../../layouts/ViewLayout/ViewLayout";
import ModerationClasses from "../../features/Moderation/ModerationClasses/ModerationClasses";
import ModerationHome from "../../features/Moderation/ModerationHome/ModerationHome";
import ModerationStructuresView from "../../features/Moderation/ModerationStructures/ModerationStructuresView/ModerationStructuresView";
import { withAcademicContextProvider, withAcademicContextReady } from "../../contexts/AcademicContext";

const Moderation = () => {
   return (
      <MainLayout>
         <Routes>
            < Route
               path='/'
               element={
                  <ViewLayout
                     title='Moderación'
                     description='Aqui podras generar contenido específico para la sección relacionadas a la Moderación del sitio web y del campus.'
                  >
                     < ModerationHome />
                  </ViewLayout>
               }
            />
            < Route
               path='/clases'
               element={
                  <ViewLayout
                     title='Clases'
                     description='Aqui podras ver clases por unidad académica. Editar moderadores y links de zoom..'
                     withBackButton
                     backBtnText='Volver a Moderación'
                  >
                     <ModerationClasses />
                  </ViewLayout>
               }
            />
            < Route
               path='/estructuras'
               element={
                  <ViewLayout
                     title='Moderación'
                     description='Aqui podras buscar clases segun fecha, unidades académicas o profesores.'
                     withBackButton
                     backBtnText='Volver a Moderación'
                  >
                     <ModerationStructuresView />
                  </ViewLayout>
               }
            />
         </Routes>
      </MainLayout>
   );
};
export default withAcademicContextProvider(withAcademicContextReady(Moderation));
