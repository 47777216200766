import {DonutChart, PieChart} from '@mantine/charts'
import {Badge, Flex, Group, Stack} from '@mantine/core'
import {
    IconCalendar,
    IconTrendingDown,
    IconTrendingUp,
    IconUser,
    IconUsersGroup
} from '@tabler/icons-react'
import moment from 'moment'
import React from 'react'

export default function AdDasboardCourses({data}) {
    return (
        <section className='AdministrativeDashboard-section-container'>
            <h3 className='AdministrativeDashboard-titleSection'>Capacitaciones</h3>
            {data &&
            <div className="AdministrativeDashboard-container">
                <div className="AdministrativeDashboard-grid">
                    {/*<div className='AdministrativeDashboard-gridContainer'>*/}

                    <article className='AdministrativeDashboard-card AdministrativeDashboard-card-thisMonth'>
                        <Group>
                            < IconCalendar/>
                            <h3 className='AdministrativeDashboard-card-title'>Comienzan este mes</h3>
                        </Group>
                        <Stack className='AdministrativeDashboard-stack-wrapper'>
                            {data.initNow.length > 0 ?
                                data.initNow.map(item => (
                                    <Flex direction='column' className='AdministrativeDashboard-card-rowItem'
                                          align='start' w='100%' gap='5px' key={item._id}>

                                        <p className='AdministrativeDashboard-card-bodyText'>{item.comercialInformation.name}</p>
                                        <Group w='100%' gap='5px'>
                                            <Badge fz='10px'
                                                   color='var(--color-tertiary)'>{moment(item.comercialInformation.initialDate).format('DD-MM-YYYY')}</Badge>
                                            <Badge fz='10px' color='var(--color-primary)'>{item.type}</Badge>
                                        </Group>
                                    </Flex>
                                ))
                                : <p style={{
                                    color: 'var(--color-text-primary)',
                                    fontSize: 'var(--fs-body)',
                                    textAlign: 'center'
                                }}>No hay capacitaciones comenzando este mes</p>
                            }
                        </Stack>
                    </article>

                    <article className='AdministrativeDashboard-card AdministrativeDashboard-card-morePopular'>
                        <Group>
                            <Group gap='0'>
                                < IconUsersGroup/>
                                < IconTrendingUp/>
                            </Group>
                            <h3 className='AdministrativeDashboard-card-title'>Populares</h3>
                        </Group>
                        <Stack className="AdministrativeDashboard-stack-wrapper">
                            {data.threeMostPopularCourses.map(item => (
                                <Flex direction='column' className='AdministrativeDashboard-card-rowItem' align='start'
                                      w='100%' gap='5px' key={item._id}>
                                    <p className='AdministrativeDashboard-card-bodyText'>{item.comercialInformation.name}</p>
                                    <Group w='100%' gap='5px'>
                                        <Badge fz='12px' color='var(--color-tertiary)'>
                                            <Group gap='0' align='center'>
                                                < IconUser size={'12px'}/>
                                                <span>{item.studentsCount}</span>
                                            </Group>
                                        </Badge>
                                        <Badge fz='12px' color='var(--color-primary)'>{item.type}</Badge>
                                    </Group>
                                </Flex>
                            ))}

                        </Stack>
                    </article>

                    <article className='AdministrativeDashboard-card AdministrativeDashboard-card-lessPopular'>
                        <Group>
                            <Group gap='0'>
                                < IconUsersGroup/>
                                < IconTrendingDown/>
                            </Group>
                            <h3 className='AdministrativeDashboard-card-title'>Populares</h3>
                        </Group>
                        <Stack  className="AdministrativeDashboard-stack-wrapper">
                            {data.threeLeastAttendedCourses.map(item => (
                                <Flex direction='column' className='AdministrativeDashboard-card-rowItem' align='start'
                                      w='100%' gap='5px' key={item._id}>

                                    <p className='AdministrativeDashboard-card-bodyText'>{item.comercialInformation.name}</p>
                                    <Group w='100%' gap='5px'>
                                        <Badge fz='12px' color='var(--color-tertiary)'>
                                            <Group gap='0' align='center'>
                                                < IconUser size={'12px'}/>
                                                <span>{item.studentsCount}</span>
                                            </Group>
                                        </Badge>
                                        <Badge fz='12px' color='var(--color-primary)'>{item.type}</Badge>
                                    </Group>
                                </Flex>
                            ))}
                        </Stack>
                    </article>
                </div>
                <div className="AdministrativeDashboard-charts">

                    <Stack
                        align='center'
                        p={10}
                        miw={{base: '100%', sm: '300px'}}
                        style={{
                            boxShadow: 'var(--box-shadow)',
                            borderRadius: 'var(--border-radius-md)',
                            backgroundColor: '#fff',
                        }}
                    >
                        <Group>
                            < IconCalendar/>
                            <h3 className='AdministrativeDashboard-card-title'>Comienzan este mes</h3>
                        </Group>
                        <PieChart
                            withTooltip
                            data={[
                                {name: 'Totales', value: data.totals, color: 'var(--color-primary)'},
                                {name: 'Este mes', value: data.initNow.length, color: 'var(--color-secondary)'},
                            ]}/>
                        <span style={{
                            textAlign: 'center',
                            fontSize: 'var(--fs-small)',
                            color: 'var(--color-text-primary)'
                        }}>Con respecto al total</span>
                    </Stack>
                    <Stack
                        align='center'
                        p={10}
                        miw={{base: '100%', sm: '300px'}}
                        style={{
                            boxShadow: 'var(--box-shadow)',
                            borderRadius: 'var(--border-radius-md)',
                            backgroundColor: '#fff',
                        }}
                    >
                        <Group>
                            < IconCalendar/>
                            <h3 className='AdministrativeDashboard-card-title'>Comienzan este mes</h3>
                        </Group>
                        <DonutChart
                            withTooltip
                            data={[
                                {
                                    name: 'Curso',
                                    value: data.initNow.filter(item => item.type === 'Curso').length,
                                    color: 'var(--color-tertiary)'
                                },
                                {
                                    name: 'Diplomado',
                                    value: data.initNow.filter(item => item.type === 'Diplomado').length,
                                    color: 'var(--color-primary)'
                                },
                                {
                                    name: 'Posgrado',
                                    value: data.initNow.filter(item => item.type === 'Posgrado').length,
                                    color: 'var(--color-secondary)'
                                },
                                {
                                    name: 'Webinar',
                                    value: data.initNow.filter(item => item.type === 'Webinar').length,
                                    color: 'red.6'
                                },
                                {
                                    name: 'Masterclass',
                                    value: data.initNow.filter(item => item.type === 'Masterclass').length,
                                    color: 'green.6'
                                },
                                {
                                    name: 'Taller',
                                    value: data.initNow.filter(item => item.type === 'Taller').length,
                                    color: 'orange.6'
                                },
                                {
                                    name: 'Bootcamp',
                                    value: data.initNow.filter(item => item.type === 'Bootcamp').length,
                                    color: 'pink.1'
                                },
                            ]}/>
                        <span style={{
                            textAlign: 'center',
                            fontSize: 'var(--fs-small)',
                            color: 'var(--color-text-primary)'
                        }}>{`Por tipo de capacitación`}</span>
                    </Stack>

                </div>
            </div>
            }
        </section>
    )
}
