import React, {useContext, useEffect, useState} from "react";
import "./AcademicSpecialtiesContainer.css";
import axios from "axios";
import {createColumnHelper} from "@tanstack/react-table";
import {ERROR_HANDLER} from "../../../utils/error-handler";
import AdminSpecialtiesForm from "./AcademicSpecialtiesForm/AdminSpecialtiesForm";
import SpecialtiesCard from "../../../components/SpecialtiesCard/SpecialtiesCard";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import TanStackTable from "../../../components/Table/TanStackTable/TanStackTable";
import ContentCreator from "../../../components/ContentCreator/ContentCreator";
import showNotification from "../../../utils/showNotification";
import AuthContext from "../../../contexts/AuthContext";
import { Modal } from "@mantine/core";

const createEvent = async (values, config) => {
    const jsonData = {
        data: {
            ...values,
        },
    };
    return axios.post(
        `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/specialties`,
        jsonData,
        config
    );
};
const editEvent = async (values, config) => {
    const jsonData = {
        item: {_id: values.id},
        field: {
            ...values,
        },
    };
    return axios.put(
        `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/specialties`,
        jsonData,
        config
    );
};

function AdminSpecialtiesContainer() {
    const {authToken} = useContext(AuthContext);
    const [SpecialtiesData, setSpecialtiesData] = useState(null);
    const [displayForm, setDisplayForm] = useState(false);
    const [initialValuesEdit, setInitialValuesEdit] = useState(null);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedSpecialtiesId, setSelectedSpecialtiesId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [updateData, setUpdateData] = useState(false);

    const handleOpenModal = (value) => {
        setModalContent(value);
        setOpenModal(true);
    }

    // COLUMN DEFINITIONS
    const columnHelper = createColumnHelper();
    const columns = [
        columnHelper.accessor("name", {
            cell: (info) =>{
                const value = info.getValue();
                const upperValue = value.toUpperCase();
                return upperValue;
            },
            header: "Nombre",
            enableColumnFilter: true,
            enableSorting: false,
            filter: "text",
            filterFn: "includesString",
        }),
        columnHelper.accessor("description", {
            cell: (info) => {
                const value = info.getValue();
                const valueUpper = value.toUpperCase();
                return (
                    <div className="acaSpecialties-description-styling" onClick={() => handleOpenModal(valueUpper)}>
                        {valueUpper}
                    </div>
                );
            },
            header: "Descripción",
            enableColumnFilter: true,
            enableSorting: false,
            filter: "text",
            filterFn: "includesString",
        }),
    ];

    // TABLE FUNCTIONS
    const editSpecialtyEvent = (rowData) => {
        if (rowData) {
            setInitialValuesEdit({
                id: rowData._id,
                name: rowData.name,
                photo: rowData.photo,
                description: rowData.description,
            });
            setDisplayForm(true);
        }
    };

    const deleteSpecialty = async (idSpecialtyEvent = null) => {
        if (deleteModalOpen) {
            try {
                setLoading(true);
                await axios.delete(
                    `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/specialties`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + authToken,
                        },
                        data: {_id: selectedSpecialtiesId._id},
                    }
                );
                setUpdateData(!updateData);
                showNotification({
                    color: "green",
                    status: "success",
                    title: "¡Operación exitosa!",
                    message: "El elemento seleccionado ha sido correctamente eliminado.",
                    autoClose: 12000,
                });
            } catch (error) {
                console.error(error);
                showNotification({
                    color: "red",
                    status: "error",
                    title: "Operación fallida.",
                    message: `No se pudo eliminar la notificación. Detalle: ${
                        ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError
                    }`,
                    autoClose: 12000,
                });
            } finally {
                setDeleteModalOpen(false);
                setLoading(false);
            }
        } else {
            setSelectedSpecialtiesId(idSpecialtyEvent);
            setDeleteModalOpen(true);
        }
    };

    const handleSubmit = async (values) => {
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authToken,
            },
        };

        try {
            setLoading(true);
            // Check if it's an edit or a new event
            if (initialValuesEdit) {
                await editEvent(values, config);
            } else {
                await createEvent(values, config);
            }

            showNotification({
                color: "green",
                status: "success",
                title: "¡Excelente! Operación exitosa.",
                message: `El evento ${values.name} ha sido creado / editado con éxito.`,
            });

            setUpdateData(!updateData);
        } catch (error) {
            showNotification({
                color: "red",
                status: "error",
                title: "Operación fallida.",
                message: `No se pudo crear / editar el evento. Detalle: ${
                    ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError
                }`,
                autoClose: 12000,
            });
        } finally {
            setLoading(false);
            setDisplayForm(false);
            setInitialValuesEdit(null);
        }
    };

    // DATA INITIALIZATION
    const init = async () => {
        const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/specialties`;
        const config = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authToken}`,
            },
        };
        const specialtiesResponse = await axios.get(url, config);
        setSpecialtiesData(specialtiesResponse.data);
    };

    useEffect(() => {
        init();
    }, [updateData]);

    return (
        <div className="academicSpecialties__container">
            {displayForm && (
                <ContentCreator
                    title={initialValuesEdit ? "Editar evento" : "Crear evento"}
                    submitFunc={(values) => handleSubmit(values)}
                    onCancel={() => {
                        setDisplayForm(false);
                        setInitialValuesEdit(null);
                    }}
                    initialValues={initialValuesEdit ?? null}
                    renderPreview={(_form) => (
                        <SpecialtiesCard
                            title={_form.form.values.name}
                            photo={_form.form.values.photo}
                        />
                    )}
                    renderForm={(_form) => (
                        <AdminSpecialtiesForm form={_form.form} loading={loading}/>
                    )}
                />
            )}
            {SpecialtiesData && (
                <TanStackTable
                    data={SpecialtiesData}
                    columns={columns}
                    editFunc={editSpecialtyEvent}
                    onDelete={deleteSpecialty}
                    displayForm={displayForm}
                    addButtonFunc={() => {
                        setDisplayForm(!displayForm);
                        if (displayForm) setInitialValuesEdit(null);
                    }}
                />
            )}

            <DeleteConfirmationModal
                opened={deleteModalOpen}
                loading={loading}
                onClose={() => {
                    setDeleteModalOpen(false);
                    setSelectedSpecialtiesId(null);
                }}
                onCancel={() => {
                    setDeleteModalOpen(false);
                    setSelectedSpecialtiesId(null);
                }}
                onConfirm={deleteSpecialty}
            />

            <Modal classNames={{title:"acaSpecialties-modalTitle"}} opened={openModal} onClose={() => setOpenModal(false)} title="Descripción">
                <div>
                    <p className="acaSpecialties-modalBody">{modalContent}</p>
                </div>
            </Modal>
        </div>
    );
}

export default AdminSpecialtiesContainer;
