import TanStackTable from '../../../../components/Table/TanStackTable/TanStackTable'
import useSalesInstallments from '../../../../hooks/Sales/SalesInstallments/useSalesInstallments'
import './SalesInstallmentsView.css'
import StudentModal from "../../../../components/StudentModal/StudentModal";
import ContentCreator from "../../../../components/ContentCreator/ContentCreator";
import {SalesInstallmentsPreview} from "../../SalesInstallmentsPreview/SalesInstallmentsPreview";
import SalesInstallmentsForm from "../../SalesInstallmentsForm/SalesInstallmentsForm";
import { DatePickerInput } from '@mantine/dates';

export default function SalesInstallmentsView() {

    const {
        data,
        columns,
        isLoading,
        studentData,
        setStudentData,
        editInstallments,
        displayForm,
        setDisplayForm,
        initialValuesEdit,
        setInitialValuesEdit,
        editPaymentStateAction,
        handleSubmitForm,
        dateFilter,
        setDateFilter
    } = useSalesInstallments()

    return (
        <section className='SalesInstallmentsView-main-container'>
            <div className="AdministrativeExpensesView__filters">
                <DatePickerInput
                    classNames={{ root: "moderationContainer-DatePicker-root" }}
                    type="range"
                    label="Mostrando datos de:"
                    value={dateFilter}
                    onChange={(value) => setDateFilter(value)}
                />
                <span>
                    * Por defecto se muestran datos del mes actual (desde el 1ro hasta la fecha actual)
                </span>
            </div>
            {displayForm && (
                <ContentCreator
                    title={"Editar cuotas"}
                    submitFunc={(values) => handleSubmitForm(values)}
                    onCancel={() => {
                        setDisplayForm(false)
                        setInitialValuesEdit(null)
                    }}
                    initialValues={initialValuesEdit}
                    renderPreview={(_form) => (
                        <SalesInstallmentsPreview rowData={_form.form.values} />
                    )}
                    renderForm={(_form) => (
                        <SalesInstallmentsForm form={_form.form} loading={isLoading} />
                    )}
                />
            )}

            {!displayForm && (
            < TanStackTable
                data={data}
                columns={columns}
                loading={isLoading}
                editFunc={editInstallments}
                actionsFunc={(rowData) => editPaymentStateAction(rowData)}
            />
            )}

            {studentData && (
                <StudentModal
                    open={studentData}
                    setOpen={() => setStudentData(null)}
                    studentData={studentData}
                />
            )}
        </section>
    )
}