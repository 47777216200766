const sidebarURLs = () => {
  return [
        {label: "Administracion", link: "/admin/administracion", requiredPermision: "administracion",},
        {
            label: "Academico",
            initiallyOpened: true,
            requiredPermision: "academico",
            link: "/admin/academico",
        },
        {
            label: "Ventas",
            requiredPermision: "ventas",
            link: "/admin/ventas",
        },
        {label: "Marketing", link: "/admin/marketing", requiredPermision: "marketing",},
        {label: "Moderacion", link: "/admin/moderacion", requiredPermision: "moderacion",},
        {label: "Soporte", link: "/admin/soporte", requiredPermision: "soporte",},
    ];
};

export default sidebarURLs;
