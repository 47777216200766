import React, {useContext} from 'react';
import "./LessonPreview.css";
import LessonCard from "../../../../components/LessonCard/LessonCard";
import {MaterialsTable} from "../../../../components/MaterialsTable/MaterialsTable";
import AppContext from "../../../../contexts/AppContext";

function LessonPreview(props) {
    const {form} = props;
    const {materialsData} = useContext(AppContext);
    const removeMaterialRow = (id) => {
        // Remove the material from the list
        const newMaterials = form.values.materials.filter((material) => material._id !== id);
        form.setFieldValue("materials", newMaterials);
    }

    const getMaterialData = () => {
      //   Get the materials data in form.values.materials for the table
        return form.values.materials.map((material) => {
         if(material.isNew) return material
         return materialsData.find((data) => data._id === material._id);
        });
    }

    return (
        <>
            <LessonCard
                name={form.values.name}
                roomUrl={form.values.roomUrl}
                initDate={form.values.initDate}
            />
            <MaterialsTable
                data={getMaterialData()}
                height={200}
                removeRow={removeMaterialRow}
            />
        </>
    );
}

export default LessonPreview;