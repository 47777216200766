import useAdministrativeDashboard from '../../../../hooks/Administrative/Dashboard/useAdministrativeDashboard'
import './AdministrativeDashboard.css'
import AdDasboardCourses from '../AdDasboardCourses/AdDasboardCourses'
// import AdDasboardStudents from '../AdDasboardStudents/AdDasboardStudents'
import AdDasboardIncomes from '../AdDasboardIncomes/AdDasboardIncomes'
import AdDashboardCoursesSkeleton from '../AdministrativeDashboardSkeletons/AdDashboardCoursesSkeleton'
// import AdDashboardStudentsSkeleton from '../AdministrativeDashboardSkeletons/AdDashboardStudentsSkeleton'
import AdDashboardIncomesSkeleton from '../AdministrativeDashboardSkeletons/AdDashboardIncomesSkeleton'

export default function AdministrativeDashboard() {
   const { coursesInfo, incomesInfo, isLoading } = useAdministrativeDashboard()

   return (
      <div className='AdministrativeDashboard-wrapper-container'>

         {isLoading || !coursesInfo
            ? < AdDashboardCoursesSkeleton />
            : < AdDasboardCourses data={coursesInfo} />
         }

         {/* {isLoading || !studentsInfo
            ? < AdDashboardStudentsSkeleton />
            : < AdDasboardStudents data={studentsInfo} />
         } */}

         {isLoading || !incomesInfo
            ? < AdDashboardIncomesSkeleton />
            : < AdDasboardIncomes data={incomesInfo} />
         }

      </div>
   )
}
