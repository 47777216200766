import React from 'react';
import "./Pricing.css";
import {Button, Grid, NumberInput} from "@mantine/core";

function PricingForm(props) {
    const {form, setCreateSubscriptionModalOpen} = props;
    const removeSubscriptionPlan = (index) => {
        const newSubscriptionPlans = form.values.subscriptionPlans.filter((_, i) => i !== index)
        form.setFieldValue("subscriptionPlans", newSubscriptionPlans)
    }

    return (
        <Grid style={{width: "100%"}}>
            <Grid.Col span={6}>
                <NumberInput
                    label="Precio en USD"
                    {...form.getInputProps('price.usd')}
                    min={0}
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <NumberInput
                    label="Precio en ARS"
                    {...form.getInputProps('price.ars')}
                    min={0}
                />
            </Grid.Col>
            <Grid.Col span={12}>
                <h2 className="pricingForm__title">Planes de pago (cuotas):</h2>
            </Grid.Col>
            <Grid.Col span={12}>
                {form.values.subscriptionPlans.length === 0 && (
                    <p className="pricingForm__text pricingForm__text--noContent">No hay planes de suscripción creados</p>
                )}
                {form.values.subscriptionPlans.map((subscription, index) => (
                    <div key={index} className="pricingForm__container" style={{display: "flex", justifyContent: "space-between"}}>
                        <p className="pricingForm__text">
                            Plan de suscripción {index + 1}: {subscription.total_cycles} cuotas de ${subscription.amount_per_cycle}
                        </p>
                        <Button
                            type="button"
                            variant="outline"
                            onClick={() => removeSubscriptionPlan(index)}
                        >
                            Eliminar
                        </Button>
                    </div>
                ))}
            </Grid.Col>
            {/*<Grid.Col span={12} align="center">*/}
            {/*    <Button*/}
            {/*        className="pricingForm__button"*/}
            {/*        style={{alignSelf: "center"}}*/}
            {/*        onClick={() => setCreateSubscriptionModalOpen(true)}*/}
            {/*    >*/}
            {/*        Crear plan de suscripción*/}
            {/*    </Button>*/}
            {/*</Grid.Col>*/}
        </Grid>
    );
}

export default PricingForm;