import { showNotification } from "@mantine/notifications"

const ALLOWED_FILES_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf']
const MAX_SIZE_IN_BYTES = 5 * 1024 * 1024 // 5 MB

export const fileToBase64 = async (file) => {
    if (file?.size > MAX_SIZE_IN_BYTES) {
        showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema al cargar el archivo.",
            message: `Detalle: el archivo es demasiado grande. Prueba con otro más pequeño.`
        })
        return null;
    }

    if (!ALLOWED_FILES_TYPES.includes(file?.type)) {
        showNotification({
            color: "red",
            status: "error",
            title: "Ha ocurrido un problema al cargar el archivo.",
            message: `El archivo debe ser en formato JPG, JPEG, PNG o PDF.`
        })
        return null;
    }

    const base64File = await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });

    return base64File;
}
