import './MktgNewsCreatorForm.css'
import { Button, Checkbox, FileInput, Group, TextInput } from '@mantine/core'
import { IconPhoto } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import {renderFile} from "../../../../utils/renderFile";

export default function MktgNewsCreatorForm({
   form,
   isLoading,
}) {
   const [active, setActive] = useState(form.values.active);

   useEffect(() => {
      setActive(form.values.active);
   }, [form.values.active]);

   return (
      <div className='MktgNewsCreatorForm-wrapper'>
         <TextInput
            {...form.getInputProps("title")}
            label='Título'
            w={'100%'}
            disabled={isLoading}
         />

         <TextInput
            {...form.getInputProps("text")}
            label='Descripción'
            w={'100%'}
            disabled={isLoading}
         />

         <TextInput
            {...form.getInputProps("url")}
            label='Enlace'
            w={'100%'}
            disabled={isLoading}
         />

         <Group w='100%' align='end'>
            <FileInput
               {...form.getInputProps("image")}
               label="Imagen de fondo"
               w={'max-content'}
               placeholder='Cargar imagen'
               leftSection={<IconPhoto size={30} />}
               disabled={isLoading}
               onChange={(file) => {
                   renderFile(file).then((_fileBase64) => {
                        form.setFieldValue("image", _fileBase64);
                   });
               }}
               accept='image/*'
            />

            <Checkbox
               label='Es activo'
               checked={active}
               onChange={(e) => {
                  setActive(e.currentTarget.checked);
                  form.setFieldValue('active', e.currentTarget.checked);
               }}
               disabled={isLoading}
            />
         </Group>

         <Button
            type="submit"
            className="eventCalendarForm__form__button"
            loading={isLoading}
            w='max-content'
         >
            Guardar novedad
         </Button>
      </div>
   );
}
