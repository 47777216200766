import React, { useContext, useEffect, useState } from "react";
import "./AdminSponsorsContainer.css";
import axios from "axios";
import AuthContext from "../../../../contexts/AuthContext";
import DeleteConfirmationModal from "../../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import TanStackTable from "../../../../components/Table/TanStackTable/TanStackTable";
import { createColumnHelper } from "@tanstack/react-table";
import ContentCreator from "../../../../components/ContentCreator/ContentCreator";
import showNotification from "../../../../utils/showNotification";
import { ERROR_HANDLER } from "../../../../utils/error-handler";
import AdminSponsorsForm from '../AdminSponsorsForm/AdminSponsorsForm';
import SponsorSection from "../../../../components/SponsorSection/SponsorSection";
import useS3 from "../../../../hooks/useS3";
import moment from "moment";


const createEvent = async (values, config) => {
  const jsonData = {
    data: {
      ...values,
    },
  };
  return axios.post(
    `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/sponsors`,
    jsonData,
    config
  );
};
const editEvent = async (values, config) => {
  const jsonData = {
    item: {_id: values.id},
    field: {
      ...values,
    },
  };
  return axios.put(
    `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/sponsors`,
    jsonData,
    config
  );
};

function AdminBankAccountContainer() {
  const { authToken } = useContext(AuthContext);
  const [adminSponsorsData, setAdminSponsorsData] = useState(null);
  const [displayForm, setDisplayForm] = useState(false);
  const [initialValuesEdit, setInitialValuesEdit] = useState(null);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedSponsorId, setSelectedSponsorId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const { uploadToS3 } = useS3();

  // COLUMN DEFINITIONS
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: "Nombre",
      enableColumnFilter: true,
      enableSorting: false,
      filter: "text",
      filterFn: "includesString",
    }),
    columnHelper.accessor("description", {
      cell: (info) => info.getValue(),
      header: "Descripcion",
    }),
    columnHelper.accessor("image_url", {
      cell: (info) => info.getValue(),
      header: "Link imagen",
    }),
    columnHelper.accessor("is_monthly_sponsor", {
      cell: (info) => {
        const value = info.getValue()
        return value ? "Si" : "No";},
      header: "Sponsor del mes",
    }),
    columnHelper.accessor("link", {
      cell: (info) => info.getValue(),
      header: "Link",
    }),
    columnHelper.accessor("location", {
      cell: (info) => {
      const value = info.getValue()
      return value === "/" ? "Inicio" : "Nosotros";
    },
      header: "Ubicacion",
    }),
  ];

  // TABLE FUNCTIONS
  const editSponsorEvent = (rowData) => {
    if (rowData) {
      setInitialValuesEdit({
        id: rowData._id,
        name: rowData.name,
        description: rowData.description,
        image_url: rowData.image_url,
        is_monthly_sponsor: rowData.is_monthly_sponsor,
        link: rowData.link,
        location: rowData.location,
        logo_url: rowData.logo_url,
      });
      setDisplayForm(true);
    }
  };

  const deleteSponsorEvent = async (idBankAccount = null) => {
    if (deleteModalOpen) {
      try {
        setLoading(true);
        await axios.delete(
          `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/sponsors`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + authToken,
            },
            data: { _id: selectedSponsorId._id },
          }
        );
        showNotification({
            color: "green",
            status: "success",
            title: "¡Operación exitosa!",
            message: "El elemento seleccionado ha sido correctamente eliminado.",
            autoClose: 12000,
          });
        setUpdateData(!updateData);
      } catch (error) {
        console.error(error);
        showNotification({
          color: "red",
          status: "error",
          title: "Operación fallida.",
          message: `No se pudo eliminar el sponsor. Detalle: ${
            ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError
          }`,
          autoClose: 12000,
        });
      } finally {
        setDeleteModalOpen(false);
        setLoading(false);
      }
    } else {
        setSelectedSponsorId(idBankAccount);
      setDeleteModalOpen(true);
    }
  };

  const handleSubmit = async (values) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
    };
    try {
      setLoading(true);
      if (values.image_url && values.logo_url) {
        const clearTitle = values.name.replace(/[^\w\s]/g, '').trim();
        const imageUrl = await uploadToS3({
          image: values.image_url,
          fileName: `${clearTitle}-${moment().format('DD-MM-YYYY')}`,
          fileDirectory: "public/images/sponsors", 
          isRequired: true,
        });
        values.image_url = imageUrl;

        const logoUrl = await uploadToS3({
          image: values.logo_url,
          fileName: `${clearTitle}-${moment().format('DD-MM-YYYY')}`,
          fileDirectory: "public/images/sponsors", 
          isRequired: true,
        });
        values.logo_url = logoUrl;
        // Check if it's an edit or a new event
        if (initialValuesEdit) {
          await editEvent(values, config);
        } else {
          await createEvent(values, config);
        }
      }

      showNotification({
        color: "green",
        status: "success",
        title: "¡Excelente! Operación exitosa.",
        message: `El evento ${values.name} ha sido creado / editado con éxito.`,
      });

      setUpdateData(!updateData);
    } catch (error) {
      showNotification({
        color: "red",
        status: "error",
        title: "Operación fallida.",
        message: `No se pudo crear / editar el evento. Detalle: ${
          ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError
        }`,
        autoClose: 12000,
      });
    } finally {
      setLoading(false);
      setDisplayForm(false);
      setInitialValuesEdit(null);
    }
  };

  // DATA INITIALIZATION
  const init = async () => {
    const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/sponsors`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    const sponsorResponse = await axios.get(url, config);
    setAdminSponsorsData(sponsorResponse.data);
  };

  useEffect(() => {
    init();
  }, [updateData]);
  return (
    <div className="SponsorContainer__container">
      {displayForm && (
        <ContentCreator
          title={initialValuesEdit ? "Editar evento" : "Crear evento"}
          submitFunc={(values) => handleSubmit(values)}
          onCancel={() => {
            setDisplayForm(false);
            setInitialValuesEdit(null);
          }}
          initialValues={initialValuesEdit ?? null}
          renderPreview={(_form) => (
            <SponsorSection
              name={_form.form.values.name}
              description={_form.form.values.description}
              image_url={_form.form.values.image_url}
              is_monthly_sponsor={_form.form.values.is_monthly_sponsor}
              link={_form.form.values.link}
              location={_form.form.values.location}
              logo_url={_form.form.values.logo_url}
            />
          )}
          renderForm={(_form) => (
            <AdminSponsorsForm initialValues={initialValuesEdit} form={_form.form} loading={loading} />
          )}
        />
      )}
      {adminSponsorsData && (
        <TanStackTable
          loading={loading}
          data={adminSponsorsData}
          columns={columns}
          onDelete={deleteSponsorEvent}
          editFunc={editSponsorEvent}
          displayForm={displayForm}
          addButtonFunc={() => {
            setDisplayForm(!displayForm);
            if (displayForm) setInitialValuesEdit(null);
          }}
        />
      )}

      <DeleteConfirmationModal
        opened={deleteModalOpen}
        loading={loading}
        onClose={() => {
          setDeleteModalOpen(false);
          setSelectedSponsorId(null);
        }}
        onCancel={() => {
          setDeleteModalOpen(false);
          setSelectedSponsorId(null);
        }}
        onConfirm={deleteSponsorEvent}
      />
    </div>
  );
}

export default AdminBankAccountContainer;
