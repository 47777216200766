import React from "react";
import "./SalesDashboardStats.css";
import { Paper, Group, Text } from "@mantine/core";
import {
  IconUser,
  IconSchool,
  IconNotebook,
  IconVocabulary,
  IconBook,
} from "@tabler/icons-react";

const icons = {
  school: IconSchool,
  notebook: IconNotebook,
  vocabulary: IconVocabulary,
  book: IconBook,
};

function getRandomIcon() {
  const randomIndex = Math.floor(Math.random() * Object.keys(icons).length);
  const randomIconKey = Object.keys(icons)[randomIndex];
  return icons[randomIconKey];
}

function SalesDashboardStats({ item }) {
  const Icon = getRandomIcon();

  return (
    <div className="salesDashboardStats-father-div">
      <Paper classNames={{root:"salesDashboardStats-paper-root"}} withBorder p="md" radius="md">
        <div className="salesDashboardStats-content-father">
          <Text size="xs" c="dimmed">
            {item.name}
          </Text>
          <Icon className="salesDashboardStats-icon" size="1.4rem" stroke={1.5} />
        </div>

        <Group gap="xs" mt={25}>
          <p className="salesDashboardStats-students"><IconUser className="salesDashboardStats-students-icon"/> {item.quantity}</p>
        </Group>
      </Paper>
    </div>
  );
}

export default SalesDashboardStats;
