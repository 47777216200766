import React, {useContext} from 'react';
import "./Exam.css";
import {Button, Grid, NumberInput, Select} from "@mantine/core";
import {DateInput} from "@mantine/dates";
import {
    IconCalendarStats,
    IconCalendarTime,
    IconHourglassHigh,
    IconNotes,
    IconPercentage,
    IconEdit
} from "@tabler/icons-react";
import AcademicContext from "../../../../../contexts/AcademicContext";
import MantineSwitchButton from "../../../../../components/MantineSwitchButton/MantineSwitchButton";

function ExamForm(props) {
    const {form, setCreateQuestionnaireModal} = props;
    const {questionnairesData} = useContext(AcademicContext);
    const allQuestionnairesSelect = questionnairesData?.map(item => (
        {value: item._id, label: item.name}
    ));

    return (
        <Grid w='100%'>
            <Grid.Col span={12}>
                <MantineSwitchButton
                    checked={form.values.hasExam}
                    onChange={() =>
                        form.setFieldValue("hasExam", !form.values.hasExam)
                    }
                    label="¿Tiene examen?"
                />
            </Grid.Col>
            {form.values.hasExam && (
                <>
                    <Grid.Col span={{base: 12, sm: 6}}>
                        <DateInput
                            {...form.getInputProps('exam.initDate')}
                            label='Fecha de inicio'
                            leftSection={<IconCalendarStats/>}
                        />
                    </Grid.Col>
                    <Grid.Col span={{base: 12, sm: 6}}>
                        <DateInput
                            leftSection={<IconCalendarTime/>}
                            label='Fecha de finalización'
                            {...form.getInputProps('exam.endDate')}
                        />
                    </Grid.Col>
                    <Grid.Col span={{base: 12, md: 6, lg: 4}}>
                        <NumberInput
                            leftSection={<IconHourglassHigh/>}
                            label='Duración (en minutos)'
                            placeholder='60'
                            min={0}
                            max={360}
                            {...form.getInputProps('exam.duration')}
                        />
                    </Grid.Col>
                    <Grid.Col span={{base: 12, md: 6, lg: 4}}>
                        <NumberInput
                            leftSection={<IconPercentage/>}
                            label='Porcentaje de aprobación (sin %)'
                            placeholder='80'
                            min={0}
                            max={100}
                            {...form.getInputProps('exam.approvalPercentage')}
                        />
                    </Grid.Col>
                    <Grid.Col span={{base: 12, md: 6, lg: 4}}>
                        <NumberInput
                            leftSection={<IconEdit/>}
                            label='Cantidad de intentos'
                            min={1}
                            max={3}
                            {...form.getInputProps('exam.tryQuantity')}
                        />
                    </Grid.Col>
                    <Grid.Col span={9}>
                        <Select
                            data={allQuestionnairesSelect}
                            label='Cuestionario'
                            placeholder='Seleccionar'
                            leftSection={<IconNotes/>}
                            style={{flexGrow: 1}}
                            {...form.getInputProps('exam.questionary')}
                            searchable
                        />
                    </Grid.Col>
                    <Grid.Col span={3}>
                        <Button
                            style={{marginTop: '22px'}}
                            styles={{label: {whiteSpace: 'normal'}}}
                            color='var(--color-tertiary)'
                            variant='outline'
                            onClick={() => setCreateQuestionnaireModal(true)}
                        >
                            Crear nuevo cuestionario
                        </Button>
                    </Grid.Col>
                </>
            )}
        </Grid>
    );
}

export default ExamForm;