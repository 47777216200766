import { Flex, Grid } from '@mantine/core'
import ContentCreator from '../../../components/ContentCreator/ContentCreator'
import DeleteConfirmationModal from '../../../components/DeleteConfirmationModal/DeleteConfirmationModal'
import OpportunitiesCard from '../../../components/OpportunitiesCard/OpportunitiesCard'
import OpportunitiesSlide from '../../../components/OpportunitiesSlide/OpportunitiesSlide'
import OpportunityModal from '../../../components/OpportunityModal/OpportunityModal'
import TanStackTable from '../../../components/Table/TanStackTable/TanStackTable'
import useOpportunities from '../../../hooks/Marketing/Opportunities/useOpportunities'
import MkgtOpportunitiesForm from './MkgtOpportunitiesForm'
import './MktgOpportunities.css'

export default function MktgOpportunities() {
   const {
      columns,
      data,
      deleteModalOpen,
      deleteOpportunity,
      displayForm,
      editOpportunity,
      formValidation,
      handleSubmit,
      initialValuesEdit,
      isLoading,
      setDeleteModalOpen,
      setDisplayForm,
      setInitialValuesEdit,
      setSelectedOpportunityId,
      isOpportunityModal,
      setIsOpportunityModal
   } = useOpportunities()

   return (
      <section className='MktgView-layout--container MktgOpportunities-main-container'>

         {displayForm && (
            <ContentCreator
               title={initialValuesEdit ? "Editar oportunidad" : "Crear oportunidad"}
               submitFunc={(values) => handleSubmit(values)}
               onCancel={() => {
                  setDisplayForm(false)
                  setInitialValuesEdit(null)
               }}
               initialValues={initialValuesEdit ?? {
                  title: "",
                  description: "",
                  redirectUrl: "",
                  logoUrl: "",
                  bannerImageUrl: "",
                  BannerHighlight: false,
               }}
               validation={formValidation}
               renderPreview={(_form) => (
                  <Grid w='100%'  >
                     <Grid.Col span={{base: 12, md: 7, lg: 8}}>
                        < OpportunitiesSlide
                           title={_form.form.values.title}
                           description={_form.form.values.description}
                           redirectUrl={_form.form.values.redirectUrl}
                           bannerImageUrl={_form.form.values.bannerImageUrl}
                           logoUrl={_form.form.values.logoUrl}
                           handleClickCard={setIsOpportunityModal}
                           isActive={_form.form.values.BannerHighlight}
                        />
                     </Grid.Col>

                     <Grid.Col span={{base: 12, md: 5, lg: 4}}align='center' justify='center' >
                        < OpportunitiesCard
                           title={_form.form.values.title}
                           description={_form.form.values.description}
                           redirectUrl={_form.form.values.redirectUrl}
                           bannerImageUrl={_form.form.values.bannerImageUrl}
                           logoUrl={_form.form.values.logoUrl}
                           handleClickCard={setIsOpportunityModal}
                           isActive={!_form.form.values.BannerHighlight}

                        />
                     </Grid.Col>
                  </Grid>
               )}
               renderForm={(_form) => (
                  < MkgtOpportunitiesForm form={_form.form} isLoading={isLoading} />
               )}
            />
         )}

         {!displayForm && data && (
            < TanStackTable
               data={data}
               columns={columns}
               editFunc={editOpportunity}
               onDelete={deleteOpportunity}
               addButtonFunc={() => {
                  setDisplayForm(!displayForm)
                  if (displayForm) setInitialValuesEdit(null)
               }}
            />
         )}

         <DeleteConfirmationModal
            opened={deleteModalOpen}
            loading={isLoading}
            onClose={() => {
               setDeleteModalOpen(false)
               setSelectedOpportunityId(null)
            }}
            onCancel={() => {
               setDeleteModalOpen(false)
               setSelectedOpportunityId(null)
            }}
            onConfirm={deleteOpportunity}
         />

         {isOpportunityModal &&
            < OpportunityModal
               isModal={isOpportunityModal}
               setIsModal={setIsOpportunityModal}
               selectedOportunity={isOpportunityModal} />
         }
      </section>
   )
}
