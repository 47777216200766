import React, { useContext, useEffect, useState } from "react";
import "./AcademicValorationsContainer.css";
import { createColumnHelper } from "@tanstack/react-table";
import TanStackTable from "../../../components/Table/TanStackTable/TanStackTable";
import axios from "axios";
import AuthContext from "../../../contexts/AuthContext";
import TeachersTable from "./TeachersTable/TeachersTable";
import AcademicValorationsComments from "./AcademicValorationsComments/AcademicValorationsComments";
import { IconCircleFilled, IconTextPlus } from "@tabler/icons-react";
import { Badge } from "@mantine/core";

const AcademicValorationsContainer = () => {
  const { authToken } = useContext(AuthContext);
  const [valorationsData, setValorationsData] = useState(null);
  const [initialValuesEdit, setInitialValuesEdit] = useState(null);
  const [displayForm, setDisplayForm] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [teachersData, setTeachersData] = useState(null);
  const [acaUnitComments, setAcaUnitComments] = useState(null);
  const [loadComments, setLoadComments] = useState(false);
  const [currentUnitName, setCurrentUnitName] = useState("");

  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
  };

  const calculateAverageScore = (reviews) => {
    const scoreMapping = {
      good: 1,
      average: 0.5,
      bad: 0,
      notRated: null,
    };

    const categories = [
      "teachersCommit",
      "moderation",
      "forVetExperience",
      "webCampusValue",
    ];
    const totals = categories.reduce((acc, category) => {
      const validScores = reviews
        .map((review) => scoreMapping[review.data[category]])
        .filter((score) => score !== null);
      const average =
        validScores.length > 0
          ? validScores.reduce((sum, score) => sum + score, 0) /
            validScores.length
          : null;
      acc[category] = average;
      return acc;
    }, {});

    return totals;
  };

  const handleBack = () => {
    setDisplayForm(false);
    setLoadComments(false);
  };

  // COLUMN DEFINITIONS
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("name", {
      cell: (info) => info.getValue(),
      header: "Unidad Académica",
      enableColumnFilter: true,
      enableSorting: false,
      filter: "text",
    }),
    columnHelper.accessor("averageScores.teachersCommit", {
      cell: (info) => {
        const value =
          info.getValue() !== null ? info.getValue().toFixed(2) : "N/A";
        const calculateBadge =
          parseFloat(info.getValue()) >= 0.9
            ? "academicValorationsContainer-greenBadge"
            : parseFloat(info.getValue()) < 0.5
            ? "academicValorationsContainer-redBadge"
            : parseFloat(info.getValue()) >= 0.5 &&
              parseFloat(info.getValue()) <= 0.7
            ? "academicValorationsContainer-orangeBadge"
            : "academicValorationsContainer-yellowBadge";
        return (
          <div className="academicValorationsContainer-divBadge">
            {" "}
            <Badge className={calculateBadge}>{value}</Badge>{" "}
          </div>
        );
      },
      header: "Cuerpo Docente",
      enableColumnFilter: false,
      enableSorting: true,
    }),
    columnHelper.accessor("averageScores.moderation", {
      cell: (info) => {
        const value =
          info.getValue() !== null ? info.getValue().toFixed(2) : "N/A";
        const calculateBadge =
          parseFloat(info.getValue()) >= 0.9
            ? "academicValorationsContainer-greenBadge"
            : parseFloat(info.getValue()) < 0.5
            ? "academicValorationsContainer-redBadge"
            : parseFloat(info.getValue()) >= 0.5 &&
              parseFloat(info.getValue()) <= 0.7
            ? "academicValorationsContainer-orangeBadge"
            : "academicValorationsContainer-yellowBadge";
        return (
          <div className="academicValorationsContainer-divBadge">
            {" "}
            <Badge className={calculateBadge}>{value}</Badge>{" "}
          </div>
        );
      },
      header: "Moderación",
      enableColumnFilter: false,
      enableSorting: true,
    }),
    columnHelper.accessor("averageScores.forVetExperience", {
      cell: (info) => {
        const value =
          info.getValue() !== null ? info.getValue().toFixed(2) : "N/A";
        const calculateBadge =
          parseFloat(info.getValue()) >= 0.9
            ? "academicValorationsContainer-greenBadge"
            : parseFloat(info.getValue()) < 0.5
            ? "academicValorationsContainer-redBadge"
            : parseFloat(info.getValue()) >= 0.5 &&
              parseFloat(info.getValue()) <= 0.7
            ? "academicValorationsContainer-orangeBadge"
            : "academicValorationsContainer-yellowBadge";
        return (
          <div className="academicValorationsContainer-divBadge">
            {" "}
            <Badge className={calculateBadge}>{value}</Badge>{" "}
          </div>
        );
      },
      header: "Experiencia ForVet",
      enableColumnFilter: false,
      enableSorting: true,
    }),
    columnHelper.accessor("averageScores.webCampusValue", {
      cell: (info) => {
        const value =
          info.getValue() !== null ? info.getValue().toFixed(2) : "N/A";
        const calculateBadge =
          parseFloat(info.getValue()) >= 0.9
            ? "academicValorationsContainer-greenBadge"
            : parseFloat(info.getValue()) < 0.5
            ? "academicValorationsContainer-redBadge"
            : parseFloat(info.getValue()) >= 0.5 &&
              parseFloat(info.getValue()) <= 0.7
            ? "academicValorationsContainer-orangeBadge"
            : "academicValorationsContainer-yellowBadge";
        return (
          <div className="academicValorationsContainer-divBadge">
            {" "}
            <Badge className={calculateBadge}>{value}</Badge>{" "}
          </div>
        );
      },
      header: "Campus",
      enableColumnFilter: false,
      enableSorting: true,
    }),
  ];

  const handleRenderComments = (rowData) => {
    if (rowData) {
      const comments = rowData.reviews
        .map((review) => ({
          user: review.user,
          extraCommit: review.data.extraCommit,
        }))
        .filter((comment) => comment.extraCommit !== ""); // Filtrar comentarios vacíos

      setAcaUnitComments(comments);
      setCurrentUnitName(rowData.name);
      setLoadComments(true);
    }
  };

  const handleRenderTable = (rowData) => {
    if (rowData) {
      const initialValues = {
        ...rowData,
      };
      const reviews = rowData.reviews;
      const teacherData = reviews.map((review) => review.data.teachers).flat();
      const teacherScores = teacherData.reduce((acc, teacher) => {
        if (!acc[teacher.name]) {
          acc[teacher.name] = {
            name: teacher.name,
            good: 0,
            average: 0,
            bad: 0,
            notRated: 0,
            totalScore: 0,
          };
        }
        if (teacher.value === "good") acc[teacher.name].good += 1;
        if (teacher.value === "average") acc[teacher.name].average += 1;
        if (teacher.value === "bad") acc[teacher.name].bad += 1;
        if (teacher.value === "notRated") acc[teacher.name].notRated += 1;
        acc[teacher.name].totalScore += 1;
        return acc;
      }, {});

      const finalTeacherData = Object.values(teacherScores).map((teacher) => ({
        ...teacher,
        averageScore:
          ((teacher.good + teacher.average * 0.5 + teacher.bad * 0) /
            (teacher.totalScore - teacher.notRated)) *
          100,
      }));

      setInitialValuesEdit(initialValues);
      setTeachersData(finalTeacherData);
      setCurrentUnitName(rowData.name);
      setDisplayForm(true);
    }
  };

  // DATA INITIALIZATION
  const init = async () => {
    const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/reviews`;

    const valorationsInfo = await axios.get(url, axiosConfig);
    const getValorationsData = valorationsInfo.data.map((unit) => {
      const averageScores = calculateAverageScore(unit.reviews);

      return {
        ...unit,
        averageScores,
        reviewsCount: unit.reviews.length,
      };
    });

    setValorationsData(getValorationsData);
  };

  useEffect(() => {
    init();
  }, [updateData]);

  return (
    <div className="academicValorationsContainer__container">
      {!displayForm && !loadComments && (
        <TanStackTable
          data={valorationsData}
          columns={columns}
          actionsFunc={(rowData) => (
            <IconTextPlus
              className="academicValorationsContainer-iconComments"
              onClick={() => handleRenderComments(rowData)}
            />
          )}
          editFunc={handleRenderTable}
        />
      )}
      {displayForm && (
        <TeachersTable
          data={teachersData}
          reviewsCount={initialValuesEdit.reviews.length}
          unitName={currentUnitName}
          onBack={handleBack}
        />
      )}
      {loadComments && (
        <AcademicValorationsComments
          comments={acaUnitComments}
          unitName={currentUnitName}
          onBack={handleBack}
        />
      )}
      {!displayForm && !loadComments && (
        <div className="academicValorationsContainer-colorReferences">
          <div className="academicValorationsContainer-colorReferences-div">
            <span className="academicValorationsContainer-colorReferences-span">
              <IconCircleFilled className="academicValorationsContainer-colorReferences-IconGreen" />
              <p className="academicValorationsContainer-colorReference-p-Styling">
                = + de 0.90.
              </p>
            </span>
            <span className="academicValorationsContainer-colorReferences-span">
              <IconCircleFilled className="academicValorationsContainer-colorReferences-IconYellow" />
              <p className="academicValorationsContainer-colorReference-p-Styling">
                = menos de 0.90 y mas de 0.70.
              </p>
            </span>
            <span className="academicValorationsContainer-colorReferences-span">
              <IconCircleFilled className="academicValorationsContainer-colorReferences-IconOrange" />
              <p className="academicValorationsContainer-colorReference-p-Styling">
                = menos de 0.70 y mas de 0.50.
              </p>
            </span>
            <span className="academicValorationsContainer-colorReferences-span">
              <IconCircleFilled className="academicValorationsContainer-colorReferences-IconRed" />
              <p className="academicValorationsContainer-colorReference-p-Styling">
                = - de 0.50.
              </p>
            </span>
          </div>
          <div className="academicValorationsContainer-descriptionReference-div">
            <p className="academicValorationsContainer-descriptionReference">
              Porcentajes definidos en una escala de 0 a 1.
            </p>
            <p className="academicValorationsContainer-descriptionReference">
              Se han cuantificado los valores de la encuesta, en donde "buena" =
              1, "regular" = 0,5 y "mala" = 0.
            </p>
            <p className="academicValorationsContainer-descriptionReference">
              La suma se divide por el total y se presenta en la tabla el
              promedio resultante.
            </p>
          </div>
        </div>
      )}
      {displayForm && !loadComments && (
        <div className="academicValorationsContainer-colorReferences">
          <div className="academicValorationsContainer-colorReferences-div">
            <span className="academicValorationsContainer-colorReferences-span">
              <IconCircleFilled className="academicValorationsContainer-colorReferences-IconGreen" />
              <p className="academicValorationsContainer-colorReference-p-Styling">
                = + de 90%.
              </p>
            </span>
            <span className="academicValorationsContainer-colorReferences-span">
              <IconCircleFilled className="academicValorationsContainer-colorReferences-IconYellow" />
              <p className="academicValorationsContainer-colorReference-p-Styling">
                = menos de 0.90 y mas de 0.70.
              </p>
            </span>
            <span className="academicValorationsContainer-colorReferences-span">
              <IconCircleFilled className="academicValorationsContainer-colorReferences-IconOrange" />
              <p className="academicValorationsContainer-colorReference-p-Styling">
                = menos de 70% y mas de 50%.
              </p>
            </span>
            <span className="academicValorationsContainer-colorReferences-span">
              <IconCircleFilled className="academicValorationsContainer-colorReferences-IconRed" />
              <p className="academicValorationsContainer-colorReference-p-Styling">
                = - 50%.
              </p>
            </span>
          </div>
          <div className="academicValorationsContainer-descriptionReference-div">
            <p className="academicValorationsContainer-descriptionReference">
              Se presenta en cada columna la sumatoria de votos de cada
              categoría que recibio el profesor por la totalidad de los alumnos
              que completaron la encuesta.
            </p>
            <p className="academicValorationsContainer-descriptionReference">
              Se han cuantificado los valores de la encuesta, en donde "buena" =
              1, "regular" = 1 y "mala" = 1.
            </p>
            <p className="academicValorationsContainer-descriptionReference">
              El porcentaje se obtiene de cuantificar los valores en donde
              "buena" = 1, "regular" = 0,5 y "mala" = 0. La suma se divide por
              el total y se presenta en la tabla el promedio resultante.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AcademicValorationsContainer;
