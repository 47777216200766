import React, { useContext, useEffect, useState } from "react";
import "./AcademicUnitsContainer.css";
import AuthContext from "../../../contexts/AuthContext";
import { createColumnHelper } from "@tanstack/react-table";
import showNotification from "../../../utils/showNotification";
import axios from "axios";
import TanStackTable from "../../../components/Table/TanStackTable/TanStackTable";
import DeleteConfirmationModal from "../../../components/DeleteConfirmationModal/DeleteConfirmationModal";
import { convertDate } from "../../../utils/convertDate";
import AcademicUnitForm from "../AcademicUnitForm/AcademicUnitForm";
import moment from "moment";
import AcademicContext from "../../../contexts/AcademicContext";
import { ERROR_HANDLER } from "../../../utils/error-handler";
import MantineSwitchButton from "../../../components/MantineSwitchButton/MantineSwitchButton";
import {
  IconCircleFilled,
  IconExclamationCircle,
  IconExternalLink,
  IconSquareX,
  IconCertificate,
  IconCertificate2,
} from "@tabler/icons-react";
import { openUrl } from "../../../utils/openUrl";
import filterFunctions from "../../../utils/filterFunctions";
import {
  Badge,
  Button,
  Flex,
  Grid,
  Modal,
  Stack,
  Tooltip,
} from "@mantine/core";
import NotesAndCertificatesTable from "../../../components/NotesAndCertificatesTable/NotesAndCertificatesTable";

const createAcademicUnit = async (values, config) => {
  let jsonData = {
    data: {
      ...values,
      name: values.name.toUpperCase(),
      description: values.description.toUpperCase(),
      initialDate: moment(values.initialDate)
        .format("DD [DE] MMMM YYYY")
        .toUpperCase(),
      endDate: moment(values.endDate).format("DD [DE] MMMM YYYY").toUpperCase(),
      closeCampusDate: moment(values.closeCampusDate)
        .format("DD [DE] MMMM YYYY")
        .toUpperCase(),
      classDays: values.classDays.toUpperCase(),
      exams: values.examId ?? null,
    },
  };
  if (values.initBreak && values.endBreak) {
    jsonData.data.initBreak = moment(values.initBreak)
      .format("DD [DE] MMMM YYYY")
      .toUpperCase();
    jsonData.data.endBreak = moment(values.endBreak)
      .format("DD [DE] MMMM YYYY")
      .toUpperCase();
  }

  if (values.uploadWithCSV) {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/newCreateAcademicUnit`,
      jsonData,
      config
    );
  } else {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/createAcademicUnit`,
      jsonData,
      config
    );
  }
};
const editAcademicUnit = async (values, config, _lessonsData) => {
  const modulesFixed = { ...values.modules };
  // This will retrieve the lessons to empty modules (Due to not being selected in the form)
  for (let module in modulesFixed) {
    if (modulesFixed[module].length === 0) {
      const lessons = _lessonsData.filter((lesson) =>
        lesson.modules.includes(module)
      );
      modulesFixed[module] = lessons.map((lesson) => lesson._id);
    }
  }

  const jsonData = {
    item: { _id: values._id },
    field: {
      ...values,
      name: values.name.toUpperCase(),
      description: values.description.toUpperCase(),
      comercialInformation: {
        ...values.comercialInformation,
        initialDate: moment(values.initialDate)
          .format("DD [DE] MMMM YYYY")
          .toUpperCase(),
        endDate: moment(values.endDate)
          .format("DD [DE] MMMM YYYY")
          .toUpperCase(),
        classDays: values.classDays.toUpperCase(),
      },
      closeCampusDate: moment(values.closeCampusDate)
        .format("DD [DE] MMMM YYYY")
        .toUpperCase(),
      modules: modulesFixed,
      exams: values.examId ?? null,
    },
  };

  if (values.initBreak && values.endBreak) {
    jsonData.field.comercialInformation.initBreak = moment(values.initBreak)
      .format("DD [DE] MMMM YYYY")
      .toUpperCase();
    jsonData.field.comercialInformation.endBreak = moment(values.endBreak)
      .format("DD [DE] MMMM YYYY")
      .toUpperCase();
  }

  return axios.put(
    `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/updateAcademicUnit`,
    jsonData,
    config
  );
};

const getIfEnabled = (value) => {
  switch (value) {
    case "Open":
      return true;
    case "Closed":
      return false;
    default:
      return null;
  }
};

const getDateBadge = (date) => {
  const value = convertDate(date, "date");
  const now = moment();
  const diffInDays = moment(value).diff(now, "days");

  let color;

  if (diffInDays < 0) {
    color = "rgb(150, 61, 61)"; // var(--color-badge-red)
  } else if (diffInDays <= 15) {
    color = "rgb(197, 189, 132)"; // var(--color-badge-yellow)
  } else {
    color = "rgb(158, 192, 152)"; // var(--color-badge-green)
  }

  return (
    <div className="acaUnit-endDate-container">
      <Badge color={color} size="lg">
        {moment(value).format("DD/MM/YYYY")}
      </Badge>
    </div>
  );
};

const getAcademicUnitUrl = (academicUnitData) => {
  const disabled = academicUnitData.state === "Open" ? "" : "deshabilitados/";
  return `https://forvet.org/curso/${disabled}${academicUnitData._id}`;
};

function AcademicUnitsContainer() {
  const { authToken } = useContext(AuthContext);
  const { lessonsData, handleModified } = useContext(AcademicContext);
  const [academicUnitsData, setAcademicUnitsData] = useState(null);
  const [displayForm, setDisplayForm] = useState(false);
  const [displayNotesAndCertificates, setDisplayNotesAndCertificates] =
    useState(false);
  const [notesAndCertificatesData, setNotesAndCertificatesData] =
    useState(null);
  const [initialValuesEdit, setInitialValuesEdit] = useState(null);
  const [acaUnitName, setAcaUnitName] = useState();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedAcademicUnitId, setSelectedAcademicUnitId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [actionButtonModal, setActionButtonModal] = useState(null);

  // COLUMN DEFINITIONS
  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor("comercialInformation.initialDate", {
      cell: (info) => convertDate(info.getValue()),
      header: "Inicio",
      enableColumnFilter: true,
      enableSorting: false,
      size: 120,
      filter: "date",
      filterFn: (row, id, filterValue) => {
        if (!filterValue) return true;
        const [startDate, endDate] = filterValue;
        const date = convertDate(
          row.original.comercialInformation.initialDate,
          "date"
        );
        return date >= startDate && date <= endDate;
      },
    }),
    columnHelper.accessor("type", {
      cell: (info) => info.getValue().toUpperCase(),
      header: "Tipo",
      enableColumnFilter: true,
      enableSorting: false,
      filter: "select",
      size: 104,
      filterOptions: [
        { value: "Curso", label: "Curso" },
        { value: "Masterclass", label: "Masterclass" },
        { value: "Diplomado", label: "Diplomado" },
        { value: "Posgrado", label: "Posgrado" },
        { value: "Webinar", label: "Webinar" },
        { value: "Taller", label: "Taller" },
      ],
      filterFn: filterFunctions.selectFilter,
    }),
    columnHelper.accessor("comercialInformation.name", {
      cell: (info) => {
        const value = info.getValue().toUpperCase();
        setAcaUnitName(value);
        return value;
      },
      header: "Nombre",
      enableColumnFilter: true,
      enableSorting: false,
      filter: "text",
      filterFn: (row, id, filterValue) => {
        if (!filterValue) return true;
        return row.original.comercialInformation.name
          .toLowerCase()
          .includes(filterValue.toLowerCase());
      },
    }),
    columnHelper.accessor("comercialInformation.endDate", {
      cell: (info) => getDateBadge(info.getValue()),
      header: "Cierre",
      enableColumnFilter: true,
      enableSorting: false,
      filter: "date",
      filterFn: (row, id, filterValue) => {
        if (!filterValue) return true;
        const [startDate, endDate] = filterValue;
        const date = convertDate(
          row.original.comercialInformation.endDate,
          "date"
        );
        return date >= startDate && date <= endDate;
      },
      size: 120,
    }),
    columnHelper.accessor("", {
      cell: (info) => (
        <IconExternalLink
          className="academicUnits__icon"
          size={24}
          onClick={() => {
            openUrl(getAcademicUnitUrl(info.row.original));
          }}
        />
      ),
      header: "Ver",
      size: 64,
    }),
    columnHelper.accessor("_id", {
      cell: (info) => {
        const value = info.getValue();
        return (
          <IconCertificate2
            className="academicUnits__icon"
            size={24}
            onClick={() => handleShowNotesAndCertificates(value)}
          />
        );
      },
      enableColumnFilter: false,
      enableSorting: false,
      header: "Notas",
      tooltip: "Controlar las notas y emitir certificados para los alumnos",
      size: 120,
    }),
    columnHelper.accessor("state", {
      cell: (info) => (
        <MantineSwitchButton
          checked={getIfEnabled(info.getValue())}
          onChange={() => {
            const newState = info.getValue() === "Open" ? "Closed" : "Open";
            handleEnableAcademicUnit(info.row.original, newState);
          }}
        />
      ),
      header: "Estado",
      enableColumnFilter: false,
      enableSorting: false,
      size: 64,
      tooltip: "Indica si la inscripción de estudiantes está abierta o cerrada",
    }),
  ];

  const handleBack = () => {
    setDisplayForm(false);
    setDisplayNotesAndCertificates(false);
  };

  const handleShowNotesAndCertificates = (academicUnit) => {
    setDisplayNotesAndCertificates(true);
    setNotesAndCertificatesData(academicUnit);
  };

  // TABLE FUNCTIONS
  const handleEnableAcademicUnit = async (_academicUnitData, _newState) => {
    const newStatus = _newState;
    const jsonData = {
      item: { _id: _academicUnitData._id },
      field: { state: newStatus },
    };
    try {
      setLoading(true);
      await axios.put(
        `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/academicunits`,
        jsonData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authToken,
          },
        }
      );
      showNotification({
        color: "green",
        status: "success",
        title: "¡Operación exitosa!",
        message: "El estado de la unidad académica ha sido modificado.",
        autoClose: 5000,
      });
      setUpdateData(!updateData);
    } catch (error) {
      showNotification({
        color: "red",
        status: "error",
        title: "Operación fallida.",
        message: `No se pudo actualizar el estado de la unidad académica. Detalle: ${
          error.message ||
          ERROR_HANDLER[error.request.status] ||
          ERROR_HANDLER.defaultError
        }`,
        autoClose: 12000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEditAcademicUnit = (rowData) => {
    if (rowData) {
      setInitialValuesEdit(rowData);
      setDisplayForm(true);
    }
  };

  const handleDeleteAcademicUnit = async (academicUnitRow = null) => {
    if (deleteModalOpen) {
      try {
        setLoading(true);
        await axios.delete(
          `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/academicunits`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + authToken,
            },
            data: { _id: selectedAcademicUnitId },
          }
        );
        showNotification({
          color: "green",
          status: "success",
          title: "¡Operación exitosa!",
          message: "El elemento seleccionado ha sido correctamente eliminado.",
          autoClose: 5000,
        });
        setUpdateData(!updateData);
      } catch (error) {
        showNotification({
          color: "red",
          status: "error",
          title: "Operación fallida.",
          message: `No se pudo eliminar la notificación. Detalle: ${
            error.message ||
            ERROR_HANDLER[error.request.status] ||
            ERROR_HANDLER.defaultError
          }`,
          autoClose: 12000,
        });
      } finally {
        setDeleteModalOpen(false);
        setLoading(false);
      }
    } else {
      setDeleteModalOpen(true);
      setSelectedAcademicUnitId(academicUnitRow._id);
    }
  };

  const actionButtons = (rowData) => {
    return (
      <>
        {!rowData?.isCampusClosed && (
          <Tooltip
            label="Cerrar campus de la UA"
            multiline
            color="rgba(54,54,54,1)"
          >
            <IconSquareX
              className="actionButtonsAcademicUnit--iconBtn"
              onClick={() =>
                setActionButtonModal({
                  isOpen: true,
                  _id: rowData._id,
                  academicUnitName: rowData?.comercialInformation?.name,
                  title: "¿Desea cerrar esta unidad académica?",
                  description:
                    "Esta acción implicará que los estudiantes no tengan acceso a esta unidad académica",
                  onConfirmFunction: () => closeAcademicUnit(rowData._id),
                })
              }
            />
          </Tooltip>
        )}
        {rowData?.exams?.length === 0 && !rowData?.areCertificatesDelivered && (
          <Tooltip
            label="Emitir certificados"
            multiline
            color="rgba(54,54,54,1)"
          >
            <IconCertificate
              className="actionButtonsAcademicUnit--iconBtn"
              onClick={() =>
                setActionButtonModal({
                  isOpen: true,
                  _id: rowData._id,
                  academicUnitName: rowData?.comercialInformation?.name,
                  title:
                    "¿Desea generar certificados para esta unidad académica?",
                  description:
                    "Esta acción generará que los estudiantes inscriptos puedan descargar el certificado de esta unidad académica",
                  onConfirmFunction: () => enableCertificate(rowData._id),
                })
              }
            />
          </Tooltip>
        )}
      </>
    );
  };

  const closeAcademicUnit = async (_id) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
    };

    try {
      if (!_id) return;

      setLoading(true);

      const jsonData = {
        data: {
          academicUnitId: _id,
        },
      };

      await axios.put(
        `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/closeCampus`,
        jsonData,
        config
      );

      showNotification({
        color: "green",
        status: "success",
        title: "¡Excelente! Operación exitosa.",
        message: `La unidad académica ${
          actionButtonModal.academicUnitName ?? ""
        } ha sido cerrada con éxito.`,
      });
      setUpdateData(!updateData);
    } catch (error) {
      showNotification({
        color: "red",
        status: "error",
        title: "Operación fallida.",
        message: `No se pudo cerrar la unidad académica. Detalle: ${error}`,
        autoClose: 12000,
      });
    } finally {
      setActionButtonModal({ isOpen: false });
    }
  };

  const enableCertificate = async (_id) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
    };

    try {
      if (!_id) return;

      setLoading(true);

      const jsonData = {
        academicUnitId: _id,
      };

      await axios.post(
        `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/enableCertificate`,
        jsonData,
        config
      );

      showNotification({
        color: "green",
        status: "success",
        title: "¡Excelente! Operación exitosa.",
        message: `El certificado ha sido generado con éxito.`,
      });
      setUpdateData(!updateData);
    } catch (error) {
      showNotification({
        color: "red",
        status: "error",
        title: "Operación fallida.",
        message: `No se pudo generar el certificado de la unidad académica. Detalle: ${error}`,
        autoClose: 12000,
      });
    } finally {
      setActionButtonModal({ isOpen: false });
    }
  };

  const postExam = async (values, config) => {
    const jsonData = {
      ...values.exam,
      academicUnit: [], // Default value. It is used to indicate that the exam is not associated with any academic unit yet.
      initDate: values.exam.initDate.toISOString(),
      endDate: values.exam.endDate.toISOString(),
      name: `EXÁMEN - ${values.name} - ${moment().format(
        "MMMM - YYYY"
      )}`.toUpperCase(),
    };

    return axios.post(
      `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/createExam`,
      { data: jsonData },
      config
    );
  };

  const handleSubmit = async (values) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
    };

    try {
      setLoading(true);

      // Before creating the academic unit, we need to create the exam
      if (values.hasExam) {
        const examId = await postExam(values, config);
        values.examId = examId.data.data;
      }

      // Check if it's an edit or a new event
      if (initialValuesEdit) {
        await editAcademicUnit(values, config, lessonsData);
      } else {
        await createAcademicUnit(values, config);
      }

      showNotification({
        color: "green",
        status: "success",
        title: "¡Excelente! Operación exitosa.",
        message: `La unidad académica ${values.name} ha sido creada / editada con éxito.`,
      });

      // Update data
      handleModified(); // Update lessons data
      setUpdateData(!updateData); // Update academic units data
      setDisplayForm(false);
    } catch (err) {
      const error =
        err.message ||
        err.response.data.message ||
        err ||
        "Error al crear la unidad académica";
      showNotification({
        color: "red",
        status: "error",
        title: "Operación fallida.",
        message: `No se pudo crear / editar la unidad académica. Detalle: ${error}`,
        autoClose: 12000,
      });
    } finally {
      setLoading(false);
    }
  };

  // DATA INITIALIZATION
  const init = async () => {
    const url = `${process.env.REACT_APP_BACKEND_SERVER}admin/academico/allUnits`;
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    };
    // Fetch academic units data
    const academicUnitsResponse = await axios.get(url, config);
    const filteredData = academicUnitsResponse.data
      .filter((_au) => {
        if (convertDate(_au.comercialInformation.initialDate)) return _au;
      })
      .sort((a, b) => {
        return (
          convertDate(b.comercialInformation.initialDate) -
          convertDate(a.comercialInformation.initialDate)
        );
      });
    setAcademicUnitsData(filteredData);
  };

  useEffect(() => {
    init();
  }, [updateData]);

  return (
    <div className="academicUnits__container">
      {displayForm && !displayNotesAndCertificates && (
        <AcademicUnitForm
          submitFunc={(values) => handleSubmit(values)}
          initialValues={initialValuesEdit ?? null}
          loading={loading}
          onCancel={() => {
            setDisplayForm(false);
            setInitialValuesEdit(null);
          }}
        />
      )}
      {academicUnitsData && !displayNotesAndCertificates && (
        <TanStackTable
          data={academicUnitsData}
          columns={columns}
          editFunc={handleEditAcademicUnit}
          onDelete={handleDeleteAcademicUnit}
          displayForm={displayForm}
          actionsFunc={(rowData) => actionButtons(rowData)}
          addButtonFunc={() => {
            setDisplayForm(!displayForm);
            if (displayForm) setInitialValuesEdit(null);
          }}
        />
      )}
      {academicUnitsData && displayNotesAndCertificates && (
        <NotesAndCertificatesTable
          onBack={handleBack}
          acaUnitName={acaUnitName}
          academicUnit={notesAndCertificatesData}
        />
      )}

      <DeleteConfirmationModal
        opened={deleteModalOpen}
        loading={loading}
        onClose={() => {
          setDeleteModalOpen(false);
          setSelectedAcademicUnitId(null);
        }}
        onCancel={() => {
          setDeleteModalOpen(false);
          setSelectedAcademicUnitId(null);
        }}
        onConfirm={handleDeleteAcademicUnit}
      />
      {!displayForm && academicUnitsData && !displayNotesAndCertificates && (
        <div className="acaUnit-colorReferences">
          <span className="acaUnit-colorReferences-span">
            <IconCircleFilled className="acaUnit-colorReferences-IconGreen" />
            <p className="acaUnit-colorReference-p-Styling">= +30 dias.</p>
          </span>
          <span className="acaUnit-colorReferences-span">
            <IconCircleFilled className="acaUnit-colorReferences-IconYellow" />
            <p className="acaUnit-colorReference-p-Styling">
              = menos de 15 dias.
            </p>
          </span>
          <span className="acaUnit-colorReferences-span">
            <IconCircleFilled className="acaUnit-colorReferences-IconRed" />
            <p className="acaUnit-colorReference-p-Styling">
              = Fecha de cierre ya pasada.
            </p>
          </span>
        </div>
      )}

      {actionButtonModal && (
        <Modal
          onClose={() => setActionButtonModal({ isOpen: false })}
          opened={actionButtonModal?.isOpen}
        >
          <Grid>
            <Grid.Col span={12}>
              <h3 className="closeCampusModal-title">
                {actionButtonModal.title}
              </h3>
            </Grid.Col>
            <Grid.Col>
              <Flex justify={"center"}>
                <IconExclamationCircle size={40} />
              </Flex>
            </Grid.Col>
            <Grid.Col span={12}>
              <p className="closeCampusModal-description">
                {actionButtonModal.description}
              </p>
            </Grid.Col>

            <Grid.Col span={12}>
              <Stack gap={0}>
                <p className="closeCampusModal-description">
                  Unidad académica implicada:
                </p>
                <p className="closeCampusModal-description">
                  <strong>{actionButtonModal?.academicUnitName}</strong>
                </p>
              </Stack>
            </Grid.Col>

            <Grid.Col span={12}>
              <Flex justify="center" gap={32}>
                <Button
                  variant="subtle"
                  onClick={() => setActionButtonModal({ isOpen: false })}
                >
                  Cancelar
                </Button>
                <Button
                  variant="outline"
                  onClick={actionButtonModal.onConfirmFunction}
                >
                  Confirmar
                </Button>
              </Flex>
            </Grid.Col>
          </Grid>
        </Modal>
      )}
    </div>
  );
}

export default AcademicUnitsContainer;
