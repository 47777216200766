import SalesCardDolar from "./SalesCardPriceInfoDolar/SalesCardDolar"
import SalesCardPesos from "./SalesCardPriceInfoPesos/SalesCardPesos"
import './SalesCardPriceInfo.css'

const SalesCardPriceInfo = ({usd, init, name, ars, image, classQuantity, type}) => {
  return (
    <div className="salesCardPriceInfo-wrapper"> 
        <SalesCardDolar priceDlrs={usd} date= {init} title={name} image={image} classes={classQuantity} type={type}/>
        <SalesCardPesos priceArs={ars} date= {init} title={name} image={image} classes={classQuantity} type={type} />
    </div>
  )
}
export default SalesCardPriceInfo