import React, { useContext, useState } from "react";
import {
   Grid,
   NumberInput,
   Select,
   Button,
   Switch,
   TextInput,
   Loader,
} from "@mantine/core";
import { generateRandomCode } from "../../../utils/generateRandomCode";
import AppContext from "../../../contexts/AppContext";
import "./DiscountsForm.css";

function DiscountsForm(props) {
   const { form, loading, initialValuesEdit, disabledInputs } = props;
   const { academicUnits, avals } = useContext(AppContext);

   const [usesQuantity, setUsesQuantity] = useState(
      initialValuesEdit?.max_uses || ""
   );
   const [multipleValues, setMultipleValues] = useState(
      initialValuesEdit?.max_uses > 1
   );
   const [isActive, setIsActive] = useState(initialValuesEdit?.active);

   const avalOptions = [{ value: "FORVET", label: "FORVET" }, ...avals];

   const handleUsesQuantityChange = (value) => {
      if (value === "multiple") {
         setMultipleValues(true);
         setUsesQuantity(""); // Clear the quantity input when "multiple" is selected
         form.setFieldValue("use", { max_uses: "" });
      } else if (value === "single") {
         setMultipleValues(false);
         setUsesQuantity(1);
         form.setFieldValue("use", { max_uses: 1 });
      } else {
         setMultipleValues(false);
         setUsesQuantity("");
         form.setFieldValue("use", { max_uses: "" });
      }
   };

   const handleQuantityInputChange = (event) => {
      const value = event.currentTarget.value;
      setUsesQuantity(value);
      form.setFieldValue("use", { ...form.values.use, max_uses: value });
   };

   const handleFormChange = () => {
      setIsNewForm(prevState => {
         form.setFieldValue("isAdvancedForm", !prevState)
         return !prevState
      });
      
   };

   const handleActiveDiscount = () => {
      const newValue = !isActive;
      setIsActive(newValue);
      form.setFieldValue("active", newValue);
   };

   const [otherDiscount, setOtherDiscount] = useState(initialValuesEdit?.other_discount)
   const [isNewForm, setIsNewForm] = useState(
      (initialValuesEdit?.max_uses > 1 && !initialValuesEdit?.academicUnit)
      || initialValuesEdit?.advancedSwitchAllow
   );

   if(isNewForm) form.setFieldValue("isAdvancedForm", true)
      
      return (
      <div style={{ width: "100%" }}>
         <div className="mainSwitch-styling">
            <Switch
               classNames={{ root: "switch-root-styling" }}
               checked={isNewForm}
               label="Formulario avanzado"
               {...form.getInputProps("isAdvancedForm")}
               onChange={handleFormChange}
               disabled={disabledInputs?.advancedSwitch}
            />
            <Switch
               classNames={{ root: "switch-root-styling" }}
               checked={otherDiscount}
               label="Otras opciones de descuento"
               onChange={() => setOtherDiscount(prevState => !prevState)}
            />
         </div>

         <Grid>
            {otherDiscount &&
               <Grid.Col span={12}>
                  <Select
                     {...form.getInputProps("other_discount")}
                     placeholder="Selecciona una opción:"
                     withAsterisk
                     data={[
                        { value: "dolar-forvet", label: "Dólar FORVET" },
                     ]}
                     onChange={(value) => {
                        form.setFieldValue("other_discount", value);
                     }}
                  />
               </Grid.Col>
            }
            <Grid.Col span={12}>
               <Select
                  {...form.getInputProps("institutional_name")}
                  withAsterisk
                  label="Institución"
                  searchable={true}
                  data={avalOptions}
                  onChange={(value) => {
                     form.setFieldValue("institutional_name", value);
                  }}
               />
            </Grid.Col>
            {isNewForm
               ? <>
                  <Grid.Col span={12}>
                     <Select
                        label="Tipo de uso"
                        data={[
                           { value: "single", label: "Uso único" },
                           { value: "multiple", label: "Uso múltiple" },
                        ]}
                        onChange={(value) => handleUsesQuantityChange(value)}
                        disabled={disabledInputs?.usesQuantityInput}
                        value={
                           multipleValues ? "multiple" : usesQuantity === 1 ? "single" : ""
                        }
                     />
                  </Grid.Col>
                  {multipleValues && (
                     <Grid.Col span={12}>
                        <TextInput
                           label="Cantidad de usos"
                           type="number"
                           min={1}
                           value={usesQuantity}
                           onChange={handleQuantityInputChange}
                        />
                     </Grid.Col>
                  )}
               </>
               : <Grid.Col span={12}>
                  <Select
                     {...form.getInputProps("academicUnit")}
                     withAsterisk
                     label="Unidad académica"
                     searchable={true}
                     data={academicUnits}
                     disabled={!form.values.institutional_name}
                     placeholder={
                        !form.values.institutional_name
                           ? "Para usar este campo debe seleccionar una institución primero"
                           : "Seleccione una unidad académica"
                     }
                     onChange={(value) => {
                        const item = academicUnits.find(
                           (_item) => _item.value === value
                        );
                        const splitName = item.label.split(" ");
                        const academicUnitCode = splitName
                           .map((_word) => _word.charAt(0))
                           .join("")
                           .toUpperCase();
                        const generatedCode = `${form.values.institutional_name
                           }-${academicUnitCode}-${generateRandomCode(6)}`;
                        form.setFieldValue("code", generatedCode);
                        form.setFieldValue("academicUnit", value);
                     }}
                  />
               </Grid.Col>
            }

            <Grid.Col span={6}>
               <NumberInput
                  {...form.getInputProps("percentage")}
                  min={1}
                  max={100}
                  withAsterisk
                  label="Porcentaje de descuento"
               />
            </Grid.Col>
            <Grid.Col span={6}>
               {(initialValuesEdit && !disabledInputs?.activeSwitch) && (
                  <div className="discountsForm__SwitchDiv">
                     <Switch
                        checked={isActive}
                        label="Estado del descuento"
                        onChange={handleActiveDiscount}
                     />
                  </div>
               )}
            </Grid.Col>
            <Grid.Col span={12}>
               <TextInput
                  {...form.getInputProps("code")}
                  withAsterisk
                  label="Nombre del código de descuento"
                  placeholder="Ingrese el nombre del código"
               />
            </Grid.Col>
            {!disabledInputs?.submitButton &&
               <Grid.Col span={12}>
                  <Button
                     type="submit"
                     className="createDiscount__button"
                     loading={loading}
                  >
                     {loading 
                        ? <Loader color="#fff" />
                        : (initialValuesEdit?.id || initialValuesEdit?._id) 
                           ? "Guardar descuento" 
                           : "Crear descuento"
                        
                     }
                  </Button>
               </Grid.Col>
            }
         </Grid>
      </div>
   );
}

export default DiscountsForm;
