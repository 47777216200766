import React from 'react';
import {Grid, NumberInput, Button} from "@mantine/core";
import {useForm} from "@mantine/form";
import "./SubscriptionPlanModal.css";

function SubscriptionPlanModal(props) {
    const {setOpen, formHandler} = props;
    const form = useForm({
        initialValues: {
            total_cycles: 1,
            amount_per_cycle: 0,
            index: 1,
        },
        validate: {
            total_cycles: (value) => (value > 0 ? null : 'Las cuotas deben ser mayores que 0'),
            amount_per_cycle: (value) => (value > 0 ? null : 'El monto por cuota debe ser mayor que 0'),
        },
    });

    const handleSubmit = async (values) => {
        const newSubscriptionPlan = {
            total_cycles: values.total_cycles,
            amount_per_cycle: values.amount_per_cycle,
            index: formHandler.values.comercialInformation.subscriptionPlans.length + 1,
        }
        formHandler.setFieldValue("comercialInformation.subscriptionPlans", [...formHandler.values.comercialInformation.subscriptionPlans, newSubscriptionPlan])

        setOpen(false)
    }


    return (
        <div className="subscriptionPlanModal__container">
            <h2 className="subscriptionPlanModal__title">Crear plan de pago en cuotas (PAYPAL)</h2>
            <form
                className="subscriptionPlanModal__form"
            >
                <Grid style={{width: "100%"}}>
                    <Grid.Col span={12}>
                        <NumberInput
                            label="Cantidad de cuotas"
                            {...form.getInputProps('total_cycles')}
                            min={1}
                        />
                    </Grid.Col>
                    <Grid.Col span={12}>
                        <NumberInput
                            label="Monto de cuota (USD)"
                            {...form.getInputProps('amount_per_cycle')}
                            min={1}
                        />
                    </Grid.Col>
                </Grid>
                <Button onClick={form.onSubmit((values) => handleSubmit(values))} className="subscriptionPlanModal__button">
                    Crear plan de pago en cuotas
                </Button>
            </form>
        </div>
    );
}

export default SubscriptionPlanModal;