import React from "react";
import "./AdminSpecialtiesForm.css";
import {Button, Grid, TextInput} from "@mantine/core";
import "moment/locale/es";

function AdminSpecialtiesForm(props) {
    const {form, loading} = props;

    return (
        <Grid style={{width: "100%"}} justify="center" align="center">
            <Grid.Col span={12}>
                <TextInput label="Nombre" {...form.getInputProps("name")} w={"100%"}/>
            </Grid.Col>
            <Grid.Col span={12}>
                <TextInput label="Imagen" {...form.getInputProps("photo")} w={"100%"}/>
            </Grid.Col>
            <Grid.Col span={12}>
                <TextInput
                    label="Descripción"
                    {...form.getInputProps("description")}
                    w={"100%"}
                />
            </Grid.Col>
            <Grid.Col span={12}>
                <Button
                    type="submit"
                    className="mktgNotificationsForm__form__button"
                    loading={loading}
                >
                    Guardar evento
                </Button>
            </Grid.Col>
        </Grid>
    );
}

export default AdminSpecialtiesForm;
