import { useContext } from 'react';
import AppContext from '../../../../contexts/AppContext/AppContext'
import './MktgAdvicesForm.css'
import { Button, Select, Skeleton, Textarea } from "@mantine/core";

export default function MktgAdvicesForm({ form, isLoading }) {
   const { staffUsers } = useContext(AppContext)

   return (
      <div className="MktgAdvicesForm-container">
         < Textarea
            w={'100%'}
            label='Escribe aquí el consejo'
            {...form.getInputProps("advice")}
         />

         {/* FALTA OBTENER TODOS LOS STAFF Y TEACHERS */}
         {staffUsers
            ? <Select
               maw='400px'
               label="Selecciona un usuario"
               data={staffUsers}
               searchable
               {...form.getInputProps("user")}
            />
            : < Skeleton w='400px' h='30px' />}

         <Button
            type="submit"
            className="eventCalendarForm__form__button"
            disabled={isLoading}
            w={'max-content'}
         >
            Guardar consejo
         </Button>
      </div>
   )
}
