import React from 'react';
import './NotificationBanner.css';
import {
    IconInfoCircle,
    IconAlertTriangle,
    IconExclamationCircle,
    IconCircleCheck,
} from "@tabler/icons-react";

function NotificationBanner(props) {
    const {message, type} = props;
    let icon, className;

    switch (type) {
        case 'information':
            icon = <IconInfoCircle size={24} className="notificationBanner__icon"/>;
            className = 'notificationBanner__information';
            break;
        case 'warning':
            icon = <IconAlertTriangle size={24} className="notificationBanner__icon"/>;
            className = 'notificationBanner__warning';
            break;
        case 'error':
            icon = <IconExclamationCircle size={24} className="notificationBanner__icon"/>;
            className = 'notificationBanner__error';
            break;
        case 'success':
            icon = <IconCircleCheck size={24} className="notificationBanner__icon"/>;
            className = 'notificationBanner__success';
            break;
    }
    return (
        <div className={`notificationBanner ${className}`}>
            {icon}
            <p className="notificationBanner__text">{message}</p>
        </div>
    );
}

export default NotificationBanner;