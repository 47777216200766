import {IconCertificate} from "@tabler/icons-react";

export const CERTIFICATE_DOWNLOAD_OPTIONS = [
    {
        id: 1,
        icon: < IconCertificate color='#77BDE0' /* var(--color-secondary) */ />,
        text: 'Certificado con nota con analítico',
        value: {
            withGrade: true,
            analytic: true,
        }
    },
    {
        id: 2,
        icon: < IconCertificate color='#77BDE0' /* var(--color-secondary) */ />,
        text: 'Certificado con nota sin analítico',
        value: {
            withGrade: true,
            analytic: false,
        }
    },
    {
        id: 3,
        icon: < IconCertificate color='#77BDE0' /* var(--color-secondary) */ />,
        text: 'Certificado sin nota con analítico',
        value: {
            withGrade: false,
            analytic: true,
        }
    },
    {
        id: 4,
        icon: < IconCertificate color='#77BDE0' /* var(--color-secondary) */ />,
        text: 'Certificado sin nota sin analítico',
        value: {
            withGrade: false,
            analytic: false,
        }
    },
    {
        id: 5,
        icon: < IconCertificate color='#77BDE0' /* var(--color-secondary) */ />,
        text: 'Certificado de participación',
        value: {
            asParticipant: true,
            withGrade: false,
            analytic: false,
        }
    },
]