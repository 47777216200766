import './QuestionnaireForm.css'
import {Button, Grid, Group, TextInput, Stack, Pagination, Radio, Menu} from '@mantine/core'
import {
    IconChevronRight,
    IconCirclePlus,
    IconMessage,
    IconPlus,
    IconSearch,
    IconTrash
} from '@tabler/icons-react'
import QuestionnairePreview from '../QuestionnairePreview/QuestionnairePreview';
import AllQuestionModal from '../AllQuestionModal/AllQuestionModal';
import useQuestionnaires from '../../../../hooks/Academic/Questionnaires/useQuestionnaires';


export default function QuestionnaireForm({
                                              isLoading,
                                              initialValues,
                                              academicUnitName,
                                              handleSubmit
                                          }) {
    const {
        questions,
        activePage,
        setPage,
        questionsModal,
        setQuestionsModal,
        handleAddQuestion,
        handleAddQuestionFromDB,
        handleQuestionChange,
        handleDeleteQuestion,
        moveQuestionBack,
        moveQuestionForward,
        isDisabledQuestionForwardBtn,
        isDisabledQuestionBackBtn,
        handleCheckboxChange,
        handleAnswerChange,
        handleAddAnswer,
        handleDeleteAnswer,
        isCreateBtnDisabled,
        isAddQuestionBtnDisabled
    } = useQuestionnaires({initialValues, academicUnitName, isLoading})

    const renderQA = () => {
        return (
            <Grid.Col span={12}>
                <Grid.Col>
                    <Stack>
                        <h2 className='QuestionnaireForm--questionTitle'>Pregunta {activePage}</h2>
                        <Grid>
                            <Grid.Col span={7}>
                                <Pagination total={questions?.length} value={activePage} onChange={setPage}/>
                            </Grid.Col>
                            <Grid.Col span={5}>
                                <Group>
                                    <Button variant='outline' color='secondaryColor'
                                            disabled={questions?.length === 1}
                                            onClick={() => handleDeleteQuestion(activePage - 1)}>
                                        < IconTrash style={{marginRight: '8px'}}/>
                                        <span>Eliminar pregunta</span>
                                    </Button>
                                    <Menu>
                                        <Menu.Target>
                                            <Button
                                                disabled={isAddQuestionBtnDisabled()}
                                            >
                                                < IconPlus style={{marginRight: '8px'}}/>
                                                <span>Agregar pregunta</span>
                                            </Button>
                                        </Menu.Target>
                                        <Menu.Dropdown>
                                            <Menu.Item onClick={handleAddQuestion}
                                                       leftSection={<IconCirclePlus color='#2C4B58'/>}>
                                                <span style={{color: 'var(--color-text-primary)'}}>Crear pregunta</span>
                                            </Menu.Item>
                                            <Menu.Item leftSection={<IconSearch color='#2C4B58'/>}
                                                       onClick={() => setQuestionsModal(true)}>
                                            <span
                                                style={{color: 'var(--color-text-primary)'}}>Buscar una pregunta</span>
                                            </Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                </Group>
                            </Grid.Col>
                        </Grid>
                    </Stack>

                </Grid.Col>
                <Grid.Col span={12}>
                    < TextInput
                        leftSection={< IconMessage style={{transform: 'scaleX(-1)'}}/>}
                        value={questions[activePage - 1].questionText}
                        style={{flexGrow: 1}}
                        onChange={(e) => handleQuestionChange(e, activePage - 1)}
                        label='Pregunta'
                    />
                </Grid.Col>


                <Grid.Col span={12}>
                    {questions[activePage - 1].responseOptions.map((answer, ansIndex) => (
                        <article key={ansIndex} className='QuestionnaireForm-question-container'>
                            <div className='QuestionnaireForm-labelQuestion-container'>
                                <label className='QuestionnaireForm-question--label'>
                                    Respuesta {ansIndex + 1}
                                </label>
                            </div>
                            <div className='QuestionnaireForm-labelAnswer-container'>
                                <label className='QuestionnaireForm-question--label'>
                                    ¿Es correcta?
                                </label>
                            </div>
                            <div className='QuestionnaireForm-labelAddButton-container'>
                                <label className='QuestionnaireForm-question--label'>
                                    Acciones
                                </label>
                            </div>
                            <TextInput
                                className='QuestionnaireForm--question-input'
                                value={answer.label}
                                leftSection={< IconChevronRight/>}
                                onChange={(e) => handleAnswerChange(e, activePage - 1, ansIndex)}
                            />

                            <div className='QuestionnaireForm--answer-input'>
                                <Radio
                                    name='answer'
                                    checked={questions[activePage - 1].trueOption === ansIndex + 1}
                                    value={ansIndex + 1}
                                    onChange={(e) => handleCheckboxChange(e, activePage - 1, ansIndex)}
                                />
                            </div>

                            <Group className='QuestionnaireForm-labelAddButton-input' style={{flexGrow: 1}}
                                   justify='center'>
                                <Button variant='outline'
                                        disabled={questions[activePage - 1].responseOptions.length === 1}
                                        color='secondaryColor'
                                        onClick={() => handleDeleteAnswer(activePage - 1, ansIndex)}>
                                    < IconTrash/>
                                </Button>
                            </Group>
                        </article>
                    ))}

                    <Button
                        mt={20}
                        w='max-content'
                        variant='outline'
                        onClick={() => handleAddAnswer(activePage - 1)}
                    >
                        <IconPlus/>
                        <span>Agregar respuesta</span>
                    </Button>
                </Grid.Col>

                <Grid.Col span={12}>
                    <QuestionnairePreview
                        data={questions}
                        moveQuestionBack={moveQuestionBack}
                        moveQuestionForward={moveQuestionForward}
                        isDisabledQuestionBackBtn={isDisabledQuestionBackBtn}
                        isDisabledQuestionForwardBtn={isDisabledQuestionForwardBtn}
                    />
                </Grid.Col>
            </Grid.Col>
        );
    };

    return (
        <Grid style={{width: "100%"}} align="center">
            {renderQA()}

            <Grid.Col span={12}>
                <Button
                    color='var(--color-tertiary)'
                    disabled={isCreateBtnDisabled()}
                    onClick={() => handleSubmit(questions)}

                >
                    Guardar cuestionario
                </Button>
            </Grid.Col>

            <AllQuestionModal
                opened={questionsModal}
                onClose={setQuestionsModal}
                addFunct={handleAddQuestionFromDB}
            />
        </Grid>
    )
}
