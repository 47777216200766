import React from 'react';
import {FileInput} from "@mantine/core";
import Papa from 'papaparse';
import { IconFileTypeCsv } from '@tabler/icons-react';

const CsvFileInput = (props) => {
    const { onFileLoad, saveFileOnForm = null } = props;
    const handleFileChange = (file) => {
        if (file) {
            if (saveFileOnForm) {
                saveFileOnForm(file);
            }
            Papa.parse(file, {
                complete: (result) => {
                    onFileLoad(result.data);
                },
                header: true,
                dynamicTyping: true,
                skipEmptyLines: true,
            });
        }
    };
    return (
            <FileInput
                leftSection={<IconFileTypeCsv/>}
                onChange={handleFileChange}
                label="Cargar CSV"
                leftSectionPointerEvents="none"
                {...props}
            />
    );
};
export default CsvFileInput;