import React, { useState } from 'react';
import "./SalesAcademicUnitPriceForm.css";
import {Button, Grid, Modal, NumberInput} from "@mantine/core";
import SubscriptionPlanModal from '../../../Academic/AcademicUnitForm/Modals/SubscriptionPlanModal/SubscriptionPlanModal';

function SalesAcademicUnitPriceForm({form, loading}) {
    const removeSubscriptionPlan = (index) => {
        const newSubscriptionPlans = form.values.comercialInformation.subscriptionPlans.filter((_, i) => i !== index)
        form.setFieldValue("comercialInformation.subscriptionPlans", newSubscriptionPlans)
    }
    const [createSubscriptionModalOpen, setCreateSubscriptionModalOpen] = useState(false);

    return (
        <>
        <Modal opened={createSubscriptionModalOpen} onClose={() => setCreateSubscriptionModalOpen(false)}
                   closeOnClickOutside={false}>
                <SubscriptionPlanModal
                    formHandler={form}
                    setOpen={setCreateSubscriptionModalOpen}
                />
            </Modal>

        <Grid style={{width: "100%"}}>
            <Grid.Col span={6}>
                <NumberInput
                    label="Precio en USD"
                    {...form.getInputProps('comercialInformation.price.usd')}
                    min={0}
                    value={form.values.comercialInformation?.price?.usd || 0}
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <NumberInput
                    label="Precio en ARS"
                    {...form.getInputProps('comercialInformation.price.ars')}
                    min={0}
                    value={form.values.comercialInformation?.price?.ars || 0}
                />
            </Grid.Col>
            <Grid.Col span={12}>
                <h2 className="pricingForm__title">Planes de pago (cuotas):</h2>
            </Grid.Col>
            <Grid.Col span={12}>
                {form.values.comercialInformation.subscriptionPlans.length === 0 && (
                    <p className="pricingForm__text pricingForm__text--noContent">No hay planes de suscripción creados</p>
                )}
                {form.values.comercialInformation.subscriptionPlans.map((subscription, index) => (
                    <div key={index} className="pricingForm__container" style={{display: "flex", justifyContent: "space-between"}}>
                        <p className="pricingForm__text">
                            Plan de suscripción {index + 1}: {subscription.total_cycles} cuotas de ${subscription.amount_per_cycle}
                        </p>
                        <Button
                            type="button"
                            variant="outline"
                            onClick={() => removeSubscriptionPlan(index)}
                        >
                            Eliminar
                        </Button>
                    </div>
                ))}
            </Grid.Col>
            <Grid.Col span={12} align="center">
                <Button
                    className="pricingForm__button"
                    style={{alignSelf: "center"}}
                    onClick={() => setCreateSubscriptionModalOpen(true)}
                >
                    Crear plan de suscripción
                </Button>
            </Grid.Col>
            <Grid.Col span={12}>
        <Button
          type="submit"
          className="eventCalendarForm__form__button"
          loading={loading}
        >
          Guardar evento
        </Button>
      </Grid.Col>
        </Grid>
        </>
    );
}

export default SalesAcademicUnitPriceForm;