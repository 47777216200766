import showNotification from "./showNotification";

const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    showNotification({
        color: "green",
        status: "success",
        title: "Copiado",
        message: `Código copiado al portapapeles`,
        autoClose: 2000,
    });
}

export { handleCopy };