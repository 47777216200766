import axios from "axios"
import { useContext } from "react"
import { useState } from "react"
import { useEffect } from "react"
import AuthContext from "../../../contexts/AuthContext/AuthContext"
import showNotification from "../../../utils/showNotification"
import { ERROR_HANDLER } from "../../../utils/error-handler"

export default function useAdministrativeDashboard() {
   const { authToken } = useContext(AuthContext)
   const [dashboardInfo, setDashboardInfo] = useState(null)
   const [isLoading, setIsLoading] = useState(false)

   useEffect(() => {
      async function getDashboardInfo() {
         try {
            setIsLoading(true)
            const getURL = `${process.env.REACT_APP_BACKEND_SERVER}admin/administracion/dashboardInfo`
           
            const { data } = await axios.get(getURL, {
               headers: {
                  "Content-Type": 'application/json',
                  Authorization: `Bearer ${authToken}`
               }
            })
            setDashboardInfo(data)

         } catch (error) {
            console.error(error)
            showNotification({
               color: "red",
               status: "error",
               title: "Error al cargar información y estadísticas del dashboard.",
               message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
               autoClose: 12000
            });
         } finally {
            setIsLoading(false)
         }
      }
      getDashboardInfo()
   }, [authToken])

   return {
      coursesInfo: dashboardInfo?.courses,
      // studentsInfo: dashboardInfo?.students,
      incomesInfo: dashboardInfo?.income,
      setDashboardInfo,
      isLoading,
      setIsLoading
   }
}
