import { useContext, useEffect, useState } from "react"
import AuthContext from "../../../contexts/AuthContext"
import showNotification from "../../../utils/showNotification";
import { ERROR_HANDLER } from "../../../utils/error-handler";
import axios from "axios";


export default function useModDashboard() {
   const { authToken } = useContext(AuthContext);
   const [data, setData] = useState(null)
   const [isLoading, setIsLoading] = useState(true)

   useEffect(() => {
      const getData = async () => {
         try {
            const { data: response } = await axios.get(`${process.env.REACT_APP_BACKEND_SERVER}admin/moderation/dashboardInfoMod`, {
               headers: {
                  "Content-Type": 'application/json',
                  Authorization: `Bearer ${authToken}`
               }
            })
            setData(response)
         } catch (error) {
            console.log(error)
            showNotification({
               color: "red",
               status: "error",
               title: "Error al cargar información y estadísticas de moderación.",
               message: `Detalle: ${ERROR_HANDLER[error.request.status] || ERROR_HANDLER.defaultError}`,
               autoClose: 12000
            });
         } finally {
            setIsLoading(false)
         }
      }
      getData()
   }, [authToken])

   const coursesInfo = data?.courses
   const moderatorsInfo = data?.moderators
   const teachersInfo = data?.teachers

   const getMostPopular = (data, field, score, limit) => {
      if (!data) return [];

      const dataArray = Object.values(data);

      // Filtrar y ordenar
      const results = dataArray
         .filter(mod => (mod[field] >= 3 && mod[score]))
         .sort((a, b) => b[score] - a[score]);

      if(limit){
         return results.slice(0,10)
      }

      return results
   };


   return {

      isLoading,
      coursesInfo,
      mostRatedModerators: getMostPopular(moderatorsInfo, 'totalModeratios', 'average'),
      tenMostRatedTeachers: getMostPopular(teachersInfo, 'totalReviews', 'overallTeacherScore', true),
   }
}