import React, {useState} from 'react';
import "./MktgCalendarForm.css";
import {Button, Grid, Select, TextInput} from "@mantine/core";
import {DateInput} from "@mantine/dates";
import moment from "moment";
import 'moment/locale/es';

const EVENT_TYPES = [
    {value: "general", label: "General"},
]

function MktgCalendarForm(props) {
    const {form, loading} = props;
    const [dateValue, setDateValue] = useState(moment().toDate());
    moment.locale('es')

    return (
        <Grid style={{width: "100%"}} justify="center" align="center">
            <Grid.Col span={12}>
                <Select
                    {...form.getInputProps('type')}
                    withAsterisk
                    label="Tipo de evento"
                    data={EVENT_TYPES}
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <TextInput
                    label="Nombre"
                    {...form.getInputProps("name")}
                    w={'100%'}
                />
            </Grid.Col>
            <Grid.Col span={6}>
                <DateInput
                    label="Fecha del evento"
                    dateParser={(dateString) => moment(dateString, 'DD/MM/YYYY').toDate()}
                    allowFreeInput
                    locale="es"
                    placeholder="dd/mm/yyyy"
                    inputFormat="DD/MM/YYYY"
                    valueFormat="DD/MM/YYYY"
                    value={dateValue}
                    onChange={(e) => setDateValue(moment(e, 'DD/MM/YYYY').toDate())}
                    onBlur={() => {
                        form.setFieldValue('initDate', dateValue)
                    }}
                />
            </Grid.Col>
            <Grid.Col span={12}>
                <Button
                    type="submit"
                    className="eventCalendarForm__form__button"
                    loading={loading}
                >
                    Guardar evento
                </Button>
            </Grid.Col>
        </Grid>
    );
}

export default MktgCalendarForm;