import imgDogLeft from '../assets/login/dog-left.jpg'
import imgDogLeft2 from '../assets/login/dog-left2.jpg'
import imgDogRight from '../assets/login/dog-right.jpg'
import imgDogRight2 from '../assets/login/dog-right2.jpg'

export const LOGIN_BG_IMAGES = [
   {
      img: imgDogLeft,
      justifyContent: 'flex-end',
      color: 'rgb(0,163,180)'
   },
   {
      img: imgDogLeft2,
      justifyContent: 'flex-end',
      color: 'rgb(173,224,231)'
   },
   {
      img: imgDogRight,
      justifyContent: 'flex-start',
      color: 'rgb(35,74,56)'
   },
   {
      img: imgDogRight2,
      justifyContent: 'flex-start',
      color: 'rgb(255,195,58)'
   },
]